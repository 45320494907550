import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from '../../Auth/AuthenticationContext';
import { db } from '../../Fribase/firebase.init';



export const BookingContext = createContext();

export default function BookingContextProvider({ children }) {
  const [userCar,setUserCar] = useState([]);
  const [allCar,setAllCar] = useState([]);
  const [allBlog,setAllBlog] = useState([]);

  const [allUser, setAllUser] = useState([]);
  const [allChakout, setChakOut] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [favorites, setUserFavorites] = useState([]);
  const [compare, setUserCompare] = useState([]);
  const [views, setViews] = useState([]);
  const [bids, setbids] = useState([]);

  const [allCommentsRepllay, setAllCommentsRepllay] = useState([]);


  const { loggedInUser } = useAuth();

  // get users Cars
  useEffect(() => {
    if (loggedInUser) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'car'),
          where('email', '==', loggedInUser.email)
        ),
        (snapshot) => {
          const userCar = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserCar(userCar);
        },
        (err) => {
          toast.error(err.message);
        }
      );
      return () => unsubscribe();
    }
  }, [loggedInUser]);

  // get All Cars
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'car'),
      (snapshot) => {
        const allCar = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllCar(allCar);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);
  // get All Blog
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'blog1'),
      (snapshot) => {
        const allBlog = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllBlog(allBlog);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);

   // get All Users
   useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'users'),
      (snapshot) => {
        const allUser = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllUser(allUser);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);

   // get All ChakOut form
   useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'chakoutcart'),
      (snapshot) => {
        const allChakout = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setChakOut(allChakout);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);












   // get All Comments form
   // get All Comments form

   useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'reply'),
      (snapshot) => {
        const allCommentsRepllay = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllCommentsRepllay(allCommentsRepllay);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);







  // all commets replly
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'commnets'),
      (snapshot) => {
        const allComments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllComments(allComments);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);





   // get users favorites 
   useEffect(() => {
    if (loggedInUser) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'favorites'),
          where('userEmail', '==', loggedInUser?.email)
        ),
        (snapshot) => {
          const favorites = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserFavorites(favorites);
        },
        (err) => {
          toast.error(err.message);
        }
      );
      return () => unsubscribe();
    }
  }, [loggedInUser]);


  // compare ........
  
   useEffect(() => {
    if (loggedInUser) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, 'compare'),
          where('userEmail', '==', loggedInUser?.email)
        ),
        (snapshot) => {
          const compare = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserCompare(compare);
        },
        (err) => {
          toast.error(err.message);
        }
      );
      return () => unsubscribe();
    }
  }, [loggedInUser]);



  // get All Views
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'view'),
      (snapshot) => {
        const views = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
         setViews(views);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);



  // get All bid

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'bid'),
      (snapshot) => {
        const bids = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
       setbids(bids);
      },
      (error) => {
        console.error(error);
        toast.error(error);
      }
    );
    return () => unsubscribe();
  }, []);


  const bookingData = {
    userCar,
    allCar,
    allBlog,
    allUser,
    allChakout,
    allComments,
    compare,
    favorites,
    views,
    allCommentsRepllay,
    bids
  };

  return (
    <BookingContext.Provider value={bookingData}>
      {children}
    </BookingContext.Provider>
  );
}