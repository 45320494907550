import React from "react";
import { AiFillHome } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaFax } from 'react-icons/fa';
import { AiFillMail } from 'react-icons/ai';

const ContactForm = () => {
  return (
    <div className="grid md:grid-cols-2 container  ml-auto mr-auto text-left mt-20 gap-10">
      <div className="">
        <h4 className="text-xl font-bold bg-white border-l-4 border-[#224b9e] ml-2">
          {" "}
          <span className="ml-4 text-3xl">Contact Form</span>{" "}
        </h4>
        <hr className="m-2" />
        <br />
        <p className="md:mr-10 m-2">
          Enter your comments through the form below, and our customer service
          professionals will contact you as soon as possible.
        </p>
        <br />

        <form action="" className="m-2">
          <div class="col-span-6 sm:col-span-3 ">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              Brand Name
            </label>
            <select
              id="make"
              name="make"
              autocomplete="country-name"
              class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              // defaultValue={listing.make}
              // {...registerPost("make", {
              //   required: "Brand name is required!",
              // })}
            >
              <option value="" disabled selected>
                Select Brand Name
              </option>
              <option value="BMW">BMW</option>
              <option value="Audi">Audi</option>
              <option value="Ford">Ford</option>
              <option value="Kia">Kia</option>
              <option value="Chevrolet">Chevrolet</option>
            </select>

            <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
              {/* {errors.make && errors.make.message} */}
            </span>
          </div>

          <div class="col-span-6 sm:col-span-3 mt-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              // Value={ loggedInUser?.email}
              disabled
              id="last-name"
              autocomplete="family-name"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div class="col-span-6 sm:col-span-3 mt-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              name="name"
              // Value={ loggedInUser?.email}
              disabled
              id="last-name"
              autocomplete="family-name"
              class="mt-1 block w-full  rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div class="col-span-6 sm:col-span-3 mt-3">
            <label
              for="last-name"
              class="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <textarea
              type="text"
              name="name"
              // Value={ loggedInUser?.email}
              disabled
              id="last-name"
              autocomplete="family-name"
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          {/* button */}
          <div className="pt-8 flex justify-end">
            <button
              type="submit"
              className=" bg-[#224b9e] ml-3 inline-flex justify-center py-2.5 px-6 border border-transparent shadow-sm text-sm sm:text-base font-semibold rounded-md text-white transition-colors duration-300 bg-brand-accent-700 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
            >
              Post{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-1 h-6 w-6 text-sm sm:text-base font-medium text-gray-50"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                />
              </svg>
            </button>
          </div>
        </form>
        <br />
        <br />
        <br />
      </div>
      {/* righit site */}
      
      <div className="">
        <h4 className="ml-6 mt-2 font-bold bg-white border-l-4 border-[#224b9e] ">
          {" "}
          <span className="ml-4 text-xl mt-2">OPENING HOURS</span>{" "}
        </h4>
        <div className="m-6 bg-gray-100 grid grid-cols-2 p-10 ">
          <div>
            <p className="font-bold"> Sales Department</p>
            <p>Mon-Sat : 8:00am - 5:00pm</p>
            <p>Sunday is closed</p>
          </div>
          <div>
            <p className="font-bold"> Service Department</p>
            <p>Mon-Sat : 8:00am - 5:00pm</p>
            <p>Sunday is closed</p>
          </div>
        </div>
        <br />


        <h4 className="ml-6 font-bold bg-white border-l-4 border-[#224b9e] ">
          {" "}
          <span className="ml-4 text-xl">OPENING HOURS</span>{" "}
        </h4>


        <div className="m-6 bg-gray-100 grid grid-cols-1 p-10 ">
          <div>
            <p className="font-bold flex"> <AiFillHome className="mr-2 color text-[#224b9e]"></AiFillHome>  ADDRESS </p>
            <span className="ml-6">202 W 7th St, Suite 233 Los Angeles, California 90014 USA</span>
            <p className="font-bold flex mt-2"><BsFillTelephoneFill className="mr-2 text-[#224b9e]"></BsFillTelephoneFill>  PHONE <span className="ml-10 font-semibold"> 1-800- 624-5462</span></p>
            <p className="font-bold flex mt-2"><FaFax className="mr-2 text-[#224b9e]"></FaFax>  FAX1 <span className="ml-10 font-semibold"> 1-800- 624-5462</span></p>
            <p className="font-bold flex mt-2"><AiFillMail className="mr-2 text-[#224b9e] text-xl"></AiFillMail> EMAL <span className="ml-10 font-semibold"> Linfo@domain.com</span></p>
           
 
           



            
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
