import React from "react";
import Slider from "react-slick";
import sir from "../../../imgs/Capture.PNG";

const Testimonials = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="ml-auto mr-auto container">
      <br />
      <br />
      <br />
      <h1 className="text-xl font-bold md:text-4xl text-[#61dafb] ml-auto mr-auto">
        {" "}
        Testimonials
      </h1>
      <br />
      {/*  */}

      <div className=" overflow-hidden  container ml-auto mr-auto p-2">
        <Slider {...settings}>
          <div className=" p-3">
            <div className="flex grid grid-cols-4 bg-white shadow rounded-xl p-4 container">
              <div>
                <img
                  className="w-20 rounded-full"
                  src="https://i.ibb.co/hKhGqgg/tras-logo-removebg-preview.png"
                  alt=""
                />
              </div>

              <div className="  col-span-3  text-left ml-3">
                <h4 className="text-left fond-bold text-xl">Abir Ahmed</h4>
                {/* <h4 className=' bg-orange-500'>"</h4> */}

                <p className="h-32">
                I came in to get a new car, and the great service and selection made me want to purchase from this dealer. Easy, and enjoyable experience. Thanks Guys! Looking forward to the new car!
                </p>
              </div>
            </div>
          </div>
          {/* 2 number */}
        
         
          {/* 4 number */}
          
          {/* 5 number */}
          <div className="p-3">
            <div className="flex grid grid-cols-4 bg-white shadow rounded-xl p-4">
              <div>
                <img className="w-20 rounded-full" src="https://i.ibb.co/hKhGqgg/tras-logo-removebg-preview.png" alt="" />
              </div>

              <div className="  col-span-3  text-left ml-3">
                <h4 className="text-left fond-bold text-xl">Asfi Noor</h4>
                {/* <h4 className=' bg-orange-500'>"</h4> */}

                <p className="h-32">
                One of the best place to buy your car, great service and also great cars.
                </p>
              </div>
            </div>
          </div>
          {/* 6 number */}
          <div className="p-3">
            <div className="flex grid grid-cols-4 bg-white shadow rounded-xl p-4">
              <div>
                <img className="w-20 rounded-full" src="https://i.ibb.co/hKhGqgg/tras-logo-removebg-preview.png" alt="" />
              </div>

              <div className="  col-span-3  text-left ml-3">
                <h4 className="text-left fond-bold text-xl">Jamil</h4>
                {/* <h4 className=' bg-orange-500'>"</h4> */}

                <p className="h-32">
                Really good Service. Kept up-to-date all the time. Would definitely buy another car and recommend the service to anyone
                </p>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <br />
    </div>
  );
};

export default Testimonials;
