import React, { useContext, useState } from "react";

import { toast } from "react-hot-toast";
import { useAuth } from "../../Auth/AuthenticationContext";

import { useForm } from "react-hook-form";

import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../Fribase/firebase.init";
import { CommentsContext, CommnetsData } from "../../context/CommentsContext";
import { BookingContext } from "../../context/AuthProvider/BookingContext";

import CommentRelly from "./CommentRelly";

const CommentProducts = ({ product }) => {
  const { allComments } = useContext(BookingContext);
  const { loggedInUser, currentUser } = useAuth();

  const { comment, setComment } = useContext(CommentsContext);
  const [postImages] = useState([]);

  // const navigate = useNavigate();
  const {
    register: registerPost,
    reset,
    handleSubmit: handleMultipulUpload,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    toast.dismiss();
    const userEmail = loggedInUser.email.split("@");
    const listingId =
      Math.floor(1000 + Math.random() * 9000) + "-" + userEmail[0];

    data.vandorName = loggedInUser.displayName;
    data.postImages = postImages || comment.postImages;
    data.email = loggedInUser.email;
    data.category = "commnets" || comment.category;
    data.id = listingId;

    // console.log("onsubmit from", data);

    try {
      // jato filed nibo sub gola akhne dilei hobe
      const payload = {
        ...data,
        carid: product.id,
        commentsUser: currentUser?.photoURL,
        createdAt: serverTimestamp(),
      };

      // upload
      const spacesRef = doc(db, data.category, data.id);

      // store user info on database
      await setDoc(spacesRef, payload, { merge: true });

      setComment(CommnetsData);
      reset();

      // toast.success("Your Comments successfully ");
      // navigate("/listing");

      // <Navigate to="/listing"/>
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="mt-4  bg-white rounded-lg shadow-lg ">
      {/* <img className="w-10 rounded-full" src={currentUserr?.photoURL} alt="" /> */}
      {console.log("commets data 11", allComments)}
      {/* <p>commets{allComments.id}</p> */}
      {allComments
        ?.filter((item) => item?.carid === product?.id)
        .map((item) => (
          <div className="grid gap-4 p-3">
            <div key={item.id} item={item}>
              <div className="text-left grid grid-cols-4">
                <div className="  flex ">
                  <div className="w-8 h-8 ml-2">
                    <img
                      className="rounded-full"
                      src={item?.commentsUser}
                      alt=""
                    />
                    {/* <img
                    className="h-full w-full text-gray-300 ml-auto mr-auto rounded-full "
                    src="https://i.ibb.co/55wYZZ4/1288-beautiful-car.jpg"
                  ></img> */}
                  </div>
                  <p className="ml-2 mt-2 font-semibold">{item.vandorName}</p>
                  {/* <p className="ml-2 mt-2 font-semibold">mmm{item.length}</p> */}

                  {/* {console.log("img comments", item.photoURL, allComments)} */}
                </div>
                <div className="text-left grid col-span-3 p-2 text-gray-500">
                  <p>{item.comments}</p>

                  {/* reply  */}
                  {/* <p>{item.vandorName}</p> */}

                  <CommentRelly comment={item}></CommentRelly>
                  {console.log("proudtsssss", product)}
                </div>
              </div>
            </div>
          </div>
        ))}

      <div className="flex mx-auto items-c enter justify-center ml-1 mb-4 max-w-lg ">
        <form onSubmit={handleMultipulUpload(onSubmit)}>
          <div className="flex flex-wrap -mx-3 mb-6">
            <h2 className="px-4 pt-3 pb-2 text-gray-800 text-lg flex">
              <img
                className="w-10 rounded-full mr-4"
                src={currentUser?.photoURL}
                alt=""
              />
              Add a new comment
            </h2>
            <div className="w-full md:w-full px-3 mb-2 mt-2">
              <textarea
                id="text"
                name="comments"
                type="text"
                autoComplete="comments"
                placeholder="Message"
                required
                {...registerPost("comments", {
                  required: " Comments is required",
                  minLength: {
                    value: 12,
                    message: "place 8 word",
                  },
                })}
                className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                  errors.message
                    ? "focus:border-red-500 focus:ring-red-500"
                    : "focus:border-blue-500 focus:ring-blue-500"
                } `}
              />
              <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                {errors.comments && errors.comments.message}
              </span>
            </div>
            <div className="w-full md:w-full flex items-start md:w-full px-3">
              <div className="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
               
                 
              </div>

              <div className="-mr-1">
                <input
                  // onClick={postDataCommnet}
                  type="submit"
                  className="bg-white cursor-pointer text-gray-700 font-medium py-1 px-4 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                  value="Post Comment"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* <CommentRelly></CommentRelly> */}
    </div>
  );
};

export default CommentProducts;
