import React, { useContext } from "react";
import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../Auth/AuthenticationContext";
import { BookingContext } from "../../context/AuthProvider/BookingContext";
import {
  CommentsRepllyContext,
  RepllyData,
} from "../../context/CommentsRepllyContext";
import toast from "react-hot-toast";

import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../Fribase/firebase.init";
import TestButton from "./TestButton";

const CommentRelly = ({ comment }) => {
  const { allCommentsRepllay } = useContext(BookingContext);
  const { loggedInUser, currentUser } = useAuth();

  const { replly, setReplly } = useContext(CommentsRepllyContext);
  const [postImages, setPostImages] = useState([]);

  const [openReplyForm, setReplyForm] = useState(false);

  // const navigate = useNavigate();
  const {
    register: registerPost,
    reset,
    handleSubmit: handleMultipulUpload,
    formState: { errors },
  } = useForm();

  console.log("comment data", allCommentsRepllay);
  // upload multiple images
  const onSubmit = async (data) => {
    toast.dismiss();
    const userEmail = loggedInUser.email.split("@");
    const listingId =
      Math.floor(1000 + Math.random() * 9000) + "-" + userEmail[0];

    console.log("data", data);
    // data.email = loggedInUser.email;
    // data.eventName = data.eventName || loggedInUser.eventName;
    // data.photoURL = fileUrl || loggedInUser.photoURL;
    data.vandorName = loggedInUser.displayName;
    data.postImages = postImages || replly.postImages;
    data.email = loggedInUser.email;
    data.category = "reply" || replly.category;
    data.id = listingId;

    console.log("onsubmit from", data);

    try {
      // const loading = toast.loading("Please wait a moment...");

      // jato filed nibo sub gola akhne dilei hobe
      const payload = {
        ...data,
       
        commentId: comment.id,
        commentsUser: currentUser?.photoURL,
        createdAt: serverTimestamp(),
      };

      // upload
      const spacesRef = doc(db, data.category, data.id);

      // store user info on database
      await setDoc(spacesRef, payload, { merge: true });

      // toast.dismiss(loading);
      setReplly(RepllyData);
      reset()
     
      // toast.success("Your replly successfully ");
      // navigate("/listing");

      // <Navigate to="/listing"/>
    } catch (error) {
      toast.error(error.message);
    }
  };
  // console.log("repllydata  idddd", allCommentsRepllay.id);
  return (
    <div className="text-left">
      {/*  */}

      {allCommentsRepllay
        ?.filter((item) => item?.commentId === comment?.id)
        .map((item) => (
          <div className="text-left grid col-span-3 p-2 text-gray-500 ">
            {" "}
            <div className="flex">
              <div className="w-10 mr-4 mt-4">
                <img className=" rounded-full" src={item.commentsUser} alt="" />
              </div>
              <p className="mt-5">{item.replly}</p>
            </div>
          </div>
        ))}

      {openReplyForm ? (
        <form onSubmit={handleMultipulUpload(onSubmit)} className="">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-full px-3 mb-2 mt-2">
              <div className="flex">
                <div className="w-12  mt-3 mr-2">
                  <img
                    className="rounded-full mr-4"
                    src={currentUser?.photoURL}
                    alt=""
                  />
                </div>

                <textarea
                  // className="w-full"
                  id="text"
                  name="replly"
                  type="text"
                  autoComplete="replly"
                  placeholder="Message"
                  required
                  {...registerPost("replly", {
                    required: " replly is required",
                    minLength: {
                      value: 12,
                      message: "place 8 word",
                    },
                  })}
                  className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                    errors.message
                      ? "focus:border-red-500 focus:ring-red-500"
                      : "focus:border-blue-500 focus:ring-blue-500"
                  } `}
                />
              </div>
            </div>
            <div className="w-full md:w-full flex items-start  px-3">
              <div className="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
                
              </div>

              <div className="-mr-1 flex">
                <input
                  onClick={() => setReplyForm(false)}
                  // type="submit"
                  className="text-red-600 bg-white cursor-pointer  font-medium py-1 px-4 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                  value="close"
                />
                <input
                  // onClick={postDataCommnet}
                  type="submit"
                  className="bg-white cursor-pointer text-gray-700 font-medium py-1 px-4 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                  value="Post Comment"
                />
              </div>
            </div>
          </div>
        </form>
      ) : (
        <button
          onClick={() => setReplyForm(true)}
          className=" text-blue-500 bg-white text-left"
        >
          Reply
        </button>
      )}
      {/*  */}
      {/* <TestButton></TestButton> */}
    </div>
  );
};

export default CommentRelly;
