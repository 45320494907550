import React from "react";
import { Navigate, Routes } from "react-router-dom";
import { useAuth } from "../Auth/AuthenticationContext";

const PublicRoute = ({ children, ...rest }) => {
  let { currentUser } = useAuth();
  if(currentUser){
    return <Navigate to="/"/>
  }
  return children;

  
    //
        
}

export default PublicRoute;
