import React from "react";
import IMG from "../../imgs/damigari page.jpeg";

import { useEffect } from "react";

const DemoPage = ({ item }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="h-screen bg-black ">
      <img className=" mr-auto ml-auto w-full  " src={IMG} alt="" />
    </div>
  );
};

export default DemoPage;
