import React from 'react'
import { useState } from 'react';

const TestButton = () => {
    const [openReplyForm, setReplyForm] = useState(false);
  return (
  <div>
    {openReplyForm ? (
        <form  className="">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-full px-3 mb-2 mt-2">
              <div className="flex">
                <div className="w-12  mt-3 mr-2">
                  <img
                    className="rounded-full mr-4"
                  
                    alt=""
                  />
                </div>

                <textarea
                  // className="w-full"
                  id="text"
                  name="replly"
                  type="text"
                  autoComplete="replly"
                  placeholder="Message"
                  
                
                />
              </div>
            </div>
            <div className="w-full md:w-full flex items-start  px-3">
              <div className="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
                <svg
                  fill="none"
                  className="w-5 h-5 text-gray-600 mr-1"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>

              <div className="-mr-1 flex">
                <input
                  onClick={() => setReplyForm(false)}
                  // type="submit"
                  className="text-red-600 bg-white cursor-pointer  font-medium py-1 px-4 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                  value="Censel"
                />
                <input
                  // onClick={postDataCommnet}
                  type="submit"
                  className="bg-white cursor-pointer text-gray-700 font-medium py-1 px-4 border border-gray-400 rounded-lg tracking-wide mr-1 hover:bg-gray-100"
                  value="Post Comment"
                />
              </div>
            </div>
          </div>
        </form>
      ) : (
        <button
          onClick={() => setReplyForm(true)}
          className=" text-blue-500 bg-white text-left"
        >
          Reply
        </button>
      )}
  </div>
  )
}
// raf
export default TestButton