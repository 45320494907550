import React, { useContext, useEffect, useState } from "react";

import AuthContext from "./../../../../context/AuthProvider/AuthContext";
import toast, { Toaster } from "react-hot-toast";


const Profile = () => {
  const { profile, setprofiele } = useContext(AuthContext);
  const [proFormdata, setProfileFormData] = useState({
    firstName: "",
    lastName:  "",
    email: profile.email || "",
    about: "",
    phone: "",
  });
  const handleChangeform = ({ currentTarget: input }) => {
    setProfileFormData({ ...proFormdata, [input.name]: input.value });
  };
console.log(proFormdata)

  

  const handleSubmit = async (e) => {

    fetch("http://localhost:5000/api/userUpdateProfile", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(proFormdata),
    })
      .then((response) => response.json())
      .then((data) => {
        // const newUsers = [...proFormdata, data];
        // setProfileFormData(newUsers)
        console.log('sdfdfdfsdfsdfd', data);
      });
    
    toast.success("Successfully ");
    e.preventDefault();
  };

  return (
    <div className="mt-10">
      <div>
        <div class="md:grid md:grid-cols-2 md:gap-6 m-2 md:p-2 overflow-hidden">
          <div class="mt-5 md:col-span-2 md:mt-0">
            {/* form validation  */}
            {/* form validation  */}
            {/* form validation  */}

            <form onSubmit={handleSubmit}>
              <div class="shadow-xl sm:overflow-hidden sm:rounded-md text-left">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div>
                    <label class="block text-sm font-medium text-gray-700"></label>
                    <div class="mt-1 flex items-center ml-auto mr-auto">
                      <span class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                        <svg
                          class="h-full w-full text-gray-300 ml-auto mr-auto"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                      <button
                        type="button"
                        class="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="first-name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        First names
                      </label>
                      <input
                        type="text"
                      
                        defaultValue={profile.firstName}
                        name="firstName"
                        onChange={handleChangeform}
                        value={setProfileFormData.firstName}
                        required
                        id="first-name"
                        autocomplete="given-name"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="last-name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        defaultValue={profile.lastName}
                        name="lastName"
                        onChange={handleChangeform}
                        value={setProfileFormData.lastName }
                        required
                        id="last-name"
                        autocomplete="family-name"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="last-name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        phone Number
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Your Number"
                        id="last-name"
                        autocomplete="family-name"
                        name="phone"
                        onChange={handleChangeform}
                        value={setProfileFormData.phone}
                        required
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    <div class="col-span-6 sm:col-span-3">
                      <label
                        for="last-name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        defaultValue={profile.email}
                        disabled
                        name="email"
                        onChange={handleChangeform}
                        value={setProfileFormData.email }
                        required
                        id="last-name"
                        autocomplete="family-name"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>

                  
                  </div>

                  <div>
                    <label
                      for="about"
                      class="block text-sm font-medium text-gray-700"
                    >
                      About
                    </label>
                    <div class="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        onChange={handleChangeform}
                        value={setProfileFormData.about}
                        required
                        rows="3"
                        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="you@example.com"
                      ></textarea>
                    </div>
                    <p class="mt-2 text-sm text-gray-500">
                      Brief description for your profile. URLs are hyperlinked.
                    </p>
                  </div>

              
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    class="inline-flex justify-center rounded-md border border-transparent 
                    bg-[#0891b2] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="hidden sm:block" aria-hidden="true"></div>
      <Toaster />
{/* 
      <TestProfile></TestProfile> */}
    </div>
  );
};

export default Profile;
