import React from "react";
import price from "../../imgs/hero/price-tag.png";
import car from "../../imgs/hero/car.png";
import img3 from "../../imgs/hero/trust.png";
import whatsapp from "../../../src/imgs/whatapp/whatsapp.png";
import "./whatsapp.css";
import messenger from "../../../src/imgs/whatapp/messenger.png.webp";

const ChooseUs = () => {
  return (
    <div className="bg-[#F5F7F9] mt-10">
      <br />
      <br />
      <br />
      <h2 className="text-2xl md:text-4xl  font-bold text-[#61dafb]">
        Why Choose Us?
      </h2>
      <br />
      <br />
      <div className="grid flex md:grid-cols-3 container ml-auto mr-auto">
        <div className="grid flex grid-cols-3 mt-4  m-1">
          <img className="w-20 ml-auto mr-auto mt-5" src={price} alt="" />
          <div className="text-left col-span-2">
            <h2 className="text-xl font-bold text-[#1a3775]">Best Price</h2>
            <p className="text-[#747eb7]">
              Our Stress-Free Finance Department That Can Find Financial
              Solutions To Save You Money.
            </p>
          </div>
        </div>

        {/* secent pard */}
        <div className="grid flex grid-cols-3 mt-4  m-1">
          <img className=" w-20  ml-auto mr-auto mt-5" src={car} alt="" />
          <div className="text-left col-span-2">
            <h2 className="text-xl font-bold text-[#1a3775]">
              Trusted By Thousands
            </h2>
            <p className="text-[#747eb7]">
              Our Stress-Free Finance Department That Can Find Financial
              Solutions To Save You Money.
            </p>
          </div>
        </div>

        {/* thard pard  */}
        <div className="grid flex grid-cols-3 mt-4 m-1">
          <img className="w-20 ml-auto mr-auto mt-5" src={img3} alt="" />
          <div className="text-left  col-span-2">
            <h2 className="text-xl font-bold text-[#1a3775]">
              Wide Range of Brands
            </h2>
            <p className="text-[#747eb7]">
              Our Stress-Free Finance Department That Can Find Financial
              Solutions To Save You Money.
            </p>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="relative">
        <a href="https://wa.me/8801627392810?text=DamiGari">
          <img
            className="fixed w-12  right-0 whtsapp-class"
            src={whatsapp}
            alt=""
          />
        </a>
      </div>

      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default ChooseUs;
