import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import logo from "../../../src/imgs/mainLogos.PNG";

import { useEffect } from "react";
import HeaderLogOut from "./HeaderLogOut";
import { useAuth } from "./../../Auth/AuthenticationContext";
import './Header.css'

const Header = () => {
  const [nav, setNav] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const { currentUser, token,logout  } = useAuth();
  // const [token, setToken] = useState(null)
  // useEffect(() => {
  //     setToken(localStorage.getItem('token'))
  // },[token])
  // console.log("token", token);
  // console.log("current user heder", currentUser);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });

  // const token = localStorage.getItem("token");
  // const handaleLogout = ()=>{

  //   setToken(localStorage.removeItem('token'))
  // }

  const handleNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <header
      className={` top-0 w-full fixed z-40  ${
        !isSticky ? " bg-transparent" : "scrolled"
      }`}
    >
      {/* max-w-[1536px]    max-w-[1240px] */}
      <div className="flex justify-between items-center  max-w-[1536px] mx-auto px-4  ">
        <div className="     text-left  w-full text-3xl font-bold bg-gradient-to-r text-transparent bg-clip-text from-green-500 to-blue-600 hover:from-pink-500 hover:to-yellow-500">
          <img className=" w-40 h-24 -mt-4 " src="https://i.ibb.co/d6byD9X/5f8a77ed-b4eb-4330-80ba-b6fb712c5b95-removebg-preview.png" alt="" />{" "}
{/* https://i.ibb.co/7YMsM83/main-Logos-removebg-preview.png */}

{/* <a href="https://imgbb.com/"><img src="" alt="5f8a77ed-b4eb-4330-80ba-b6fb712c5b95-removebg-preview" border="0"></a> */}




          
        </div>
        <ul className="hidden md:flex  ">
          <NavLink to="/home" className="p-4 hover:text-[#61dafb]">
            {/* #61dafb */}
            Home
          </NavLink>
          <NavLink to="/listing" className="p-4 hover:text-[#61dafb]">
            Listing
          </NavLink>
          <NavLink to="/blog" className="p-4 hover:text-[#61dafb]">
            Blog
          </NavLink>
          <NavLink to="/about" className="p-4 hover:text-[#61dafb]">
            About
          </NavLink>
          <NavLink to="/contact" className="p-4 hover:text-[#61dafb]">
            Contact
          </NavLink>

          {(currentUser || token) && (
            <NavLink to="/dashboard" className="p-4 hover:text-[#61dafb]">
              Dashboard
            </NavLink>
          )}

          {currentUser || token ? (
            <HeaderLogOut></HeaderLogOut>
          ) : (
            <NavLink to="/login" className="p-4 hover:text-[#61dafb]">
              Login
            </NavLink>
          )}

          {/* 
 <NavLink to='/dashboard' className="p-4">Dashboard</NavLink>


  <NavLink to='/login' className="p-4">Login</NavLink> */}

          {/* { token ?  <span onClick={handaleLogout} className="p-4 cursor-pointer">Logout</span>: <NavLink to='/login' className="p-4">Login</NavLink>} */}

          {/* {user && <Route path="/" exact element={<Main />} />} */}
        </ul>

        {/* md:hidden dile midium hole menu icons dekhabe sma:dile khob choto hole menu dekahbe */}
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
        <ul
          className={
            nav
              ? "z-100 text-white font-bold  overflow-auto h-100 text-left fixed left-0 top-0 md:w-[20%] w-[60%] h-full border-r  bg-[#0a2357] ease-in-out duration-500 block md:hidden "
              : "ease-in-out duration-500 fixed left-[-100%] "
          }
        >
          <div className="flex bg-white">
            <h1 className="  w-full text-3xl font-bold text-[#00df9a] m-5 mt-8  bg-gradient-to-r text-transparent bg-clip-text from-green-500 to-blue-600 hover:from-pink-500 hover:to-yellow-500">
              <img src={logo} className="w-24" alt="" />
            </h1>
            <h1 className="mt-10 text-black" onClick={handleNav}>
              {" "}
              <AiOutlineClose size={20} />
            </h1>
          </div>

          <li className="p-4 border-b border-gray-600 hover:text-[#61dafb]">
            <NavLink to="/home">Home</NavLink>
          </li>
          <li className="p-4 border-b border-gray-600 hover:text-[#61dafb]">
            <NavLink to="/listing">Listing</NavLink>
          </li>
          <li className="p-4 border-b border-gray-600 hover:text-[#61dafb]">
            <NavLink to="/blog">Blog</NavLink>
          </li>
          <li className="p-4 border-b border-gray-600 hover:text-[#61dafb]">
            <NavLink to="/about">About</NavLink>
          </li>
        
          <li className="p-4 border-b border-gray-600 hover:text-[#61dafb]">
            <NavLink to="/dashboard">Dashboard</NavLink>
          </li>
         


          {currentUser || token ? (
             <NavLink  onClick={logout} className=" p-4 mt-3  hover:text-[#61dafb]">
             Logout
           </NavLink>
          ) : (
            <NavLink to="/login" className="p-4 hover:text-[#61dafb]">
              Login
            </NavLink>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Header;
