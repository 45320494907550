import { getAuth, onAuthStateChanged, updateProfile } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useAuth } from "../../../../Auth/AuthenticationContext";
import { db } from "../../../../Fribase/firebase.init";

const UserProfile = () => {
  const auth = getAuth();
  const storage = getStorage();

  const { loggedInUser, currentUser, setCurrentUser } = useAuth();

  // get current user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user ? user : null);
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register: registerProfile,
    handleSubmit: handleProfile,
    formState: { errors },
  } = useForm();

  const [fileUrl, setFileUrl] = useState(null);

  const accountCreationDate = new Date(loggedInUser?.accountCreated);

  // update profile picture
  // update profile picture
  const onFileChange = async (e) => {
    try {
      const file = e.target.files[0];
      console.log("img", file);

      //   const base64URL = await getBase64URL(file);

      // image resize
      //   const blob = await resizeImg(base64URL, 800, false);

      // Create the file metadata
      const metadata = {
        contentType: "image/jpeg",
      };

      const loading = toast.loading("Uploading photo...");

      let fileName = Math.floor(1000 + Math.random() * 9000) + "-" + file.name;

      // Upload file and metadata to the object 'images/mountains.jpg'
      const storageRef = ref(
        storage,
        `users/${loggedInUser.email.substring(
          0,
          loggedInUser.email.lastIndexOf("@")
        )}/` + fileName.replace(/ /g, "_")
      );
      await uploadBytes(storageRef, file, metadata);

      // get the download url
      const downloadURL = await getDownloadURL(storageRef);
      setFileUrl(downloadURL);

      // update profile
      await updateProfile(auth.currentUser, {
        photoURL: downloadURL || loggedInUser?.photoURL,
      });

      const docRef = doc(db, "users", loggedInUser?.email);
      const payload = {
        photoURL: downloadURL || loggedInUser?.photoURL,
        timestamp: serverTimestamp(),
      };

      await setDoc(docRef, payload, { merge: true });

      toast.dismiss(loading);
      toast.success("Photo upload successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  // upload multiple images

  // update user profile
  const onSubmit = async (data) => {
    data.email = loggedInUser.email;
    data.eventName = data.eventName || loggedInUser.eventName;
    data.photoURL = fileUrl || loggedInUser.photoURL;
    console.log("onsubmit from", data);

    const {
      displayName,
      email,
      phone,
      photoURL,
      description,
      company,
      address,
    } = data;

    if (displayName && email && phone && address) {
      const loading = toast.loading("Please wait...");

      try {
        // update profile
        await updateProfile(auth.currentUser, {
          displayName,
          photoURL: fileUrl || photoURL,
        });

        const docRef = doc(db, "users", loggedInUser.email);
        const payload = {
          displayName,
          email,
          photoURL: fileUrl || photoURL,
          phone,
          description,
          company,
          address,
          timestamp: serverTimestamp(),
        };

        console.log("payload", payload);

        await setDoc(docRef, payload, { merge: true });

        toast.dismiss(loading);
        toast.success("Profile updated successfully!");
      } catch (error) {
        toast.dismiss(loading);
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      <div className="flex-1 xl:overflow-y-auto text-left">
        <div className="max-w-4xl mx-auto py-6 px-6 lg:px-8">
          {/* Email verification alert */}
          {currentUser?.emailVerified ? null : (
            <div className="alert flex flex-row items-center bg-yellow-100 p-5 rounded-xl border-b-2 border-yellow-300 shadow-4xl mb-6">
              <div className="alert-icon flex items-center bg-yellow-100 border-2 border-yellow-600 justify-center h-10 w-10 flex-shrink-0 rounded-full">
                <span className="text-yellow-700">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className="h-6 w-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="alert-content ml-4 tracking-wide">
                <div className="alert-title font-semibold text-lg text-yellow-800">
                  Your Email is Not Verified!
                </div>
                <div className="alert-description text-sm text-amber-700">
                  Please go to your email inbox and verify your email address to
                  click the verification link.
                </div>
              </div>
            </div>
          )}

          <form
            className="space-y-8 divide-y divide-y-blue-gray-200"
            onSubmit={handleProfile(onSubmit)}
          >
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-6">
                <h2 className="text-2xl font-semibold text-gray-900">
                  Profile
                </h2>
                <p className="mt-1 text-sm text-blue-gray-500">
                  This information will be displayed publicly so be careful what
                  you share.
                </p>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="photo"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  Photo
                </label>
                <div className="mt-2 flex items-center">
                  <img
                    className="inline-block object-cover object-center w-36 h-36 rounded-full"
                    src={fileUrl || loggedInUser?.photoURL}
                    alt=""
                  />
                  <div className="ml-4 flex  cursor-pointer ">
                    <div className="relative py-2 px-4 cursor-pointer border-gray-300 rounded-md shadow-sm flex items-center border border-transparent text-sm sm:text-base font-medium text-white transition-colors duration-300 bg-brand-accent-700 bg-[#0891b2]  hover:bg--800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
                      <label
                        htmlFor="user-photo"
                        className=" relative pointer-events-none"
                      >
                        <span className="  text-sm font-semibold text-gray-100 tracking-wide cursor-pointer">
                          Change
                        </span>
                      </label>
                      <input
                        id="user-photo"
                        name="user-photo"
                        type="file"
                        onChange={onFileChange}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="displayName"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  name="displayName"
                  id="displayName"
                  placeholder="Your Full Name"
                  defaultValue={loggedInUser?.displayName}
                  {...registerProfile("displayName", {
                    required: "Name is required",
                    minLength: { value: 6, message: "Name minimum 2 words" },
                  })}
                  className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                    errors.displayName
                      ? "focus:border-red-500 focus:ring-red-500"
                      : "focus:border-blue-500 focus:ring-blue-500"
                  } `}
                />

                <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                  {errors.displayName && errors.displayName.message}
                </span>
              </div>

              <div className="sm:col-span-6">
                <label
                  htmlFor="description"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  About me
                </label>
                <div className="mt-2">
                  <textarea
                    id="description"
                    name="description"
                    placeholder="Write something about your self..."
                    {...registerProfile("description")}
                    defaultValue={loggedInUser?.description}
                    rows={3}
                    className="block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm text-blue-gray-900 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <p className="mt-3 text-sm text-blue-gray-500">
                  Brief description for your profile.
                </p>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="company"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  Profession
                </label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  placeholder="Your Profession"
                  {...registerProfile("company")}
                  defaultValue={loggedInUser?.company}
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>

            <div className="pt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-6">
                <h2 className="text-xl font-medium text-blue-gray-900">
                  Personal Information
                </h2>
                <p className="mt-1 text-sm text-blue-gray-500">
                  This information will be private and secure so don't worry
                  about it.
                </p>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  Email address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  defaultValue={loggedInUser?.email}
                  disabled
                  className="mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  Phone number
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Your Phone Number"
                  defaultValue={loggedInUser?.phone}
                  {...registerProfile("phone", {
                    required: "Contact number is required!",
                    pattern: {
                      value: /^(01[3-9])(\d{8})$/,
                      message:
                        "Phone number must be a valid 11 digit BD number",
                    },
                  })}
                  className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                    errors.phone
                      ? "focus:border-red-500 focus:ring-red-500"
                      : "focus:border-blue-500 focus:ring-blue-500"
                  } `}
                />

                <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                  {errors.phone && errors.phone.message}
                </span>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="address"
                  className="block text-sm sm:text-base font-medium text-blue-gray-900"
                >
                  Present address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Your Present Address"
                  defaultValue={loggedInUser?.address}
                  {...registerProfile("address", {
                    required: "Address is required",
                    minLength: { value: 6, message: "Address minimum 2 words" },
                  })}
                  className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                    errors.address
                      ? "focus:border-red-500 focus:ring-red-500"
                      : "focus:border-blue-500 focus:ring-blue-500"
                  } `}
                />

                <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                  {errors.address && errors.address.message}
                </span>
              </div>

              {/* Multiple Photo */}

              {/* Preview multiple images */}

              <p className="text-sm text-blue-gray-500 sm:col-span-6">
                This account was created on{" "}
                <span>{accountCreationDate?.toLocaleString()}</span>
              </p>
            </div>

            <div className="pt-8 flex justify-end">
              <button
                type="submit"
                className=" bg-blue-400 ml-3 inline-flex justify-center py-2.5 px-6 border border-transparent shadow-sm text-sm sm:text-base font-semibold rounded-md text-white transition-colors duration-300 bg-brand-accent-700 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
              >
                Update{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 h-6 w-6 text-sm sm:text-base font-medium text-gray-50"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
