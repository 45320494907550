import { configureStore } from "@reduxjs/toolkit";
import cartReducer from './cartSlice'
import favoriteReducer from './favoriteSlice'
// import productReducer from './productSlice'



const store = configureStore({
    reducer:{
        cart: cartReducer,
        favorite:favoriteReducer,
        // product: productReducer,
        // products:
    }
})
export default store;