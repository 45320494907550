import React from 'react';
import AboutImag from '../../imgs/about2.jpg'
import AboutImag1 from '../../imgs/about2.jpg'

const AboutHeader = () => {
    return (
        <div  className=' max-w-[1536px] ml-auto mr-auto'>
            <img className='w-full md:h-60' src={AboutImag1} alt="" />
        </div>
    );
};

export default AboutHeader;