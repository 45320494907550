import { Filter } from "heroicons-react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ListingProductView from "./ListingProductView";
import './ListingProduct.css'


const ListingProduct = (props) => {

  const {allCar}=props
  console.log("Post", allCar);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // We start with an empty list of allCar.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    // Fetch allCar from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading allCar from ${itemOffset} to ${endOffset}`);
    setCurrentItems(allCar.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allCar.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allCar]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allCar.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div>
      {/* {post.length=== 0 ?(
            <Loding></Loding>):( */}
      <div className="grid flex md:grid-cols-4 sm:grid-cols-2 gap-6 container ml-auto mr-auto m-2">
        {currentItems?.filter((car)=>car?.status === "Active").map((allCar) => (
          <ListingProductView allCar={allCar} key={allCar.id}></ListingProductView>
        ))}
      </div>
      <br />
      <br />

      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"

     
      />

      {/* )} */}
    </div>
  );
};

export default ListingProduct;
