import React from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../Auth/AuthenticationContext";

import { toast } from "react-hot-toast";

import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../../Fribase/firebase.init";

const CardSectionImages = ({ item }) => {
  // const { allComments } = useContext(BookingContext);
  const { loggedInUser, currentUser } = useAuth();

  const navigate = useNavigate();

  const handleViews = async (id) => {
    // toast.dismiss();
    const userEmail = loggedInUser.email.split("@");
    const viewId = Math.floor(1000 + Math.random() * 9000) + "-" + userEmail[0];

    try {
      //   const loading = toast.loading("Please wait a moment...");

      const payload = {
        // ...data,
        viewId,
        userEmail: loggedInUser?.email,

        carId: id,
        viewUser: currentUser?.photoURL,
        createdAt: serverTimestamp(),
      };

      // upload
      const viewRef = doc(db, "view", viewId);

      // store user info on database
      await setDoc(viewRef, payload, { merge: true });

      //   toast.dismiss(loading);
      // setComment(FavoriteData);
      //   toast.success("Your favorite added successfully ");
      // navigate("/listing");

      // <Navigate to="/listing"/>
    } catch (error) {
      toast.error(error?.message);
    }
  };
  return (
    <div>
      <div className="absolute mt-4 text-right ml-4">
        <p className=" -mb-6   bg-[#61dafb] rounded-xl text-white absolute text-right px-2 flex text-xs">
          <span>{item?.dprice || <span className="hidden"></span>}</span>{" "}
          <span className="ml-1"></span>
        </p>

        {/*  {(getFormatedTime(time)) .length < 20 ?(getFormatedTime(time)): <li>Available</li>} */}
      </div>
      <img
        // onClick={()=> handleFavorite(item?.id)}
        onClick={() => {
          navigate(`/product/${item.id}`);
          handleViews(item?.id);
        }}
        className=" ml-auto md:h-52 rounded-2xl mr-auto w-full p-2 card-img  aspect-square  ease-in-out cursor-pointer "
        // src={item?.postImages[0]?.url}
        src={item?.postImages && item?.postImages[0]?.url}
        alt=""
      />
    </div>
  );
};

export default CardSectionImages;
