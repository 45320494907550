// FavoriteContext.jsimport React, { createContext, useState } from "react";
import { createContext, useState } from 'react';

export const FavoriteContext = createContext();

export const FavoriteData = {
  id: "",
  href: "",
  category: "favorite",
  createdAt: "",
  featured: "No",
  status: "Draft",
  postImages: [],
  userName: "",
  email: "",
  phoneNumber: "",
  comments:"",
  carid:'',
  clicksUser:''
};

export default function FavoriteContextProvider({ children }) {
  const [favorite, setFavorite] = useState(FavoriteData);

  const listingDatafavorite = { favorite, setFavorite};

  return (
    <FavoriteContext.Provider value={listingDatafavorite}>
      {children}
    </FavoriteContext.Provider>
  );
}
