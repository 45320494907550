import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { BsFillCalendar2CheckFill } from "react-icons/bs";


import { BookingContext } from "../../context/AuthProvider/BookingContext";


import { useNavigate } from "react-router-dom";

import { AiOutlineUser } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import "./BlogSection.css";

const BlogSection = ({ item }) => {
  const {views, allCar,allBlog } = useContext(BookingContext);

  const viewCount = views?.filter((view) => item?.id === view?.carId);
  console.log("viewCount", viewCount);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;

  useEffect(() => {
    // Fetch allCar from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading allCar from ${itemOffset} to ${endOffset}`);
    setCurrentItems(allBlog.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allBlog.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allBlog]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allBlog.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div>
      <div className="grid md:grid-cols-4 gap-4 container ml-auto mr-auto mt-12 m-2">
        <div className="col-span-3 ">
          {/* .filter((car) => car?.status === "Active").map */}
          {allBlog?.map((item) => (
              <div kye={item.id}>
                <div className="grid grid-cols-5 mt-3 border birder-[#eaeaea] rounded-md">
                  <div className="col-span-2 p-2 ">
                    <img
                     onClick={() => navigate(`/blogDetials/${item.id}`)}
                      className="rounded-md md:h-56 w-full cursor-pointer"
                      src={item?.postImages[0]?.url}
                      alt=""
                    />
                  </div>
                  <div className="col-span-3 ml-2 text-left text-[#0a2357]">
                    <div className="hidden sm:block">
                      <ul className="flex ml-2 mt-6 ">
                        <p className="flex">
                          {" "}
                          <span className="mt-1">
                            <AiOutlineUser></AiOutlineUser>
                          </span>{" "}
                          <span className="ml-2">Admin</span>
                        </p>

                        <p className="flex ml-4">
                          {" "}
                          <span className="mt-1">
                            <BiCommentDetail></BiCommentDetail>
                          </span>{" "}
                          <span className="ml-2"> 0 Comments</span>
                        </p>

                        <p className="flex ml-4">
                          {" "}
                          <span className="mt-1">
                            <BsFillCalendar2CheckFill></BsFillCalendar2CheckFill>
                          </span>{" "}
                          <span className="ml-2">January 3, 2022</span>
                        </p>
                      </ul>
                    </div>
                    <h4 className="text-xl font-bold p-2">
                      2022 Audi Hybrid Gives Up Nothing With Its Optimized Fuel
                      Economy
                    </h4>
                    <h4 className="text-smtext-gray-400 p-2 hidden sm:block">
                      Description This July, Auto Trader are giving away a funky
                      Mini Electric worth £26,000 – plus up to £1,200 towards
                      your car insurance, ...
                    </h4>
                    <div className="hidden sm:block">
                      <div className="flex ml-2  ">
                        <p className="mt-2 font-bold">Read More</p>
                        <button class="ml-4 mb-1 bg-[#61dafb] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full text-xl">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3"></div>
                </div>
              </div>
            ))}
        </div>
        <div className="hidden sm:block">
          <div className="bg-[#f9f9f9] border border-[#e8f0fe] text-left mt-3 rounded-md">
            <div className="p-6">
              <h4 className="font-bold text-gray-500 mt-4">Search</h4>
              <input
                placeholder="Envato Elements"
                className="py-3 mb-2 w-full bg-[#e8f0fe] border border-[#e8f0fe] mt-4 rounded-md"
                type="text"
              />
            </div>
          </div>

          <div className="bg-[#f9f9f9] border border-[#e8f0fe] text-left  rounded-md mt-6">
            <div className="p-6">
              <h4 className="font-bold text-gray-500 mt-4">Categories</h4>
              <h4 className=" text-gray-500 mt-4 cursor-pointer">
                Auto Detailing
              </h4>
              <h4 className=" text-gray-500 mt-4 cursor-pointer">Car News</h4>
              <h4 className=" text-gray-500 mt-4 cursor-pointer">
                Car Reviews
              </h4>
              <h4 className=" text-gray-500 mt-4 cursor-pointer">
                Classic Cars
              </h4>
            </div>
          </div>

          {/* sidebar car */}
          <div className="bg-[#f9f9f9] border border-[#e8f0fe] text-left  rounded-md mt-6">
            <div className="">
              <h4 className="font-bold text-gray-500 mt-4 ml-4">Categories</h4>
              <div className="grid grid-cols-3 p-3  mt-4">
                <img className="rounded-md h-20" src="https://i.ibb.co/1L0sGsh/9087-about.jpg" alt="" />
                <div className="col-span-2 ml-2">
                  <p>
                    2022 Audi Hybrid Gives Up Nothing With Its Optimized Fuel
                    Economy
                  </p>
                </div>

                
              </div>

              <div className="grid grid-cols-3 p-3 ">
                <img className="rounded-md h-20" src="https://i.ibb.co/1L0sGsh/9087-about.jpg" alt="" />
                <div className="col-span-2 ml-2">
                  <p>
                    2022 Audi Hybrid Gives Up Nothing With Its Optimized Fuel
                    Economy
                  </p>
                </div>

                
              </div>

              <div className="grid grid-cols-3 p-3 ">
                <img className="rounded-md h-20" src="https://i.ibb.co/1L0sGsh/9087-about.jpg" alt="" />
                <div className="col-span-2 ml-2">
                  <p>
                    2022 Audi Hybrid Gives Up Nothing With Its Optimized Fuel
                    Economy
                  </p>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="page-num"
        previousLinkClassName="page-num"
        nextLinkClassName="page-num"
        activeLinkClassName="active"
      />
    </div>
    // <div className="rounded-2xl shadow  mt-6 ml-auto mr-auto  container text-left border border-slate-300  singel-card ">
    //   {/* <Link to={`/product/${item.id}`}> </Link> */}{" "}
    //   <div class="overflow-hidden  relative group">
    //     <div class="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300   absolute  to-transparent bg-gradient-to-t inset-x-0 bottom-2 pt-30 text-white flex ">
    //       <div>
    //         <div class="flex justify-between ">
    //           <span className="ml-4 mb-1 flex">
    //             {" "}
    //             <BsFillCameraFill></BsFillCameraFill>
    //             <span className="ml-1 -mt-1 ">{viewCount?.length}</span>
    //           </span>

    //           <span className="ml-52">
    //             <MdFavorite
    //             // onClick={() => handleFavorite(item?.id)}
    //             ></MdFavorite>
    //           </span>

    //           <span className=" ml-2">
    //             <TbGitCompare></TbGitCompare>
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //     <img
    //       onClick={() => navigate(`/product/${item.id}`)}
    //       className=" ml-auto md:h-52 rounded-2xl mr-auto w-full p-2 card-img  aspect-square  ease-in-out cursor-pointer "
    //       src={item?.postImages[0]?.url}

    //       alt=""
    //     />
    //     {/* <CardSectionImages item={item}></CardSectionImages> */}
    //   </div>
    //   <p className="mt-3 ml-3 ">{item.name}</p>
    //   <p className="ml-3">{item.make}</p>
    //   <p className="flex ml-3">
    //     <ReactStars
    //       count={item.Review}
    //       // onChange={ratingChanged}
    //       size={20}
    //       activeColor="#ffd700"
    //     />{" "}
    //     <span className="mt-1 ml-1 flex">({item.Review} Review) </span>
    //   </p>
    //   {/* button */}
    //   <hr className="ml-3 mr-3" />
    //   <ul className="flex ml-auto mr-auto mt-2 ml-3">
    //     <TbEngine className="mt-1"></TbEngine>

    //     <p className="ml-4 mt-1">
    //       {" "}
    //       <FaGasPump> </FaGasPump>
    //     </p>
    //     <p className="ml-4">partol</p>
    //     <TbManualGearbox className="ml-4 mt-1"></TbManualGearbox>
    //   </ul>
    //   <br />
    //   <Toaster />
    // </div>
  );
};

export default BlogSection;
