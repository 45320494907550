import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { ImCancelCircle } from "react-icons/im";

const DrimesonalModal = ({ open, setOpenModal }) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100"
        initialFocus={cancelButtonRef}
        onClose={setOpenModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity  " />
        </Transition.Child>

        <div className="fixed inset-0 z-100 overflow-y-auto -mt-6 max-w-[1536px] ml-auto mr-auto">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg ml-auto mr-auto px-2 pt-2 pb-2 text-left shadow-xl transition-all sm:my-8 md:w-2/4 ">
                <div>
                  <div>
                    <div className=" h-full border-t-4 border-t-[#61dafb] rounded-md border-8    border-white ml-auto mr-auto  z-100 bg-white ">
                      <div className="flex ml-auto mr-auto p-3 ">
                        <p
                          style={{ margin: "0 auto" }}
                          className="flex bg-gray-300  px-6 rounded-full"
                        >
                          <button
                            onClick={() => setOpenModal(false)}
                            ref={cancelButtonRef}
                            className=" text-white p-1 px-8 rounded-full m-1 hover:bg-[#61dafb] "
                          >
                            {" "}
                            Exterior
                          </button>

                          <button className=" text-white p-1 ml-4 px-10 rounded-full m-1 bg-[#61dafb]">
                            360º
                          </button>
                        </p>
                        <p
                          onClick={() => setOpenModal(false)}
                          ref={cancelButtonRef}
                          className=" mr-0 mt-3 cursor-pointer"
                        >
                          <ImCancelCircle></ImCancelCircle>
                        </p>
                      </div>

                      {/* 360 degri img */}

                      {/* <div className="" style={{width:"20%",border: '2px solid black', position:'relative'}} >
                        <ThreeSixty
                          amount={36}
                          imagePath="https://scaleflex.cloudimg.io/width/600/q35/https://scaleflex.ultrafast.io/https://scaleflex.airstore.io/demo/chair-360-36"
                          fileName="chair_{index}.jpg?v1"
                        />
                      </div> */}

                      {/* <img
                        className="w-full "
                        src="https://i.ibb.co/cwF9dXN/8121-yellow-sport-car-with-black-autotuning-highway-front-view.jpg"
                        alt=""
                      />
                      <hr /> */}
                      {/* <div className="w-full flex justify-between">

                      <iframe src="https://momento360.com/e/u/cd561b3e93a741af9572c7af02ec8681?utm_campaign=embed&utm_source=other&heading=391.1&pitch=57.7&field-of-view=75&size=medium&display-plan=true"></iframe>



                      <iframe src="https://momento360.com/e/u/df19c16df61845c687e7f8d9f5fd9502?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true"></iframe>
                      </div> */}

                      <img
                        className="w-full h-full "
                        src="https://i.ibb.co/cwF9dXN/8121-yellow-sport-car-with-black-autotuning-highway-front-view.jpg"
                        alt=""
                      />
                      <hr />
                      <div className="w-full md:flex justify-between">
                        <iframe title="myFrame" className="w-full m-1" src="https://momento360.com/e/u/cd561b3e93a741af9572c7af02ec8681?utm_campaign=embed&utm_source=other&heading=391.1&pitch=57.7&field-of-view=75&size=medium&display-plan=true"></iframe>

                        <iframe title="myFrame" className="w-full m-1"  src="https://momento360.com/e/u/df19c16df61845c687e7f8d9f5fd9502?utm_campaign=embed&utm_source=other&heading=0&pitch=0&field-of-view=75&size=medium&display-plan=true"></iframe>
                      </div>

                      {/* <div className="flex ">
                        {product?.postImages &&
                          product?.postImages?.map((img, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                setImgId(index);
                              }}
                              className="flex "
                            >
                              <img className=" w-full" src={img?.url} alt="" />
                            </div>
                          ))}
                      </div> */}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DrimesonalModal;

// import { Fragment, useRef } from "react";
// import { Dialog, Transition } from "@headlessui/react";

// const DrimesonalModal = ({ open, setOpen, product, imgId }) => {
//   const cancelButtonRef = useRef(null);
//   console.log('product',product)
//   console.log('imgId',imgId)

//   return (
//     <Transition.Root show={open} as={Fragment}>
//       <Dialog
//         as="div"
//         className="relative z-10"
//         initialFocus={cancelButtonRef}
//         onClose={setOpen}
//       >
//         <Transition.Child
//           as={Fragment}
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
//         </Transition.Child>

//         <div className="fixed inset-0 z-10 overflow-y-auto">
//           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//               enterTo="opacity-100 translate-y-0 sm:scale-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//             >
//               <Dialog.Panel className="relative transform overflow-hidden rounded-lg ml-auto mr-auto px-2 pt-2 pb-2 text-left shadow-xl transition-all sm:my-8 ">
//                 <div className=" w-3/4 ">
//                   <img className=""  src={product && product[imgId]?.url} alt="" />

//                 </div>
//                 {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
//                   <button
//                     type="button"
//                     className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
//                     onClick={() => setOpen(false)}
//                   >
//                     Deactivate
//                   </button>
//                   <button
//                     type="button"
//                     className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
//                     onClick={() => setOpen(false)}
//                     ref={cancelButtonRef}
//                   >
//                     Cancel
//                   </button>
//                 </div> */}
//               </Dialog.Panel>
//             </Transition.Child>
//           </div>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   );
// };

// export default DrimesonalModal;
