import React, { useContext } from "react";

import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { GoChecklist } from "react-icons/go";
import { BiMessageRoundedDots } from "react-icons/bi";
import { MdFavorite } from "react-icons/md";
import Graphs from './Graphs';
import { useSelector } from "react-redux";
import AuthContext from './../../../context/AuthProvider/AuthContext';
import { BookingContext } from "../../../context/AuthProvider/BookingContext";
import { useAuth } from "../../../Auth/AuthenticationContext";


const transactions = [
  {
    id: 1,
    name: "Payment to Molly Sanders",
    href: "#",
    amount: "$20,000",
    currency: "USD",
    status: "success",
    date: "July 11, 2020",
    datetime: "2020-07-11",
  },
  // More transactions...
];
const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

// useEffect(() => {
//   window.scrollTo(0, 0);
// }, []);


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DHome = () => {
  const { userCar, allUser, allCar, favorites,allComments } = useContext(BookingContext);
  const { loggedInUser, currentUser, setCurrentUser } = useAuth();
  const items = useSelector((state)=>state.cart)
  const itemhFavorite  = useSelector((state)=>state.favorite)
  const {profile,setProfile} = useContext(AuthContext)
  return (
    <div>
      <main className="flex-1 pb-8">
        {/* Page header */}
        {/* Page header */}
        {/* Page header */}
        <div className="bg-white shadow">
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="min-w-0 flex-1">
                {/* Profile */}
                <div className="flex items-center">
                  <img
                    className="hidden h-16 w-16 rounded-full sm:block"
                    src={loggedInUser?.photoURL}
                    alt=""
                  />
                  <div>
                    <div className="flex items-center">
                      <img
                        className="h-16 w-16 rounded-full sm:hidden"
                        src={loggedInUser?.photoURL}
                        alt=""
                      />
                      <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      Wellcome , {loggedInUser?.displayName}
                      </h1>
                    </div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt className="sr-only">Company</dt>
                      <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                        {/* <BuildingOfficeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            /> */}
                        Duke street studio
                      </dd>
                      <dt className="sr-only">Account status</dt>
                      <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                        {/* <CheckCircleIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                              aria-hidden="true"
                            /> */}
                        Verified account
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Add money
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                >
                  Send money
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        {/*  */}

        <div className="mt-8">
          <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <h2 className="text-lg font-medium leading-6 text-gray-900">
              Overview
            </h2>
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              <div className="grid grid-cols-2 bg-white shadow-md p-7 rounded-md ">
                <i className="text-6xl my-auto text-[#0891b2]">
                  {" "}
                  <GoChecklist></GoChecklist>
                </i>

                <div>
                  <h2 className="text-2xl font-bold text-gray-600">{allCar?.length}</h2>
                  <p className="text-xl  text-gray-600">Published</p>
                </div>
              </div>

              <div className="grid grid-cols-2 bg-white shadow-md p-7 rounded-md ">
                <i className="text-6xl my-auto text-[#0891b2]">
                  {" "}
                  <BiMessageRoundedDots></BiMessageRoundedDots>
                </i>
                <div>
                  <h2 className="text-2xl font-bold text-gray-600">{allComments?.length}</h2>
                  <p className="text-xl  text-gray-600">Reviews</p>
                </div>
              </div>
              <div className="grid grid-cols-2 bg-white shadow-md p-7 rounded-md ">
                <i className="text-6xl my-auto text-[#0891b2]">
                  {" "}
                  <MdFavorite></MdFavorite>
                </i>
                <div>
                  <h2 className="text-2xl font-bold text-gray-600">{favorites?.length}</h2>
                  <p className="text-xl  text-gray-600">Favorites</p>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </main>
      <Graphs></Graphs>
    </div>
  );
};

export default DHome;
