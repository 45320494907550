import React from 'react';

const Location = () => {
    return (
        <div className=' w-full ml-auto mr-auto  overflow-hidden m-2  max-w-[1536px] '>
            <iframe title="myFrame" style={{width:'100%',height:'600px',borderRadius:'5px',borderColor:'black'}} src="https://maps.google.com/maps?q=Jamuna%20Future%20Park,%20Dhaka&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" ></iframe>
        </div>
    );
};

export default Location;