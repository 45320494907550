import React from "react";
import { useContext } from "react";
import { AiOutlineCalendar, AiOutlineFolderView } from "react-icons/ai";
import { BiData, BiUserCircle } from "react-icons/bi";
import { FaBeer } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth/AuthenticationContext";
import { BookingContext } from "../../context/AuthProvider/BookingContext";

const Bids = ({ myRef, product }) => {
  const { allComments, bids,views  } = useContext(BookingContext);
  const { currentUser, loggedInUser } = useAuth();
  const viewCount = views?.filter((view) => product?.id === view?.carId);

  return (
    <div ref={myRef}>
      <div className="">
        <br />

        <p className="font-bold text-xl mt-10 text-left">
       Car Name :  {product?.name}
        </p>

        <div className="grid grid-cols-2 border border-[#eeeef0] mt-10">
          <div className="text-left m-2">
            <p>
              Current Bid <span> </span> 
            </p>
            <p className="text-5xl font-bold mt-2">
              {" "}
              <span className=" -mt-2 font-bold"> ৳ </span>{" "}


              {bids?.filter((item) => item?.carid === product?.id)?.length !== 0
                ? Math.max(
                    ...bids
                      ?.filter((item) => item?.carid === product?.id)
                      ?.map((item) => item?.bid)
                  )
                : product?.price}
              {/* {Math.max(
                ...bids
                  ?.filter((item) => item?.carid === product?.id)
                  .map((item) => item.bid)
              )} */}
            </p>
            <p className="mt-2">
            BID TO WIN * T&C applied *
              <span className=" underline">Text Here</span>
            </p>
          </div>

          <div className="text-left flex gird grid-cols-4 mt-2">
            <div>
              <p>Seller</p>
              <p>Ending </p>
              <p>Bids </p>
              <p>Views </p>
            </div>
            <div className=" col-span-3 ml-6 ">
              <p className="flex font-bold">
                <BiUserCircle className="mt-1 mr-2"></BiUserCircle>{" "}
                {product.vandorName}
              </p>
              <p className="flex">
                <AiOutlineCalendar className="mt-1 mr-2"></AiOutlineCalendar>{" "}
                {product.date}
              </p>
              <p className="flex">
                <BiData className="mt-1 mr-2"></BiData>
                {bids?.filter((item) => item?.carid === product?.id)?.length}
              </p>
              <p className="flex ">
                <AiOutlineFolderView className="mt-1 mr-2"></AiOutlineFolderView>{" "}
                {viewCount?.length}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4  bg-[#eeeef0] p-3">
          <p>
            <Link to={`/bids/${product.id}`}>
              <button class="bg-white  text-[#0a2357] font-bold py-2 px-4 rounded">
                Place Bid
              </button>
            </Link>
          </p>
          <p className="mt-1">How buying works</p>
          <p className="mt-1">watch this auction</p>
          <p className="mt-1">Notify me of 911s</p>
        </div>
      </div>
    </div>
  );
};

export default Bids;
