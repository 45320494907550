import React from "react";
import Header from "../../../shared/Header";
import { TbEngine, TbManualGearbox } from "react-icons/tb";
import { AiFillCar, AiFillLike, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { FaGasPump } from "react-icons/fa";
import { IoMdPhotos } from "react-icons/io";
import { GoDashboard } from "react-icons/go";
import Footer from "../../Footer";

import { Dialog } from "primereact/dialog";
import { useState } from "react";
import ReviewsPage from "./ReviewsPage";

const CarDetailsLogo = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [showVideos, setPhotos] = useState(false);

  const onHideDialog = () => {
    setShowDialog(false);
    setPhotos(false);
  };

  const ReviewsView = async (rowData) => {
    // setUpdateData(rowData);
    setShowDialog(true);
  };
  const Photos = async (rowData) => {
    // setUpdateData(rowData);
    showVideos(true);
  };

  return (
    <div>
      <Header></Header>
      <br></br>
      <br></br>
      <br></br>

      <div className="grid grid-cols-4 gap-5 ml-auto mr-auto container  overflow-hidden w-5/6">
        <div className="col-span-3 shadow-2xl ">
          <div className="grid grid-cols-3 p-2 shadow-sm">
            <div className="">
              <img
                className="w-4/6"
                src="https://i.ibb.co/wpbbGZX/car.jpg"
                alt="car"
                border="0"
              />
            </div>

            <div className="col-span-2 text-left">
              <p className="font-bold text-red-600 text-xl mb-2">
                Aston Martin Vantage
              </p>

              <div className="mb-2 flex ">
                <p>Autocar rating </p>

                <div class="flex ml-4 mb-2">
                  <span class="sr-only">4 out of 5 stars</span>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-slate-200 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                </div>

                <p className="ml-2">(8/10)</p>
              </div>
              <p className="">
                <span className="font-bold text-red-500 "> ₹ 3.40 </span>{" "}
                <span className="ml-2 underline cursor-pointer">
                  {" "}
                  crore On Road
                </span>{" "}
                <span className="ml-2 underline cursor-pointer">
                  {" "}
                  Price in Delhi Change City{" "}
                </span>
              </p>
            </div>
          </div>
          <hr></hr>
          <br></br>
          {/* footer modal sections */}

          <div className="grid grid-cols-4">
            <div className="">
              <TbEngine className="text-4xl ml-auto mr-auto mb-2"></TbEngine>
              <p className="font-bold">Engine</p>
              <p>3982cc</p>
              <div className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2">
                <p>
                  <AiFillLike className="text-2xl text-white"></AiFillLike>
                </p>
                <p className="ml-2">Reviews</p>
              </div>
            </div>
            {/* number 2 */}
            <div className="">
              <FaGasPump className="text-4xl ml-auto mr-auto mb-2"></FaGasPump>
              <p className="font-bold">Fuel Type</p>
              <p>Petrol</p>

              <div
                onClick={() => ReviewsView()}
                className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2 cursor-pointer"
              >
                <p>
                  <IoMdPhotos className="text-2xl ml-auto mr-auto"></IoMdPhotos>
                </p>
                <p className="ml-2">Photo</p>
              </div>
            </div>
            {/* 3 numbers */}

            <div  className="">
              <TbManualGearbox className="text-4xl ml-auto mr-auto mb-2"></TbManualGearbox>
              <p className="font-bold">Transmission</p>
              <p>Torque Converter Auto</p>
              <div onClick={() => Photos()}  className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2 cursor-pointer">
                <p>
                  <AiOutlineVideoCameraAdd className="text-2xl ml-auto mr-auto"></AiOutlineVideoCameraAdd>
                </p>
                <p className="ml-2">Videos</p>
              </div>
            </div>
            {/* 4 numbers */}
            <div className="">
              <GoDashboard className="text-4xl ml-auto mr-auto mb-2"></GoDashboard>
              <p className="font-bold">Mileage</p>
              <p>NA</p>
              <div className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2">
                <p>
                  <AiFillCar className="text-2xl ml-auto mr-auto"></AiFillCar>
                </p>
                <p className="ml-2">Variant</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className=" h-40 "
          style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <img
            className="w-full"
            // src="https://i.ibb.co/hC2GGhs/add-img.png"
            alt="car"
            border="0"
          />
        </div>
      </div>

      {/* secend */}
      <br></br>
      <div className="grid grid-cols-4 gap-5 ml-auto mr-auto container  overflow-hidden w-5/6">
        <div className="col-span-3 shadow-2xl ">
          <div className="grid grid-cols-3 p-2 shadow-sm">
            <div className="">
              <img
                className="w-4/6"
                src="https://i.ibb.co/LJh6cDx/car2.jpg"
                alt="car"
                border="0"
              />
            </div>

            <div className="col-span-2 text-left">
              <p className="font-bold text-red-600 text-xl mb-2">
                Aston Martin Vantage
              </p>

              <div className="mb-2 flex ">
                <p>Autocar rating </p>

                <div class="flex ml-4 mb-2">
                  <span class="sr-only">4 out of 5 stars</span>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-amber-500 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    xmlns="http://www.w3.org/2000/svg"
                    class="shrink-0 text-slate-200 h-5 w-5"
                  >
                    <path d="M12.854 3.5a.979.979 0 0 0-1.708 0 26.978 26.978 0 0 0-2.057 4.762c-.139.431-.551.73-1.023.743a29.398 29.398 0 0 0-4.267.425c-.774.136-1.065 1.018-.515 1.556a31.484 31.484 0 0 0 3.41 2.892c.367.269.518.73.378 1.152a26.807 26.807 0 0 0-1.14 4.927c-.1.755.708 1.288 1.41.928a28.593 28.593 0 0 0 3.98-2.472 1.148 1.148 0 0 1 1.356 0 28.505 28.505 0 0 0 3.98 2.472c.701.36 1.51-.173 1.41-.928a26.81 26.81 0 0 0-1.14-4.928c-.14-.42.01-.882.378-1.151a31.497 31.497 0 0 0 3.41-2.892c.55-.538.26-1.42-.515-1.556a29.046 29.046 0 0 0-4.267-.425 1.097 1.097 0 0 1-1.023-.743 26.982 26.982 0 0 0-2.057-4.761Z"></path>
                  </svg>
                </div>

                <p className="ml-2">(8/10)</p>
              </div>
              <p className="">
                <span className="font-bold text-red-500 "> ₹ 3.40 </span>{" "}
                <span className="ml-2 underline cursor-pointer">
                  {" "}
                  crore On Road
                </span>{" "}
                <span className="ml-2 underline cursor-pointer">
                  {" "}
                  Price in Delhi Change City{" "}
                </span>
              </p>
            </div>
          </div>
          <hr></hr>
          <br></br>
          {/* footer modal sections */}

          <div className="grid grid-cols-4">
            <div className="">
              <TbEngine className="text-4xl ml-auto mr-auto mb-2"></TbEngine>
              <p className="font-bold">Engine</p>
              <p>3982cc</p>
              <div className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2">
                <p>
                  <AiFillLike className="text-2xl text-white"></AiFillLike>
                </p>
                <p className="ml-2">Reviews</p>
              </div>
            </div>
            {/* number 2 */}
            <div className="">
              <FaGasPump className="text-4xl ml-auto mr-auto mb-2"></FaGasPump>
              <p className="font-bold">Fuel Type</p>
              <p>Petrol</p>
              <div className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2">
                <p>
                  <IoMdPhotos className="text-2xl ml-auto mr-auto"></IoMdPhotos>
                </p>
                <p className="ml-2">Phonto</p>
              </div>
            </div>
            {/* 3 numbers */}

            <div className="">
              <TbManualGearbox className="text-4xl ml-auto mr-auto mb-2"></TbManualGearbox>
              <p className="font-bold">Transmission</p>
              <p>Torque Converter Auto</p>
              <div className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2">
                <p>
                  <AiOutlineVideoCameraAdd className="text-2xl ml-auto mr-auto"></AiOutlineVideoCameraAdd>
                </p>
                <p className="ml-2">Videos</p>
              </div>
            </div>
            {/* 4 numbers */}
            <div className="">
              <GoDashboard className="text-4xl ml-auto mr-auto mb-2"></GoDashboard>
              <p className="font-bold">Mileage</p>
              <p>NA</p>
              <div className="bg-black py-2 text-white ml-auto mr-auto flex justify-center items-center border-gray border-r-2">
                <p>
                  <AiFillCar className="text-2xl ml-auto mr-auto"></AiFillCar>
                </p>
                <p className="ml-2">Variant</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <br></br>

      <Footer></Footer>

      {/*  Dialog Sections*/}

      <Dialog
        keepInViewport={false}
        blockScroll
        className="shadow-2xl bg-white"
        maximizable
        header="Car Reviews"
        visible={showDialog}
        style={{ width: "90vw" }}
        onHide={() => onHideDialog("setShowDialog")}
        id="fname"
      >
        <ReviewsPage></ReviewsPage>
      </Dialog>
      <Dialog
        keepInViewport={false}
        blockScroll
        className="shadow-2xl bg-white"
        maximizable
        header="Car Reviews"
        visible={showVideos}
        style={{ width: "90vw" }}
        onHide={() => onHideDialog("setShowDialog")}
        id="fname"
      >
        <ReviewsPage></ReviewsPage>
      </Dialog>
    </div>
  );
};

export default CarDetailsLogo;
