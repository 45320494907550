import React from "react";
import about1 from "../../imgs/AboutUs/about.jpg";
import about from "../../imgs/AboutUs/about3.jpg";
import about3 from "../../imgs/AboutUs/about2.webp";
import "./About.css";
const AboutHero = () => {
  return (
    <div>
      <div className=" grid md:grid-cols-2 mt-28 container ml-auto mr-auto gap-4">
        <div className=" ">
          <img className="w-full" src={about1} alt="" />
        </div>
        <div className="mt-30 p-7  text-left  m-2 md:ml-20  ">
          <h2 className="text-4xl text-[#1a3775] font-bold md:mt-20 ">
            Welcome To damigari.com
          </h2>
          <p className="mt-8">
            Damigari.com is bangladesh first ever bidding & Retail site for
            exotic cars. Our site and innovative solutions connect buyers and
            sellers to match people with their perfect car. With our network
            spread across the Bangladesh, we still maintain a startup culture
            with innovation and passion for our people at the core of everything
            we do.
          </p>
          <p className="mt-5">
          Damigari.com has an award-winning brand, leadership team, and
            the best and brightest employees in the industry. Asfi Noor, Founder
            and CEO, Damigari.com
          </p>
        </div>
      </div>
      <div className="grid grid-cols-3 ">
        <div></div>
        <div className="phoneDivce ">
          {/* <img className=" -mt-40 -ml-40 h-120 z-0  hidden sm:block " src={about} alt="" /> */}
          <img
            className="phoneDivce -mt-36 -ml-10 mt w-80 z-0  hidden sm:block "
            src={about}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
