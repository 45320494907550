import { doc, setDoc, deleteDoc } from "firebase/firestore";
import React, { useContext } from "react";
import { AiTwotoneDelete } from "react-icons/ai";

import { BookingContext } from "../../../../context/AuthProvider/BookingContext";
import { db } from "../../../../Fribase/firebase.init";

import { ListingContext } from "./../../../../context/ListingContext";

const UserList = () => {
  const { allUser } = useContext(BookingContext);
  const { listing } = useContext(ListingContext);

  // delete user
  const handleDelete = async (person) => {
    alert("are you sure !")
    await deleteDoc(doc(db, "users", person.id));
  };

  // change schedule status
  const handleStatusChange = async (status) => {
    const scheduleData = {
      status,
    };

    // update the schedule status on database
    const scheduleRef = doc(db, "car", listing.id);
    await setDoc(scheduleRef, scheduleData, { merge: true });

    // toast success message
  };

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8 mt-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Users List</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add user
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 p-2">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Delete
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {/* map section  */}
                    {/* map section  */}
                    {/* map section  */}

                    {allUser?.map((person, id) => (
                      <tr key={person.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={person.photoURL}
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person.name}
                              </div>
                              <div className="text-gray-500  hidden sm:block">
                                {person.displayName}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500 mt-3  hidden sm:block">
                          {person?.email}
                        </td>

                        <td className="text-left relative whitespace-nowrap py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">
                          {person?.phone}
                        </td>
                        <td className="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500 mt-3  hidden sm:block">
                          {/* {person?.phone} */}
                          <AiTwotoneDelete
                            // onClick={() =>handleDelete(person)}
                            onClick={() => handleDelete(person)}
                            className="text-2xl cursor-pointer"
                          ></AiTwotoneDelete>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
