import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, Navigate } from "react-router-dom";
import facebookSvg from "../../src/imgs/Facebok.png";
import googleSvg from "../../src/imgs/google.png";

import { useAuth } from "./AuthenticationContext";
import { Toaster } from "react-hot-toast";
import { FaFacebookF } from "react-icons/fa";
import { GrGooglePlus } from "react-icons/gr";
import './ReginsterUser.css'

const RegistrationUser = () => {
  const [typePass, setTypePass] = useState(false);

  const { register, setJWTToken, signInWithGoogle, signInWithFacebook } =
    useAuth();

  const {
    register: registerSignUp,
    handleSubmit: handleSignUp,
    formState: { errors },
    watch,
  } = useForm();

  const handleResponse = (res) => {
    setJWTToken();

    toast.success("Registration Successful");
    Navigate("/login");
  };

  // Google Sign In
  const googleSignIn = () => {
    const loading = toast.loading("Please wait a moment...");
    signInWithGoogle()
      .then((res) => {
        toast.dismiss(loading);
        handleResponse(res);
      })
      .catch((err) => {
        toast.dismiss(loading);
        let message = err.code.split("auth/")[1].replace(/-/g, " ");
        toast.error(message.charAt(0).toUpperCase() + message.slice(1));
      });
  };

  // Facebook Sign In
  const facebookSignIn = () => {
    const loading = toast.loading("Please wait a moment...");
    signInWithFacebook()
      .then((res) => {
        toast.dismiss(loading);
        handleResponse(res);
      })
      .catch((err) => {
        toast.dismiss(loading);
        let message = err.code.split("auth/")[1].replace(/-/g, " ");
        toast.error(message.charAt(0).toUpperCase() + message.slice(1));
      });
  };

  // Sign In with Email and Password
  const onSubmit = (data) => {
    const loading = toast.loading("Please wait a moment...");
    const { name, phone, email, password } = data;

    // For Registration
    if (name && phone && email && password) {
      console.log("name phone email passwoed ok");

      register(name, phone, email.toLowerCase(), password)
        .then((res) => {
          console.log("responc", res);

          toast.dismiss(loading);
          setJWTToken();
          toast.success("Successfully Signed In!");
        })
        .catch((err) => {
          toast.dismiss(loading);
          let message = err.code.split("auth/")[1].replace(/-/g, " ");
          toast.error(message.charAt(0).toUpperCase() + message.slice(1));
        });
    }
  };

  return (
    <>
      <div className="mt-auto ">
        <div className="flex w-full mt-10 max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-800 lg:max-w-3xl m-3">
          <div className="w-full px-6 py-8 md:px-8 lg:w-1/2">
            <div className="flex justify-end -mt-2 -mx-6">
              <button>{/* <XIcon className="w-5 h-5" /> */}</button>
            </div>
            <div className="mt-4">
              <div className="w-full mx-auto bg-white">
                <div className="flex ml-auto mr-auto cursor-pointer gap-2 justify-center mt-4">
                  <i 
                   onClick={facebookSignIn}
                  className="hover:bg-white rounded-full p-2 border bg-[#2b77f2] border-sky-600 hover:text-black  text-white  font-bold">
                    {" "}
                    <FaFacebookF></FaFacebookF>{" "}
                  </i>
                  <i 
                     onClick={googleSignIn}
                  className=" hover:bg-white rounded-full p-2 border bg-[#2b77f2] border-sky-600 hover:text-black  text-white  font-bold">
                    <GrGooglePlus></GrGooglePlus>{" "}
                  </i>
                </div>

                {/* <div className="grid gap-3 mt-6">
                      <button
                        className="nc-will-change-transform flex w-full rounded-2xl bg-blue-50 px-4 py-3 transform transition-transform sm:px-6 hover:-translate-y-1"
                        onClick={googleSignIn}
                      >
                        <img
                          className="flex-shrink-0 w-6"
                          src={googleSvg}
                          alt=""
                        />
                        <h3 className="flex-grow text-center text-base font-medium text-gray-800">
                          Continue with Google
                        </h3>
                      </button>

                      <button
                        className="nc-will-change-transform flex w-full rounded-2xl bg-blue-50 px-4 py-3 transform transition-transform sm:px-6 hover:-translate-y-1"
                        onClick={facebookSignIn}
                      >
                        <img
                          className="flex-shrink-0 w-6"
                          src={facebookSvg}
                          alt=""
                        />
                        <h3 className="flex-grow text-center text-base font-medium text-gray-800">
                          Continue with Facebook
                        </h3>
                      </button>
                    </div> */}

                <div>
                 

                  <div className="my-4">
                    <form
                      className="space-y-6 text-left"
                      onSubmit={handleSignUp(onSubmit)}
                    >
                      <div>
                        <label
                          htmlFor="name"
                          className="block font-medium text-gray-800"
                        >
                          Full Name
                        </label>
                        <div className="mt-1">
                          <input
                            {...registerSignUp("name", {
                              required: "Name is required",
                              minLength: {
                                value: 6,
                                message: "Name minimum 2 words",
                              },
                            })}
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Your Full Name"
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-2xl shadow-sm placeholder-gray-500 focus:outline-none leading-wide ${
                              errors.name
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            }`}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.name && errors.name.message}
                          </span>
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="phone"
                          className="block font-medium text-gray-800"
                        >
                          Phone
                        </label>
                        <div className="mt-1">
                          <input
                            {...registerSignUp("phone", {
                              required: "Phone number is required",
                              pattern: {
                                value: /^(01[3-9])(\d{8})$/,
                                message:
                                  "Phone number must be a valid 11 digit BD number",
                              },
                            })}
                            id="phone"
                            name="phone"
                            type="number"
                            placeholder="Your Phone Number"
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-2xl shadow-sm placeholder-gray-500 focus:outline-none leading-wide ${
                              errors.phone
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            }`}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.phone && errors.phone.message}
                          </span>
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="email"
                          className="block font-medium text-gray-800"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            {...registerSignUp("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                            })}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="example@example.com"
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-2xl shadow-sm placeholder-gray-500 focus:outline-none leading-wide ${
                              errors.email
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            }`}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.email && errors.email.message}
                          </span>
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block font-medium text-gray-800"
                        >
                          Password
                        </label>
                        <div className="relative flex w-full flex-wrap items-stretch mt-3">
                          <input
                            {...registerSignUp("password", {
                              required: "Password is required",
                              pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                // /^(?=.[A-Za-z])(?=.\d)(?=.[@$!%#?&;:`"'%<,>./?^-_=+])[A-Za-z\d@$!%*#?&;:`"'%<,>./?^-_=+]{8,}$/i,
                                message:
                                  "Minimum eight characters, at least one letter, one number and one special character",
                              },
                            })}
                            id="password"
                            name="password"
                            type={typePass ? "text" : "password"}
                            placeholder="Your Password"
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-2xl shadow-sm placeholder-gray-500 focus:outline-none leading-wide ${
                              errors.password
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            }`}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.password && errors.password.message}
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <input
                              {...registerSignUp("TermsCondition", {
                                required: true,
                              })}
                              id="TermsCondition"
                              name="TermsCondition"
                              type="checkbox"
                              className={`h-4 w-4 text-blue-600  border-gray-300 rounded ${
                                errors.TermsCondition
                                  ? "focus:ring-red-500"
                                  : "focus:ring-blue-500"
                              }`}
                            />

                            <label
                              htmlFor="TermsCondition"
                              className="ml-2 block text-gray-800"
                            >
                              <span className="my-8 font-medium text-center">
                                Agree to our{" "}
                                <Link
                                  to="/support/terms-and-conditions/"
                                  className="text-blue-500 hover:text-blue-700"
                                >
                                  Terms of Service{" "}
                                </Link>
                                and{" "}
                                <Link
                                  to="/support/privacy-policy/"
                                  className="text-blue-500 hover:text-blue-700"
                                >
                                  Privacy Policy{" "}
                                </Link>
                                .
                              </span>
                            </label>
                          </div>
                        </div>
                        <span className="block font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                          {errors.TermsCondition &&
                            "Please accept our Terms & Conditions"}
                        </span>
                      </div>

                      <div>
                        <button
                          className=" bg-[#2b77f2] relative w-full h-auto inline-flex items-center justify-center rounded-full transition-colors text-base sm:text-lg font-semibold px-4 py-2 disabled:bg-opacity-70 bg-brand-accent-700 hover:bg-blue-400 flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 text-white"
                          type="submit"
                        >
                          Sign up
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="hidden bg-cover lg:block lg:w-1/2 login-url bg-blue-500 "

            //   style="backgroundImage: url('https://images.unsplash.com/photo-1606660265514-358ebbadc80d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1575&q=80');"
          >
            <div className="mt-60">
              <h2 className="text-2xl  font-semibold text-center text-gray-700 dark:text-white my-4 text-white">
                Sign in
              </h2>
              <p className=" text-slate-300 my-4">
                sign in here if you have an account
              </p>
              <Link to="/login">
                <button className=" px-14 rounded-full py-2 tracking-wide text-white transition-colors duration-300 transform  hover:bg-[#0e7490] focus:outline-none focus:bg-gray-600 border border-white font-bold">
                  Sign In
                </button>
              </Link>
            </div>
          </div>
          {/* Toast Notification */}
          <Toaster
            toastOptions={{
              duration: 3000,
            }}
          />
        </div>

        {/* Background Modal Opacity */}
      </div>
    </>
  );
};

export default RegistrationUser;
