import React from "react";
import { BsFillCameraFill } from "react-icons/bs";
import { FaGasPump } from "react-icons/fa";
import { TbEngine } from "react-icons/tb";
import { TbManualGearbox } from "react-icons/tb";
import { Navigate, useNavigate } from "react-router-dom";
import { MdFavorite } from "react-icons/md";

const FavoriteCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-2xl shadow  mt-6 ml-auto mr-auto  container text-left border border-slate-300  singel-card ">
      {/* <Link to={`/product/${item.id}`}> </Link> */}{" "}
      <div class="overflow-hidden  relative group">
        <div class="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300   absolute  to-transparent bg-gradient-to-t inset-x-0 bottom-2 pt-30 text-white flex ">
          
        </div>
        <img
          onClick={() => navigate(`/product/${item.id}`)}
          className=" ml-auto md:h-52 rounded-2xl mr-auto w-full p-2 card-img  aspect-square  ease-in-out cursor-pointer "
          src={item?.postImages[0]?.url}
          alt=""
        />
      </div>
      <p className="mt-3 ml-3 ">{item.name}</p>
      <p className="ml-3">{item.make}</p>
      {/* button */}
      <hr className="ml-3 mr-3" />
      <ul className="flex ml-auto mr-auto mt-2 ml-3">
        <TbEngine className="mt-1"></TbEngine>
        <p className="ml-4 mt-1">
          {" "}
          <FaGasPump> </FaGasPump>
        </p>
        <p className="ml-4">partol</p>
        <TbManualGearbox className="ml-4 mt-1"></TbManualGearbox>
      </ul>
      <br />
    </div>
  );
};

export default FavoriteCard;
