import React from 'react';

import Header from '../shared/Header';
import Location from './Location';
import Test from './Test';
import Test2 from './Test2';
import AboutHeader from './../About/AboutHeader';
import ContactForm from './ContactForm';
import Services from './Services';
import Footer from '../home/Footer';

const Contact = () => {
    return (
        <div>
            <Header></Header>
            <br /><br /><br /><br />
            <AboutHeader></AboutHeader>
            <Location></Location>
            <ContactForm></ContactForm>

            {/* <Services></Services> */}
            
            {/* <Test></Test> */}
            {/* <Test2></Test2> */}
            <Footer></Footer>
         
            
        </div>
    );
};

export default Contact;