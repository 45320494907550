import React, { useState } from "react";
import { useEffect } from "react";

import { useContext } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

import { BookingContext } from "../../context/AuthProvider/BookingContext";
import Services from "../Contact/Services";
import BlogBids from "./BlogBids";





const BlogBidDetails = ({ duration }) => {
  const [product, setProduct] = useState([]);

  const [time, setTime] = useState(duration);

  const {  allBlog } = useContext(BookingContext);
  const {  bids } = useContext(BookingContext);
  const { id } = useParams();

  const date1 = new Date(`${product.date} ${product.time}`);
  const date2 = new Date();
  const difarence = Math.abs(date1 - date2);
  // console.log('difarence date',difarence)

  // console.log("time data dekbo", date2);

  // console.log("time data dekbo time", product.time);
  // console.log("time data dekbo time", product.time);

  // console.log("repllydata  idddd", allCommentsRepllay.id);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setTimeout(() => {
      setTime(time - 1000);
    }, 1000);
  }, [time]);

  useEffect(() => {
    const getProducts = () => {
      setProduct(allBlog?.find((obj) => obj.id === id));
    };
    if (allBlog?.length > 0) {
      getProducts();
    }
  }, [allBlog, id]);
  console.log("Product", product);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // {
  //   console.log(
  //     "produt img",
  //     product?.postImages && product?.postImages[0]?.url
  //   );
  // }
  return (
    <div>
      <div className="container ml-auto mr-auto mt-4 ">
        <div className="grid md:grid-cols-12 sm:grid-cols-1 ml-auto mr-auto container p-6  mt-1 gap-4">
          <div className="overflow-hidden md:col-span-6">
            {/* <img src="https://i.ibb.co/55wYZZ4/1288-beautiful-car.jpg" alt="" /> */}
            <div className="">
              <Slider {...settings}>
                {product?.postImages &&
                  product?.postImages?.map((img, index) => (
                    <div
                      key={index}
                      // onClick={() => {
                      //   setShowModal(true);
                      //   setImgId(index);
                      // }}
                      className="flex"
                    >
                      <img className=" h-full w-full " src={img?.url} alt="" />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div className="md:col-span-3">
            <div className="border border-[#eeeef0]">
              <p className="bg-[#f2f2f2] text-left font-bold p-3">
                Bid Information
              </p>
              <hr />

              <ul className="flex justify-between p-2">
                <li className="text-xs">VID:</li>
                <li className="text-sm">{product?.vin}</li>
              </ul>
              <hr className="m-1" />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Engine:</li>
                <li className="text-sm">{product?.engine}</li>
              </ul>
              <hr className="m-1" />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Current Bid:</li>
                <li className="text-sm">


                {bids?.filter((item) => item?.carid === product?.id)?.length !== 0
                ? Math.max(
                    ...bids
                      ?.filter((item) => item?.carid === product?.id)
                      ?.map((item) => item?.bid)
                  )
                : product?.price}
                </li>
              </ul>
              <hr className="m-1" />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Time left: </li>
                <li className="text-sm"> {product?.date}</li>
              </ul>
              <hr />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Trim: </li>
                <li className="text-sm">{product?.trim}</li>
              </ul>
              <hr />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Exterior Color: </li>
                <li>{product?.color}</li>
              </ul>
              <hr />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Door: </li>
                <li>{product?.door}</li>
              </ul>
              <hr />
              <ul className="flex justify-between p-2">
                <li className="text-xs">Condition: </li>
                <li>{product?.Condition}</li>
              </ul>
              <hr />

              <p className="text-xs">
                {/* Lorem ipsum dolor sit amet consectetur adipisicing{" "} */}
              </p>
              <br />
            </div>
          </div>
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          <div className="md:col-span-3">
            <div className="border border-[#eeeef0]">
                <BlogBids
                    products={product}
                    duration={difarence}
                    allBlog={allBlog}
                ></BlogBids>
            </div>
          </div>
        </div>
      </div>
      <Services></Services>
    </div>
  );
};

export default BlogBidDetails;
