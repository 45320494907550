// import React, { useContext } from 'react';
// import { Navigate } from 'react-router-dom';
// //

// const PrivetRoute = ({children, ...rest}) => {
//     // const {token,setToken} = useContext(AuthContext)
//     // const {user, isLoading} = useAuth();
//     // if (isLoading){
//     //     return <CircularProgress></CircularProgress>
//     // }
//     return localStorage.getItem("token") ? children:   <Navigate  to="/login" replace  />
// };

// export default PrivetRoute;

import React from "react";
import { Navigate, Redirect, Route } from "react-router-dom";
import { useAuth } from "./../Auth/AuthenticationContext";

const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser } = useAuth();

  return currentUser || localStorage.getItem("token") ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;







// import React from 'react';
// import { Redirect, Route } from 'react-router-dom';
// import { useAuth } from './AuthContext';

// const PublicRoute = ({ children, ...rest }) => {
//   const { currentUser } = useAuth();

//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         !currentUser ? (
//           children
//         ) : (
//           <Redirect
//             to={{
//               pathname: '/',
//               state: { from: location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

// export default PublicRoute;
