import React from "react";
import { useContext } from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BookingContext } from "../../../../context/AuthProvider/BookingContext";
import FavoriteCard from "./FavoriteCard";

const Favorite = () => {
  const { userCar, allUser, allCar, favorites } = useContext(BookingContext);

  return (
    <div>
      <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-6 container ml-auto mr-auto m-2">
        {console.log("favorites data", favorites)}
        {/* .postImages[0]?.url */}

        {allCar
          ?.filter(
            (object1) =>
              favorites?.some((object2) => object1?.id === object2?.carId)
          )
          ?.map((item) => (
            <FavoriteCard key={item.id} item={item}></FavoriteCard>
          ))}
      </div>
    </div>
  );
};

export default Favorite;
