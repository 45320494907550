import React from "react";
import { useContext } from "react";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { BiTimeFive } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BookingContext } from "../../context/AuthProvider/BookingContext";


const BlogTimer = ({ duration, product, executeScroll }) => {
  const [time, setTime] = useState(0);
  const [isSticky, setStickyy] = useState(false);
  const { allComments, bids } = useContext(BookingContext);
  useEffect(() => {
    setTimeout(() => {
      setTime(time - 1000);
    }, 1000);
  }, [time]);
  useEffect(() => {
    setTime(Number(duration));
  }, [duration]);
  // console.log("product timer11234",Number(duration))
  // console.log("bids..............", bids);

  const getFormatedTime = (milliseconds) => {
    let total_seconds = parseInt(Math.floor(milliseconds / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    let days = parseInt(Math.floor(total_hours / 24));

    let seconds = parseInt(total_seconds % 60);
    let minutes = parseInt(total_minutes % 60);
    let hours = parseInt(total_hours % 24);
    return `${days}d: ${hours}h: ${minutes} m: ${seconds}s`;
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 500) {
      setStickyy(true);
    } else {
      setStickyy(false);
    }
  });
  // console.log("allComments", allComments);
  return (
    <header
      className={` z-30 cursor-pointer ${
        !isSticky ? "" : "top-20 fixed scrolled  ml-auto mr-auto  max-w-[1536px]"
      }`}
      onClick={executeScroll}
    >
      <div>
        <div></div>

        <div style={{marginRight:"0 auto"}} className="grid grid-cols-4 gap-1">
          <div className="flex col-span-3 bg-[#61dafb] text-white font-bold py-3 px-4 rounded justify-between">
          <p className="flex md:w-60 ">
              <BiTimeFive className="mt-1 mr-1"></BiTimeFive>{" "}
              <span className="text-gray-300 mr-1"> Time Left</span>
              {(getFormatedTime(time)).length < 20 ?(getFormatedTime(time)): <li>Available</li>}
              {console.log('time chak karbo length',getFormatedTime(time)?.length   )}



             
              {/* {diffDays}:{diffHours}:{diffMinutes}:{diffSeconds} */}
            </p>
            <p>
              <span className="text-gray-300 ml-1">HighBid</span> ৳
              {/* {  Math.max(...bids?.filter((item) => item?.carid === product?.id).map(item => item.bid)) || (product?.price)} */}
              {bids?.filter((item) => item?.carid === product?.id)?.length !== 0
                ? Math.max(
                    ...bids
                      ?.filter((item) => item?.carid === product?.id)
                      ?.map((item) => item?.bid)
                  )
                : product?.price}
              {/* {console.log("bids bids", bids)} */}
              {/* {
                Math.max(...bids?.filter((item) => item?.carid === product?.id).map(item => item.bid)) 

              } */}
              {/* {console.log("product.price", product.price)} */}
              {/* {console.log('bis test data',Math.max(...bids?.filter((item) => item?.carid === product?.id).map(item => item.bid)))} */}
              {/* {
                  bids?.filter((item) => item?.carid === product?.id).map((item)=>(<span key={item.id}>

                     {Math.max(...item.bid)}
                     {console.log('item.bid',item.bid)}
      
                  </span>))
                } */}
              {/* {console.log("bid chak bids", bids)} */}
            </p>
            <p className="hidden sm:block">
              {" "}
              <span className="text-gray-300 ">Bids </span>
              {bids?.filter((item) => item?.carid === product?.id)?.length}
            </p>
            <p className="hidden sm:block">
              <span className="text-gray-300  ">Comments</span>{" "}
              {
                allComments?.filter((item) => item?.carid === product?.id)
                  ?.length
              }{" "}
            </p>
          </div>

          <button className="bg-[#61dafb] hover:bg-blue-700 text-white font-bold rounded">
            <Link to={`/blogbid/${product.id}`}>Place Bid </Link>
            {/* <Link to={`/product/${item.id}`}> </Link>  */}
          </button>
        </div>
      </div>
    </header>
  );
};

export default BlogTimer;
