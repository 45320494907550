import React from 'react';
import Services from '../Contact/Services';
import ChooseUs from '../home/ChooseUs';
import Footer from '../home/Footer';

import Testimonials from '../home/HomeCard/Testimonials';
import Header from '../shared/Header';
import AboutHeader from './AboutHeader';
import AboutHero from './AboutHero';
import OurTeam from './OurTeam';

const About = () => {
    return (
        <div>
            <Header></Header>
            <br /><br /><br /><br />
            <AboutHeader></AboutHeader>
            <AboutHero></AboutHero>
            <ChooseUs></ChooseUs>
            <br /><br />
            {/* <OurTeam></OurTeam> */}
            <br /><br />
            <Testimonials></Testimonials>
            <Footer></Footer>
           

          
        </div>
    );
};

export default About;