import React from 'react';

const VehicleInformation = ({product}) => {
  console.log('vehice informaiton ',product)
    return (
        <div className="  md:grid-cols-2 justify-between md:gap-4 mt-5 ml-auto mr-auto">
        {/* fast table */}
        <div className=" mt-5">
          <div className="flex justify-between mt-3 mb-3">
            <p className="">Trim</p>
            <p className=" font-bold uppercase">{product?.trim}</p>
          </div>
          <hr />

          <div className="flex justify-between mt-3 mb-3">
            <p className="">Engine</p>
            <p className=" font-bold uppercase">{product?.engine}</p>
          </div>
          <hr />
          <div className="flex justify-between mt-3 mb-3">
            <p className="">VIN</p>
            <p className="  font-bold uppercase">{product?.vin}</p>
          </div>
         
          <hr />
          <div className="flex justify-between mt-3 mb-3">
            <p className="">Exterior Color</p>
            <p className="  font-bold">{product?.color}</p>
          </div>
          <hr />
          <div className="flex justify-between mt-3 mb-3">
            <p className="">Condition</p>
            <p className="  font-bold">{product?.Condition}</p>
          </div>
          <hr />
          <div className="flex justify-between mt-3 mb-3">
            <p className="">Doors</p>
            <p className="  font-bold">{product?.door}</p>
          </div>
          <hr />
          <div className="flex justify-between mt-3 mb-3">
            <p className="">Gas Mileage</p>
            <p className="  font-bold">{product?.gas}</p>
          </div>
          <hr />
        </div>
        {/* secend table */}

        
      </div>
    );
};

export default VehicleInformation;