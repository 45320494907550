import React, { createContext, useState } from "react";

export const BlogContext = createContext();

export const blogDefaultData = {
  id: "",
  href: "",
  category: "blog1",
  createdAt: "",
  featured: "No",
  status: "Draft",
  reviews: [],
  listingDate: "",
  postImages: [],
  vandorName: "",
  email: "",
  make: "",
  Condition: "",
  name: "",
  model: "",
  color: "",
  Review: "",
  Type: "",
  Transmission: "",
  Yea: "",
  price:"",
  description: "",
  address: "",
  date: "",
  time:"",
  trim:"",
  engine:"",
  vin:"",
  door:"",
  gas:"",
  dprice:"",
};

export default function BlogContextProvider({ children }) {
  const [blog, setBlog] = useState(blogDefaultData);

  const blogData = { blog, setBlog };

  return (
    <BlogContext.Provider value={blogData}>
      {children}
    </BlogContext.Provider>
  );
}
