import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../Auth/AuthenticationContext";
import { BookingContext } from "../../context/AuthProvider/BookingContext";
import { BidData, BidsContext } from "../../context/BidsContext";
import { db } from "../../Fribase/firebase.init";

function BlogBids({ duration,products}) {

    const [product, setProduct] = useState([]);
    const navigate = useNavigate();

    const [time, setTime] = useState(duration);
    const [count, setCount] = useState(0);
  
    const { allCommentsRepllay, allBlog } = useContext(BookingContext);
    const {  bids } = useContext(BookingContext);
    const { id } = useParams();

    const { loggedInUser, currentUser } = useAuth();

    const { bid, setBid } = useContext(BidsContext);
    const [postImages] = useState([]);


    const { allComments } = useContext(BookingContext);
    useEffect(() => {
        setTimeout(() => {
            setTime(time - 1000);
        }, 1000);
    }, [time]);
    useEffect(() => {
        setTime(Number(duration));
    }, [duration]);


    const getFormatedTime = (milliseconds) => {
        let total_seconds = parseInt(Math.floor(milliseconds / 1000));
        let total_minutes = parseInt(Math.floor(total_seconds / 60));
        let total_hours = parseInt(Math.floor(total_minutes / 60));
        let days = parseInt(Math.floor(total_hours / 24));

        let seconds = parseInt(total_seconds % 60);
        let minutes = parseInt(total_minutes % 60);
        let hours = parseInt(total_hours % 24);
        return `${days}: ${hours} : ${minutes} : ${seconds}`;
    };

   
    const { handleSubmit: handleMultipulUpload } = useForm();

    const date2 = new Date(`${product.date} ${product.time}`);



    const onSubmit = async (data) => {
       if(loggedInUser?.email){
        toast.dismiss();
        const userEmail = loggedInUser.email.split("@");
        const listingId = Math.floor(1000 + Math.random() * 9000) + "-" + userEmail[0];



        data.vandorName = loggedInUser.displayName;
        data.postImages = postImages || bid.postImages;
        data.email = loggedInUser.email;
        data.category = "bid" || bid.category;
        data.id = listingId;




        try {
            const loading = toast.loading("Please wait a moment...");

            // update listing context
            // jato filed nibo sub gola akhne dilei hobe
            const payload = {
                ...data,
                bid: count + Number(product.price),

                carid: product.id,
                productimg: product?.postImages && product?.postImages[0]?.url,
                commentsUser: currentUser?.photoURL,
                createdAt: serverTimestamp(),
            };
            // console.log('count data.....................', count);

            // upload
            const spacesRef = doc(db, data.category, data.id);

            // store user info on database
            await setDoc(spacesRef, payload, { merge: true });

            toast.dismiss(loading);
            setBid(BidData);
            toast.success("Your bid successfully ");
            navigate("/home");

            // <Navigate to="/listing"/>
        } catch (error) {
            toast.error(error.message);
        }

       }
       else{
       
        navigate("/login");
        {console.log('login user123456')}
        
        
       }
    };


    useEffect(() => {
        setTimeout(() => {
            setTime(time - 1000);
        }, 1000);
    }, [time]);

    useEffect(() => {
        const getProducts = () => {
            setProduct(allBlog?.find((obj) => obj.id === id));
        };
        if (allBlog?.length > 0) {
            getProducts();
        }
    }, [allBlog, id]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <p className="bg-[#f2f2f2] text-left font-bold p-3">Bid Information</p>
            <hr />

            <ul className="flex justify-between p-2">
                <li className="text-xs">Bid Status:</li>
                <li className="text-sm">YOU HAVEN'T BID</li>
            </ul>
            <hr className="m-1" />
            <ul className="flex justify-between p-2">
                <li className="text-xs">Recommended Bid:</li>
                <li className="text-sm">Login or Register to view</li>
            </ul>
            <hr className="m-1" />
            <ul className="flex justify-between p-2">
                <li className="text-xs">Current Bid:</li>
                <li className="text-sm">
                ৳ {bids?.filter((item) => item?.carid === product?.id)?.length !== 0
                ? Math.max(
                    ...bids
                      ?.filter((item) => item?.carid === product?.id)
                      ?.map((item) => item?.bid)
                  )
                : product?.price}

                
                </li>
            </ul>
            <hr className="m-1" />
            <ul className="flex justify-between p-2">
                <li className="text-xs">Time left: </li>
                <li> {getFormatedTime(time)}</li>
            </ul>
            <hr />

            <div className="bg-[#e6ecfd] m-3 rounded-md text-left ">
                <p className="ml-2">($100 EUR increment) </p>
                <p className="ml-2">Incremental Bid Guidelines</p>

                <div>
                    <div className="">
                        <div className="">
                            <form onSubmit={handleMultipulUpload(onSubmit)}>
                                <div className="grid grid-cols-7 ml-auto mr-auto justify-between p-2 ">

                                     <input
                                        onClick={() => setCount((count ) => count - 1000)}
                                        value="-"
                                        className=" cursor-pointer col-span-1 bg-red-500 text-white"
                                        type="button" 
                                       
                                    />
                                    <div className="col-span-3">
                                        <input
                                            value={count + Number(product.price)}
                                            className="w-full"
                                            name="bid"
                                            type="number"
                                            disabled
                                            
                                            required
                                        ></input>
                                    </div>
                                  
                                    <input
                                        onClick={() => setCount((count ) => count + 1000)}
                                        value="+"
                                        className="cursor-pointer bg-black text-white"
                                        type="button" 
                                        
                                    />
                                    {/* <button onClick={() => setCount((count ) => count + 1000)} className="bg-black text-white p-2 cursor-pointer">
                                        +
                                    </button> */}
                                    <input
                                        type="submit"
                                        className="col-span-2 ml-2 bg-[#0a2357]  cursor-pointer text-white font-medium py-1 px-4  tracking-wide mr-1 "
                                        value="BID"
                                     />
                                </div>
                            </form>
                            {/* <button
                      onClick={() => setCount(count + 500)}
                      className="font-bold text-2xl bg-blue-500 text-white"
                    >
                      +
                    </button> */}
                        </div>
                    </div>
                </div>
                <p className="ml-4">Final price Estimator</p>
            </div>
            <p className="text-xs">
                Lorem ipsum dolor sit amet consectetur adipisicing{" "}
            </p>
            <br />
        </div>
    );
}

export default BlogBids;
