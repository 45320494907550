import { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import React from "react";
import ReactPaginate from "react-paginate";

import { FaGasPump } from "react-icons/fa";
import { TbGitCompare, TbManualGearbox } from "react-icons/tb";
import { TbEngine } from "react-icons/tb";

import { Link, useNavigate } from "react-router-dom";
import { BsFillCameraFill } from "react-icons/bs";
import { MdFavorite } from "react-icons/md";
import { useDispatch } from "react-redux";
import { add } from "../Redux/Store/cartSlice";
import toast, { Toaster } from "react-hot-toast";

const ListingProductView = ({ allCar }) => {
  const dispatch = useDispatch();
  // const { id, url, title, name, Review, url1, make } = allCar;
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };
  const navigate = useNavigate();

  const handleAdd = (item) => {
    // alert('add')
    // toast('Here is your toast.')
    toast.success(" Add Compare Successfully ");
    // add ta asse react redux
    dispatch(add(item));
  };

  return (
    <div>
      <div className="rounded-2xl shadow  mt-6 ml-auto mr-auto  container text-left border border-slate-300  singel-card ">
        {" "}
        <div class="overflow-hidden  relative group">
          <div class="rounded-xl z-50 opacity-0 group-hover:opacity-100 transition duration-300   absolute  to-transparent bg-gradient-to-t inset-x-0 bottom-2 pt-30 text-white flex justify-between ml-4 mr-4 mb-2" >
            <div className="flex">
              <span className="  bg-[#61dafb] rounded-full p-1">
                <BsFillCameraFill></BsFillCameraFill>
                </span>
              <span className="ml-1"> 2 </span>
            </div>
            <div className="flex">
             <span className="  bg-[#61dafb] rounded-full p-1">  <MdFavorite></MdFavorite> </span> 

              <span onClick={() => handleAdd(allCar)} className=" ml-3 bg-[#61dafb] rounded-full p-1">
                <TbGitCompare></TbGitCompare>
              </span>
            </div>
          </div>

          <p className=" ml-3  mt-4   bg-[#61dafb] rounded-xl text-white absolute text-right px-2 flex text-xs">
          <span>{(allCar?.dprice) || <span className="hidden"></span>}</span> <span className="ml-1"></span>
        </p>
          <img
            onClick={() => navigate(`/product/${allCar.id}`)}
            className=" ml-auto md:h-52 rounded-2xl mr-auto w-full p-2 card-img  aspect-square  ease-in-out cursor-pointer "
            src={allCar?.postImages[0]?.url}
            alt=""
          />
        </div>


        <p className="flex  ml-2 -mt-1 text-white">
        <p className="bg-[#61dafb]  flex rounded-full">
          <img
            className="w-4 h-4 bg-white rounded-full"
            src="https://i.ibb.co/PFxdM8J/logo.png"
            alt=""
          />
          <span className="  px-2 text-xs">DAMIGARI CERTIFIED </span>
        </p>{" "}
      </p>
        <p className="mt-1 ml-3 ">
          {" "}
          <span className=""> ৳</span>
          {allCar.price}
        </p>
        <p className="ml-3">{allCar.name}</p>
        <p className="flex ml-3">
          <ReactStars
            count={allCar.Review}
            onChange={ratingChanged}
            size={20}
            activeColor="#ffd700"
          />{" "}
          <span className="mt-1 ml-1">({allCar.Review} Review)</span>
        </p>
        {/* button */}
        <hr className="ml-3 mr-3" />
        <ul className="flex ml-auto mr-auto mt-2 mb-2 ml-3">
          <TbEngine className="mt-1"></TbEngine>
          <p className="ml-4"> 260</p>
          <p className="ml-4 mt-1">
            {" "}
            <FaGasPump> </FaGasPump>
          </p>
          <p className="ml-4">partol.</p>
          <TbManualGearbox className="ml-4 mt-1"></TbManualGearbox>
          <p className="ml-4"> {allCar?.Transmission}</p>
        </ul>
        <Toaster />
      </div>

      {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      /> */}
    </div>
  );
};

export default ListingProductView;
