// Bids.Context.js

import React, { createContext, useState } from "react";

export const  BidsContext = createContext();

export const BidData = {
  id: "",
  href: "",
  category: "bids",
  createdAt: "",
  featured: "No",
  status: "Draft",
  postImages: [],
  userName: "",
  email: "",
  phoneNumber: "",
  comments:"",
  carid:'',
  commentsUser:'',
  productimg:'',
};

export default function  BidsContextProvider({ children }) {
  const [bid, setBid] = useState(BidData);

  const listingDataBid = { bid, setBid };

  return (
    < BidsContext.Provider value={listingDataBid}>
      {children}
    </ BidsContext.Provider>
  );
}
