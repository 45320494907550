import React, { useState } from "react";
import ProductChakOutTopModal from './ProductChakOutTopModal';

const ProductChakOutTop = ({ product }) => {
  // const {id}= item;
  const [isSticky, setSticky] = useState(false);
  const [showModalProduct, setShowModalProduct] = useState(false);
  window.addEventListener("scroll", () => {
    if (window.scrollY > 170) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  });

  return (
    <header
      className={`top-0 w-full fixed  z-40  ${
        !isSticky ? " hidden" : "scrolled"
      }`}
    >
      <div>
      <div className="hidden sm:block   max-w-[1536px] ml-auto mr-auto">
        <div className="grid grid-cols-2 bg-white  justify-between  ">
          <div className=" ">
            <img className="ml-10 w-28 h-20 p-1 rounded-sm"
            //  src={product.url} 
            src={product?.postImages && product?.postImages[0]?.url}
             
             alt="" />
            
          </div>
          <div className="flex justify-end mt-3  text-gray-500 text-left">
            <div>
              <p>{product.name}</p>
              <p> <span className="text-2xl">৳ </span>  {product.price}</p>
            </div>
            <div className="md:ml-4">
              <button onClick={() => setShowModalProduct(true)} class="  mr-10 m-2 bg-[#61dafb] hover:bg-blue-700 text-white font-bold py-2 px-6 border  rounded">
                Check Availability
              </button>
            </div>
          </div>
        </div>
        
      </div>
      {showModalProduct && (
        <ProductChakOutTopModal
          open={showModalProduct}
          product={product}
          setOpen={setShowModalProduct}
        ></ProductChakOutTopModal>
      )}
      
      </div>
      
    </header>
  );
};

export default ProductChakOutTop;
