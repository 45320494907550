import React, { useContext, useEffect, useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BookingContext } from "../../context/AuthProvider/BookingContext";
import { useParams } from "react-router-dom";
import { ImCancelCircle } from "react-icons/im";
import DrimesonalModal from "./DrimesonalModal";

const CarModal = ({ open, setOpen, products }) => {
  const [product, setProduct] = useState([]);
  const { allCar } = useContext(BookingContext);
  const cancelButtonRef = useRef(null);
  const [imgId, setImgId] = useState(null);
  const { id } = useParams();
  console.log("product", product);
  console.log("imgId", imgId);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const getProducts = () => {
      setProduct(allCar?.find((obj) => obj.id === id));
    };
    if (allCar?.length > 0) {
      getProducts();
    }
  }, [allCar, id]);
  console.log("Product", product);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-100 "
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black  bg-opacity-80 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-100 overflow-y-auto -mt-6 max-w-[1536px] ml-auto mr-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg ml-auto mr-auto px-2 pt-2 pb-2 text-left shadow-xl transition-all sm:my-8 ">
                <div className="">
                  <div>
                    <div className=" md:w-2/4 h-full rounded-md border-t-4 border-t-[#61dafb]  border-2 border-white border-gray ml-auto mr-auto z-100 bg-white ">
                      <div className="flex ml-auto mr-auto md:p-3 ">
                        <p style={{margin: "0 auto"}} className="flex bg-gray-300  px-6 rounded-full ">
                          <button className=" text-white p-1 px-8 rounded-full m-1 bg-[#61dafb] ">
                            {" "}
                            Exterior
                          </button>

                          <button
                            onClick={() => {
                              setShowModal(true);
                              // setOpen(false)
                              if (setShowModal === true) {
                                setOpen(false);
                              }
                            }}
                            className=" text-white p-1 ml-4 px-10 rounded-full m-1 hover:bg-[#61dafb] "
                          >
                            360º
                          </button>
                        </p>
                        <p
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                          className="mr-0 mt-3 cursor-pointer"
                        >
                          <ImCancelCircle></ImCancelCircle>
                        </p>
                      </div>
                      <img
                        className="w-full "
                        src={products && (products[imgId] || products[0])?.url}
                        alt=""
                      />
                      <hr />
                      <div className="flex ">
                        {product?.postImages &&
                          product?.postImages?.map((img, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                setImgId(index);
                              }}
                              className="flex "
                            >
                              <img
                                className=" w-full p-1"
                                src={img?.url}
                                alt=""
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {showModal && (
                    <DrimesonalModal
                      open={showModal}
                      setOpenModal={setShowModal}
                    ></DrimesonalModal>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CarModal;

// import React from "react";
// import { Fragment, useRef } from "react";
// import { Dialog, Transition } from "@headlessui/react";

// const CarModal = ({ open, setOpen, product, imgId }) => {
//   const cancelButtonRef = useRef(null);
//   console.log('product',product)
//   console.log('imgId',imgId)

//   return (
//     <Transition.Root show={open} as={Fragment}>
//       <Dialog
//         as="div"
//         className="relative z-10"
//         initialFocus={cancelButtonRef}
//         onClose={setOpen}
//       >
//         <Transition.Child
//           as={Fragment}
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity " />
//         </Transition.Child>

//         <div className="fixed inset-0 z-10 overflow-y-auto">
//           <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//             <Transition.Child
//               as={Fragment}
//               enter="ease-out duration-300"
//               enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//               enterTo="opacity-100 translate-y-0 sm:scale-100"
//               leave="ease-in duration-200"
//               leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//               leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//             >
//               <Dialog.Panel className="relative transform overflow-hidden rounded-lg ml-auto mr-auto px-2 pt-2 pb-2 text-left shadow-xl transition-all sm:my-8 ">
//                 <div className=" w-3/4 ">
//                   <img className=""  src={product && product[imgId]?.url} alt="" />

//                 </div>
//                 {/* <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
//                   <button
//                     type="button"
//                     className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
//                     onClick={() => setOpen(false)}
//                   >
//                     Deactivate
//                   </button>
//                   <button
//                     type="button"
//                     className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
//                     onClick={() => setOpen(false)}
//                     ref={cancelButtonRef}
//                   >
//                     Cancel
//                   </button>
//                 </div> */}
//               </Dialog.Panel>
//             </Transition.Child>
//           </div>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   );
// };

// export default CarModal;
