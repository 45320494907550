import React, { createContext, useState } from "react";

export const ListingContext = createContext();

export const listingDefaultData = {
  id: "",
  href: "",
  category: "car",
  createdAt: "",
  featured: "No",
  status: "Draft",
  reviews: [],
  listingDate: "",
  postImages: [],
  vandorName: "",
  email: "",
  make: "",
  Condition: "",
  name: "",
  model: "",
  color: "",
  Review: "",
  Type: "",
  Transmission: "",
  Yea: "",
  price:"",
  description: "",
  address: "",
  date: "",
  time:"",
  trim:"",
  engine:"",
  vin:"",
  door:"",
  gas:"",
  dprice:"",
};

export default function ListingContextProvider({ children }) {
  const [listing, setListing] = useState(listingDefaultData);

  const listingData = { listing, setListing };

  return (
    <ListingContext.Provider value={listingData}>
      {children}
    </ListingContext.Provider>
  );
}
