import React, { createContext, useState } from "react";

export const CommentsContext = createContext();

export const CommnetsData = {
  id: "",
  href: "",
  category: "commnets",
  createdAt: "",
  featured: "No",
  status: "Draft",
  postImages: [],
  userName: "",
  email: "",
  phoneNumber: "",
  comments:"",
  carid:'',
  commentsUser:''
};

export default function CommentsContextProvider({ children }) {
  const [comment, setComment] = useState(CommnetsData);

  const listingDataCommet = { comment, setComment };

  return (
    <CommentsContext.Provider value={listingDataCommet}>
      {children}
    </CommentsContext.Provider>
  );
}
