import React, { useContext, useEffect, useState } from "react";
import Footer from "../home/Footer";
import Header from "../shared/Header";

import ListingProduct from "./ListingProduct";

import Loding from "./Loding";

import ListingEmty from "./ListingEmty";

import { BookingContext } from "./../../context/AuthProvider/BookingContext";

const Listing = () => {
  const { userCar, allUser, allCar } = useContext(BookingContext);
  const [posts, setPosts] = useState([]);
  const [user, setUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(100);

  const [make, setMake] = useState("");
  const [condition, setCondition] = useState("");
  const [model, setModel] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    setUser(user);
  }, [user]);

  // const {} = useContext()

  // console.log("All Cars", allCar);

  // useEffect(()=>{
  //     axios.get('https://run.mocky.io/v3/957a2b20-4827-4340-869b-c1a2d1bd2c2d')
  //     .then(res=>{
  //         setPosts(res.data)
  //     })
  // },[])
  // new state neyer jonn abar useEffect nite hoi

  useEffect(() => {
    setPosts(allCar);
  }, [allCar]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //   current posts
  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPost = posts.slice(indexOfFirstPost, indexOfLastPost);

  console.log("condition", condition);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const filterItems = () => {
  //   console.log("clicked-------------->>>>");

  //   const updateItems = allCar?.filter((curElem) => {
  //     return (
  //       curElem.Condition === condition &&
  //       curElem.make === make &&
  //       curElem.model === model &&
  //       curElem.Type === type
  //     );
  //   });
  //   // console.log("🚀 ~ file: HomeCard.js ~ line 13 ~ filterItem ~ updateItems", updateItems)
  //   setPosts(updateItems);
  // };

  useEffect(() => {
    setPosts(
      allCar?.filter((curElem) => {
        return (
          curElem.Condition ===
            (condition === "" ? curElem.Condition : condition) &&
          curElem.make === (make === "" ? curElem.make : make) &&
          curElem.model === (model === "" ? curElem.model : model) &&
          curElem.Type === (type === "" ? curElem.Type : type)
        );
      })
    );
  }, [condition, allCar, make, model, type]);

  // fILTER 2 NUMBER MAKE CARE MANE

  // const filterItemsTow = (cardItem) => {
  //   console.log('clicked-------------->>>>')

  //   const updateItems = item?.filter((curElem) => {
  //     return curElem.make === cardItem ;
  //   });
  // console.log("🚀 ~ file: HomeCard.js ~ line 13 ~ filterItem ~ updateItems", updateItems)
  //   setPosts(updateItems)
  // };

  // FILTER 3 MODELS Models

  // const filterItemsModes= (cardItem) => {
  //   console.log('clicked-------------->>>>')

  //   const updateItems = item?.filter((curElem) => {
  //     return curElem.model === cardItem ;
  //   });
  //   // console.log("🚀 ~ file: HomeCard.js ~ line 13 ~ filterItem ~ updateItems", updateItems)
  //   setPosts(updateItems)
  // };

  // FILTER NUMBER 4 CAR TYPES

  // const filterItemsType =(cardItem) => {
  //   console.log('clicked-------------->>>>')

  //   const updateItems = item?.filter((curElem) => {
  //     return curElem.Type === cardItem ;
  //   });
  //   // console.log("🚀 ~ file: HomeCard.js ~ line 13 ~ filterItem ~ updateItems", updateItems)
  //   setPosts(updateItems)
  // };

  return (
    <div>
      <Header></Header>
      {allCar.length === 0 ? (
        <Loding></Loding>
      ) : (
        <div className="">
          <br />
          <br />

          <br />

          <div className=" mt-5   max-w-[1536px] ml-auto mr-auto">
            {/* serch section  */}
            <div className="z-20  bg-[#61dafb] shadow p-2 ml-auto mr-auto  gap-1 overflow-hidden">
              <br />
              <div className="   grid md:grid-cols-4 ">
                <div className=" m-1">
                  <div className="relative w-full lg:max-w-sm">
                    <select
                      className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none  focus:border-indigo-600"
                      onChange={(e) => {
                        setCondition(e.target.value);
                      }}
                    >
                      <option value="" >Condition </option>
                      <option value="New">New</option>
                      <option value="Used">Used</option>
                    </select>
                  </div>
                </div>

                {/* 2 */}
                <div className=" m-1">
                  <div className="relative w-full lg:max-w-sm">
                    <select
                      className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none  focus:border-indigo-600"
                      onChange={(e) => {
                        setMake(e.target.value);
                      }}
                    >
                      <option value="">Select Makes </option>
                      <option value="Audi">Audi</option>
                      <option value="BMW">BMW</option>
                      <option value="Chevrolet">Chevrolet</option>
                      <option value="Ford">Ford</option>
                      <option value="Kia">kia</option>
                    </select>
                  </div>
                </div>

                {/* 3 number */}
                <div className="col-span-2  gap-2 ">
                  <div className="grid md:grid-cols-3 grid-cols-2  ">
                    <div className=" m-1 ">
                      <select
                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none  focus:border-indigo-600 "
                        onChange={(e) => {
                          setModel(e.target.value);
                        }}
                      >
                        <option value="">Select Models </option>
                        <option value="911">911</option>
                        <option value="A4">A4</option>

                        <option value="A6">A6</option>
                        <option value="A8">A8</option>
                        <option value="Camry">Camry</option>
                      </select>
                    </div>
                    <div className="m-1">
                      <select
                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none  focus:border-indigo-600 "
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      >
                        <option value="">Select Types </option>
                        <option value="Compact">Compact</option>

                        <option value="Sedan">Sedan</option>

                        <option value="SUV">SUV</option>
                      </select>
                    </div>

                    <div className="m-1 md:col-span-1 col-span-2 ">
                      <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"></div>
                      <input
                        className="placeholder-white bg-[#4d6391]  hover:bg-[#4d6394] w-full p-2.5 text-white  border rounded-md shadow-sm outline-none  focus:border-indigo-600"
                        placeholder="Search"
                        type="search"
                      >
                        {/* <BiSearch></BiSearch> */}
                      </input>
                    </div>
                  </div>
                </div>

                <br />
              </div>
            </div>
            <br />
          </div>
          {/* <button onClick={() => filterItems("New")}>new</button>
           <button onClick={() => filterItems("Used")}>used</button> */}

          {/* <ListingSearch ></ListingSearch> */}
          {posts?.length === 0 ? (
            <ListingEmty></ListingEmty>
          ) : (
            <ListingProduct allCar={currentPost} post={posts}></ListingProduct>
          )}
          {/* <ListingPagination
            postPerPage={postPerPage}
            totalPosts={posts.length}
            paginate={paginate}
          ></ListingPagination> */}
        </div>
      )}

      <Footer></Footer>

      {/* <AllUserCar></AllUserCar> */}
    </div>
  );
};

export default Listing;
