import { doc, setDoc, getFirestore, deleteDoc } from "firebase/firestore";
import React, { useContext } from "react";
import { AiOutlineDelete, AiTwotoneDelete, AiTwotoneEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { BookingContext } from "../../../../context/AuthProvider/BookingContext";
import { ListingContext } from "../../../../context/ListingContext";
import { db } from "../../../../Fribase/firebase.init";


const MyLstingAllUsers = () => {
  const {  allCar } = useContext(BookingContext);

  const db = getFirestore();


   // delete user
   const handleDeleteList = async (person) => {
    alert("are you sure !")
    await deleteDoc(doc(db, "car", person.id));
  };

  // delete item
  const handleDelete = async (status, id) => {
    const scheduleData = {
      status,
    };
    // <MyListingAleart> </MyListingAleart>
    alert('are you sure')
    const docRef = doc(db, "car", id);
    deleteDoc(docRef)
      .then(() => {
        console.log("Entire Document has been deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleStatusChang = async (status, id) => {
    const scheduleData = {
      status,
    };

    // update the schedule status on database
    const scheduleRef = doc(db, "car", id);
    await setDoc(scheduleRef, scheduleData, { merge: true });

    // toast success message

    console.log("id chak korbo 2022 23 tarik", id);
  };
  // const deleteItem = (deleteItem,id) =>{
  //   const docRef = doc (db , "car", id);
  //   docRef.get().then(docSnap=>{
  //    const result = docSnap.data().car.filter(car => car != deleteItem)
  //    docRef.update({
  //     car:result
  //    })
  //   })
  //   console.log("delete")
  // }

  return (
    <div>
      {/* {item.length} */}
      <div className="px-4 sm:px-6 lg:px-8 mt-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Product List
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A a list of all the users in your account including their name,
              title, email and role.
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add user
            </button>
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 p-2">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 w-full">
                  <thead className="bg-gray-50 ml-auto mr-auto">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Car
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ml-auto mr-auto justify-center "
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Role
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-left ">
                    {allCar?.map((person) => (
                      <tr key={person.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-20 w-20 flex-shrink-0">
                              <img
                                className="h-20 w-20 rounded"
                                // src={person?.postImages[0]?.url}
                                src={person?.postImages && person?.postImages[0]?.url}
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {person.name}
                              </div>
                              <div className="text-gray-500  hidden sm:block">
                                {person.Transmission}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900  hidden sm:block">
                            {person.title}
                          </div>
                          <div className="text-gray-500  hidden sm:block">
                            {person.department}
                          </div>
                        </td>
                        <td className="whitespace-nowrap mt-8 px-3 py-4 text-sm text-gray-500 hidden sm:block">
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            {person.status}
                          </span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 -mt-14 text-sm text-gray-500  hidden sm:block">
                          <div className="flex items-end justify-end space-x-3">
                            <select
                              className={`block w-48 font-semibold py-2 px-4 border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:ring-primary-500 tracking-wide  transition-colors duration-200  focus:focus:border-primary-500 ${
                                person?.status === "Active"
                                  ? "text-[#67ec4d]"
                                  : person?.status === "Draft"
                                  ? "text-[#4e4545]"
                                  : person?.status === "Hide"
                              }`}
                              name="listing"
                              defaultValue={person?.status}
                              onChange={(e) =>
                                handleStatusChang(e.target.value, person.id)
                              }
                            >
                              <option className="text-[#67ec4d]" value="Active">
                                Active
                              </option>
                              <option className="text-[#4e4545]" value="Draft">
                                Draft
                              </option>
                              <option className="text-[#e04747]" value="Hide">
                                Hide
                              </option>
                              <option className="text-[#e04747]" value="Hide">
                                Delete
                              </option>
                              <p></p>

                              {/* <option value="Updated">Updated</option> */}
                            </select>
                          </div>
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 ">
                          <Link
                            to=""
                            className="text-indigo-600 hover:text-indigo-900 flex gap-4  text-xl text-right "
                          >
                            <span>
                              {" "}
                              <Link to={`/dashboard/products/${person.id}`}>
                                <AiTwotoneEdit className="hover:text-green-600 mt-6"></AiTwotoneEdit>{" "}
                              </Link>
                            </span>{" "}
                            {/* delete button */}
                            {/* <span>
                              <AiOutlineDelete
                                className="hover:text-red-600"

                              
                              ></AiOutlineDelete>
                            </span> */}
                            <div className="flex items-end justify-end space-x-3">
                            <AiTwotoneDelete
                            // onClick={() =>handleDelete(person)}
                            onClick={() => handleDeleteList(person)}
                            className="text-2xl cursor-pointer"
                          ></AiTwotoneDelete>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLstingAllUsers;
