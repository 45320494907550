import React, { useContext } from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";

import { BookingContext } from "../../../../context/AuthProvider/BookingContext";

const CompareList = () => {
  const {  allCar,compare} = useContext(BookingContext);
  // const items = useSelector((state) => state.cart);
  // const dispatch = useDispatch();

  const handleRemove = (productId) => {
    // dispatch(remove(productId));
  };
  // const { id, url, title, name, Review, url1, make } = items;

  // More people...
  return (
    <div>
      {/* {item.length} */}
      <div className="px-4 sm:px-6 lg:px-8 mt-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl  text-gray-900 text-left font-bold">
              Compare List
            </h1>
            {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name,
              title, email and role.
            </p> */}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {/* <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              Add user
            </button> */}
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 p-2">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 w-full">
                  <thead className="bg-gray-50 ml-auto mr-auto">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Car
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ml-auto mr-auto justify-center "
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Role
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-left ">
                    {allCar
                      ?.filter((object1) =>
                      compare?.some(
                          (object2) => object1?.id === object2?.carId
                        )
                      )
                      ?.map((person) => (
                        <tr key={person.email}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              <div className="h-20 w-20 flex-shrink-0">
                                <img
                                  className="h-20 w-20 rounded"
                                  src={person?.postImages[0]?.url}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">
                                  {person.name}
                                </div>
                                <div className="text-gray-500  hidden sm:block">
                                  {person.Transmission}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900  hidden sm:block">
                              {person.name}
                            </div>
                            <div className="text-gray-500  hidden sm:block">
                              {person.department}
                            </div>
                          </td>
                         
                          <td className="whitespace-nowrap px-3 py-4 mt-6 text-sm text-gray-500  hidden sm:block">
                            {person.id}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 ">
                            <p
                              to="/"
                              className="text-indigo-600 hover:text-indigo-900 flex gap-4  text-xl text-right"
                            >
                              <span>
                                {" "}
                                <AiTwotoneEdit className="hover:text-green-600"></AiTwotoneEdit>{" "}
                              </span>{" "}
                              <span>
                                <AiOutlineDelete
                                  onClick={() => handleRemove(person.id)}
                                  className="hover:text-red-600"
                                ></AiOutlineDelete>
                              </span>{" "}
                            </p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareList;
