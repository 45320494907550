import React from "react";
import { AiFillDashboard } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import Footer from "../home/Footer";
import "./service.css";
import img from '../../imgs/location.png'
const Services = () => {
  return (
    <div className="mt-14  max-w-[1536px] ml-auto mr-auto">
      <div className="grid md:grid-cols-4  gap-4 bg-[#61dafb] text-left p-10">
        <div className="hidden sm:block"></div>
        <div className="ml-10 ">
          <h4 className="ml-6 mt-2 font-bold  b text-white">
            {" "}
            <span className="ml-4  mt-2"> Low Prices, No Haggling</span>{" "}
          </h4>
        </div>
        <div>
          <h4 className="ml-6 mt-2 font-bold  border-l-4 border-white text-white">
            {" "}
            <span className="ml-4  mt-2"> Low Prices, No Haggling</span>{" "}
          </h4>
        </div>
        <div>
          <h4 className="ml-6 mt-2 font-bold  border-l-4 border-white text-white">
            {" "}
            <span className="ml-4  mt-2"> Low Prices, No Haggling</span>{" "}
          </h4>
        </div>
      </div>

      {/* side ber */}
      <div className="bg-[#333333] ml-auto mr-auto text-left p-10">
        <div className="grid md:grid-cols-4  gap-4 mt-10">
          <div class="row-span-4 "></div>
          <div className="">
            <h4 className=" ml-4 text-[#61dafb] font-bold">
              {" "}
              <span className="border-l-4 mr-4 "></span> OPENING HOURS{" "}
              <span className="border-r-4 ml-4"></span>
            </h4>

            <br />
            {/* last 3 ta  */}
            <div>
              <div className="grid grid-cols-3 text-left ml-4">
                <img
                  className="w-24"
                  src="https://i.ibb.co/55wYZZ4/1288-beautiful-car.jpg"
                  alt=""
                />
                <div className=" md:col-span-2 ml-2 text-gray-200">
                  <h4 className="text-sm">MERCEDES-AMG GTS</h4>
                  <p className="flex text-xs text-gray-400">
                    <AiFillDashboard className="mr-1 mt-1 "></AiFillDashboard>{" "}
                    35,000 KM
                  </p>
                  <br />
                  <br className="m-4" />
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div>
            <div>
              <h4 className="text-white ml-4  text-[#61dafb] font-bold">
                {" "}
                <span className="border-l-4 mr-4"></span> OPENING HOURS{" "}
                <span className="border-r-4 ml-4"></span>
              </h4>
              <div className="ml-4 mr-10 mt-4 text-gray-300">
                <h4 className="flex text-xs">
                  <span className="mr-2 mt-1 text-xs">
                    {" "}
                    <BsTwitter></BsTwitter>{" "}
                  </span>
                  Duis scelerisque aliquet ante donec libero pede porttitor
                </h4>
                <p className="ml-6 text-xs text-white">20 minutes ago</p>
                <p className="ml-6 text-xs text-white">20 minutes ago</p>
              </div>
            </div>
            <br />
            <hr />
          </div>
          <div>
            <h4 className="text-white ml-4  text-[#61dafb] font-bold">
              {" "}
              <span className="border-l-4 mr-4"></span> OPENING HOURS{" "}
              <span className="border-r-4 ml-4"></span>
            </h4>
            <div className="ml-4 mr-10 mt-4 text-gray-300">
              <h4 className="text-xs">202 W 7th St, Suite 233 Los Angeles, California 90014 USA</h4>
              <p className="text-xs mt-1">Phone: 1-800- 624-5462</p>
              <p className="text-xs mt-1">FAX: 1-800- 624-5462</p>
              <p className="text-xs mt-1">Email: damigari@domain.com</p>


              



            </div>
          </div>
        </div>
        <div className=" grid md:grid-cols-4">
          <div></div>
          <div className="grid grid-cols-3 text-left ml-4 mt-2">
            <img
              className="w-24"
              src="https://i.ibb.co/55wYZZ4/1288-beautiful-car.jpg"
              alt=""
            />
            <div className="col-span-2 ml-2 text-gray-200">
              <h4 className="text-sm">MERCEDES-AMG GTS</h4>
              <p className="flex text-xs">
                <AiFillDashboard className="mr-1 mt-1"></AiFillDashboard> 35,000
                KM
              </p>
              <br />
              <br className="m-4" />
            </div>
          </div>

          <div className="ml-4 mr-10 text-gray-300">
            <h4 className="flex text-xs">
              <span className="mr-2 mt-1">
                {" "}
                <BsTwitter></BsTwitter>{" "}
              </span>
              Duis scelerisque aliquet ante donec libero pede porttitor
            </h4>
            <p className="ml-6 text-xs">20 minutes ago</p>
          </div>

          <div>
            <div className="ml-4 mr-10  text-gray-300">
              <h4 className="text-xs">Duis scelerisque aliquet</h4>
              <p className="text-xs">20 minutes ago</p>
              <img  className="mt-4" src={img} alt="" />
             
            </div>
          </div>
          <br />

          <br />
          <br />
        </div>
      </div>

      <br />
      <br />
      <br />
      {/* spesel box */}
      <div  class="top-card  hidden sm:block border-t-4 border-[#61dafb] m-4 bg-[#272727] w-72 text-left text-white md:ml-12">
        <div className="mt-10">
          <h4 className="font-bold ml-4 text-[#61dafb]">
            {" "}
            <span className="border-l-4 mr-4"></span> OPENING HOURS{" "}
            <span className="border-r-4 ml-4"></span>
          </h4>
          <h4 className="font-bold mt-3 ml-4 text-sm"> Sales Department</h4>
          <p className="ml-4 text-xs text-gray-300">Mon-Sat : 8:00am - 5:00pm</p>
          <p className="ml-4 text-xs text-gray-300">Sunday is closed</p>
          <br />
       
          <br />
          <h4 className="font-bold mt-3 ml-4 text-sm"> Sales Department</h4>
          <p className="ml-4 text-xs text-gray-300">Mon-Sat : 8:00am - 5:00pm</p>
          <p className="ml-4 text-xs text-gray-300">Sunday is closed</p>
          <br />
          <hr />
          <br />
          <h4 className=" font-bold ml-4 text-[#61dafb]">
            {" "}
            <span className="border-l-4 mr-4 "></span> OPENING HOURS{" "}
            <span className="border-r-4 ml-4"></span>
          </h4>
          <h4 className="font-bold mt-3 ml-4 text-sm"> Sales Department</h4>
          <p className="ml-4 text-xs mt-1">Mon-Sat : 8:00am - 5:00pm</p>
          <p className="ml-4 text-xs mb-16 text-gray-300 mt-1">
            Vestibulum varius od lio eget conseq uat blandit, lorem auglue comm
            lodo nisl non ultricies lectus nibh mas lsa Duis scelerisque
            aliquet. Ante donec libero pede porttitor dacu msan esct venenatis
            quis.
          </p>
          <br />
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer></Footer>
    </div>
  );
};

export default Services;
