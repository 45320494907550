import AuthContext from "./AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";

const AuthProvider = (props) => {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [profile, setProfile] = useState([]);
  const [userListing, setUserListing] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    setEmail(localStorage.getItem("user"));
  }, [token, email]);
  
  useEffect(() => {
    if (email?.length > 0) {
      console.log("eimaol", email);
      axios
        .get(`http://localhost:5000/api/profile?email=${email}`)
        .then((res) => {
          setProfile(res.data);
          console.log("email chak data", res.data);
        });
    }
  }, [email, setProfile]);

  const values = { profile, setProfile, userListing, setUserListing ,token,setToken,email,setEmail};

  return (
    <AuthContext.Provider value={values}>{props.children}</AuthContext.Provider>
  );
};
export default AuthProvider;
