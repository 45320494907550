import logo from "./logo.svg";
import "./App.css";
import Header from "./componet/shared/Header";
import Home from "./componet/home/Home";
import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import ProductDetials from "./componet/ProductDetials/ProductDetials";
import { useState } from "react";
import { useEffect } from "react";
import Dashboard from "./componet/home/Dashboard/Dashboard";
import Login from "./login/Login";
import Registration from "./login/Registration";
import Listing from "./componet/Listing/Listing";
import About from "./componet/About/About";
import Contact from "./componet/Contact/Contact";

import DHome from "./componet/home/Dashboard/DHome";
import Profile from "./componet/home/Dashboard/Profile/Profile";
import Post from "./componet/home/Dashboard/ProductPost/Post";
import UserList from "./componet/home/Dashboard/MyListing/UserList";
import ProductList from "./componet/home/Dashboard/MyListing/ProductList";
import Favorite from "./componet/home/Dashboard/Favorite/Favorite";
import Loding from "./componet/Listing/Loding";
import EditProduts from "./componet/home/Dashboard/MyListing/EditProduts";

import Massages from "./componet/home/Dashboard/Message/Massages";
import { Provider } from "react-redux";
import store from "./componet/Redux/Store/store";
import CompareList from "./componet/home/Dashboard/CompareList/CompareList";
import AuthProvider from "./context/AuthProvider/AuthProvider";
import PrivetRoute from "./PrivetRoute/PrivetRoute";
import AuthContextProvider from "./Auth/AuthenticationContext";
import LoginUser from "./Auth/LoginUser";
import RegistrationUser from "./Auth/RegistrationUser";
import { Toaster } from "react-hot-toast";
import PublicRoute from "./PrivetRoute/PublicRoute";
import UserProfile from "./componet/home/Dashboard/Profile/UserProfile";
import ListingContextProvider from "./context/ListingContext";
import BookingContextProvider from "./context/AuthProvider/BookingContext";
import MyLstingAllUsers from "./componet/home/Dashboard/MyListing/MyLstingAllUsers";
import ChokOutFormContextProvider from './context/ChokOutFormContext';
import Chakout from "./componet/home/Dashboard/Chakout";
import CommentsContextProvider from './context/CommentsContext';
import FavoriteContextProvider from "./context/FavoriteContext";
import BidsDetails from "./componet/ProductDetials/BidsDetails";
import CommentsRepllyContextProvider from "./context/CommentsRepllyContext";
import BidsContextProvider from "./context/BidsContext";
import BlogHome from "./componet/Blog/BlogHome";
import Dblog from "./componet/home/Dashboard/Dblog";
import BlogContextProvider from "./context/BlogContext";
import BlogDetials from "./componet/BlogDetials/BlogDetials";
import BlogBidDetails from "./componet/BlogDetials/BlogBidDetails";
import MainCarShow from "./componet/MainCarShowPage/MainCarShow";
import CarDetailsLogo from "./componet/home/CarLogSection/CarDetails Logo/CarDetailsLogo";

function App() {
  const user = localStorage.getItem("token");

  const [item, setItem] = useState([]);

  const [loding, setLodin] = useState(false);
  useEffect(() => {
    // fetch('https://run.mocky.io/v3/30e41d0f-7a95-474e-ab78-8657fad08f65')

    fetch("https://run.mocky.io/v3/eb88a6da-3c0a-406a-8150-95378e50c0f9")
      .then((res) => res.json())
      .then((data) => setItem(data));
    setLodin(true);
  }, []);

  // const handleFilter = (args) => {
  //   // apply filter
  // }

  return (
    <div className="App">
      {/* {item.length=== 0 ?(

            <Loding></Loding>):( */}
      <AuthContextProvider>
        <ListingContextProvider>
          <BookingContextProvider>
            <ChokOutFormContextProvider>
              <CommentsContextProvider>
                <FavoriteContextProvider>
                  <CommentsRepllyContextProvider>
                    <BidsContextProvider>
                      <BlogContextProvider>
            <AuthProvider>
              <Provider store={store}>
                <BrowserRouter>
                  {/* <Header></Header> */}
                  {/* handleFilter={handleFilter} */}

                  <Routes>
                    <Route path="/" element={<Home item={item}></Home>}></Route>
                    <Route
                      path="/home"
                      element={<Home item={item}></Home>}
                    ></Route>
                    <Route
                      path="/listing"
                      element={<Listing item={item}></Listing>}
                    ></Route>
                    <Route
                      path="/aston-martin"
                      element={<MainCarShow item={item}></MainCarShow>}
                    ></Route>

                    <Route
                      path="/brand-details"
                      element={<CarDetailsLogo item={item}></CarDetailsLogo>}
                    ></Route>




                    <Route
                      path="/blog"
                      element={<BlogHome></BlogHome>}
                    ></Route>
                    {/* dashbord  routes */}
                    <Route
                      path="/dashboard"
                      element={
                        <PrivetRoute>
                          <Dashboard item={item}></Dashboard>
                        </PrivetRoute>
                      }
                    >
                      <Route index element={<DHome></DHome>}></Route>
                      <Route
                        path="profile"
                        element={<Profile></Profile>}
                      ></Route>
                      <Route
                        path="userProfile"
                        element={<UserProfile></UserProfile>}
                      ></Route>
                      <Route path="post" element={<Post></Post>}></Route>
                      <Route path="checkout" element={<Chakout></Chakout>}></Route>
                      <Route
                        path="users"
                        element={<UserList></UserList>}
                      ></Route>


                      <Route
                        path="blogs"
                        element={<Dblog></Dblog>}
                      ></Route>
                      <Route
                        path="product"
                        element={<ProductList item={item}></ProductList>}
                      ></Route>
                      <Route
                        path="allListing"
                        element={<MyLstingAllUsers></MyLstingAllUsers> }
                      ></Route>

                      <Route
                        path=":products/:id"
                        element={<EditProduts item={item}></EditProduts>}
                      ></Route>
                      <Route
                        path="favorite"
                        element={<Favorite></Favorite>}
                      ></Route>
                      <Route
                        path="compareList"
                        element={<CompareList item={item}></CompareList>}
                      ></Route>
                      <Route
                        path="message"
                        element={<Massages></Massages>}
                      ></Route>
                    </Route>

                    {/* <Route path="/products/:id" element={<EditProduts item={item}></EditProduts>}></Route> */}

                    <Route path="/about" element={<About></About>}></Route>
                    <Route
                      path="/contact"
                      element={<Contact></Contact>}
                    ></Route>
                    <Route
                      path="/product/:id"
                      element={<ProductDetials item={item}></ProductDetials>}
                    ></Route>
                    <Route
                      path="/blogDetials/:id"
                      element={<BlogDetials item={item}></BlogDetials>}
                    ></Route>
                    <Route
                      path="/bids/:id"
                      element={<BidsDetails></BidsDetails>}
                    ></Route>

                    <Route
                      path="/blogbid/:id"
                      element={<BlogBidDetails></BlogBidDetails>}
                    ></Route>


                    
                    {/* <Route path="/login" element={<Login></Login>}></Route> */}
                    <Route
                      path="/login"
                      element={
                        <PublicRoute>
                          {" "}
                          <LoginUser></LoginUser>
                        </PublicRoute>
                      }
                    ></Route>
                    <Route
                      path="/register"
                      element={
                        <PublicRoute>
                          <RegistrationUser></RegistrationUser>
                        </PublicRoute>
                      }
                      // element={<Registration></Registration>}
                    ></Route>
                  </Routes>
                </BrowserRouter>
              </Provider>
            </AuthProvider>
            </BlogContextProvider>
            </BidsContextProvider>
            </CommentsRepllyContextProvider>
            </FavoriteContextProvider> 
            </CommentsContextProvider> 
            </ChokOutFormContextProvider>
          </BookingContextProvider>
        </ListingContextProvider>
      </AuthContextProvider>

      {/* )} */}

      {/* <h1 className="text-3xl font-bold underline">
      Hello world!
    </h1> */}

      {/* Toast Notification */}
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
      />
    </div>
  );
}

export default App;
