import React from "react";
import { useState } from "react";
import { useContext } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Auth/AuthenticationContext";
import { BlogContext, blogDefaultData } from './../../../context/BlogContext';
import { getBase64URL, resizeImg } from "../../../utils/imageFn";
import toast from "react-hot-toast";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../../Fribase/firebase.init";
import { RiAuctionFill } from "react-icons/ri";
import { TbCurrencyTaka } from "react-icons/tb";
import Listing from "../../Listing/Listing";



const Dblog = () => {
    const { blog, setBlog } = useContext(BlogContext);
    const [postImages, setPostImages] = useState([]);
    const { logout, loggedInUser } = useAuth();
    const [dragEnter, setDragEnter] = useState(false);
    const [showPopover, setShowPopover] = useState(false);
    const [coverDragEnter, setCoverDragEnter] = useState(false);
  
    const [openTimeLimitForm, setOpenFormInput] = useState(false);
    const [openDiscountOpen, setOpenFormInputDiscount] = useState(false);
    const navigate = useNavigate();
    const {
      register: registerPost,
      handleSubmit: handleMultipulUpload,
      formState: { errors },
    } = useForm();
  
    const uploadImage = (img) => {
      let body = new FormData();
      body.set("key", "9b06b95c493a116f58d0e106543c41be");
      body.append("image", img);
      console.log("body", body);
  
      return axios({
        method: "post",
        url: "https://api.imgbb.com/1/upload",
        data: body,
      });
    };
  
    // upload multiple images
    const onFiles = async (files) => {
      // console.log(await uploadImage(files[0]))
  
      for (let file of files) {
        const loading = toast.loading("Photos uploading...");
        try {
          const base64URL = await getBase64URL(file);
  
          // image resize
          const blob = await resizeImg(base64URL, 1600, false);
  
          let fileName =
            Math.floor(1000 + Math.random() * 9000) + "-" + file.name;
  
          // create image file
          const downloadURL = new File([blob], fileName, {
            type: blob.type,
          });
  
          // upload images to imgBB
  
          const imageData = new FormData();
          imageData.set("key", "9b06b95c493a116f58d0e106543c41be");
          imageData.append("image", downloadURL);
  
          const res = await axios({
            method: "post",
            url: "https://api.imgbb.com/1/upload",
            data: imageData,
          });
          console.log("response", res);
          const imageId = res.data.data.id;
          const imageURL = res.data.data.url;
          const displayURL = res.data.data.display_url;
  
          // update state
          setPostImages((postImages) => [
            ...postImages,
            { id: imageId, url: imageURL, thumbnail: displayURL },
          ]);
  
          toast.dismiss(loading);
        } catch (err) {
          toast.dismiss(loading);
          toast.error(err.message);
          console.log("imgBB", err);
        }
      }
    };
  
    const handleRemoveImage = async (image, id) => {
      // Delete the file
      const newImages = postImages.filter((image, index) => index !== id);
      setPostImages(newImages);
  
      // get blog cover image
      const spacesRef = doc(db, blog.category, blog.id);
      const docSnap = await getDoc(spacesRef);
  
      if (docSnap.exists()) {
        // remove image from gallery
        const newGalleryImages = docSnap
          .data()
          .gallery.filter((galleryImage) => galleryImage.id !== image.id);
  
        const payload = {
          spacesImages: newImages,
          gallery: newGalleryImages,
        };
        // store user info on database
        await setDoc(spacesRef, payload, { merge: true });
      }
    };
  
    const handleFiles = (e) => {
      onFiles(e.target.files);
    };
  
    const handleDragEnter = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setDragEnter(true);
    };
    const handleDragOver = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setDragEnter(true);
    };
    const handleDragLeave = (e) => {
      e.stopPropagation();
      e.preventDefault();
      setDragEnter(false);
    };
    const handleDrop = (e) => {
      e.stopPropagation();
      e.preventDefault();
      onFiles(e.dataTransfer.files);
    };
  
    const onSubmit = async (data) => {
      toast.dismiss();
      const userEmail = loggedInUser.email.split("@");
      const listingId =
        Math.floor(1000 + Math.random() * 9000) + "-" + userEmail[0];
  
      console.log("data", data);
      // data.email = loggedInUser.email;
      // data.eventName = data.eventName || loggedInUser.eventName;
      // data.photoURL = fileUrl || loggedInUser.photoURL;
      data.vandorName = loggedInUser.displayName;
      data.postImages = postImages || blog.postImages;
      data.email = loggedInUser.email;
      data.category = "blog1" || blog.category;
      data.id = listingId;
  
      if (data.postImages.length < 6) {
        toast.error("Please select at least six image");
        return;
      }
      console.log("onsubmit from", data);
  
      try {
        const loading = toast.loading("Please wait a moment...");
  
        // update blog context
        const payload = {
          ...data,
          status: "Draft",
          createdAt: serverTimestamp(),
        };
  
        // upload blog to firebase database
        const spacesRef = doc(db, data.category, data.id);
  
        // store user info on database
        await setDoc(spacesRef, payload, { merge: true });
  
        toast.dismiss(loading);
        setBlog(blogDefaultData);
        toast.success("Your blog save as a draft");
        navigate("/blog");
  
        // <Navigate to="/blog"/>
      } catch (error) {
        toast.error(error.message);
      }
    };
 
  
  return (
    <div className="">
    <div>
      <div class="md:grid md:grid-cols-2 md:gap-6 m-2  overflow-hidden">
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form onSubmit={handleMultipulUpload(onSubmit)}>
            <div class="shadow-xl sm:overflow-hidden sm:rounded-md text-left">
              <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div>
                  <label class="block text-sm font-medium text-gray-700"></label>
                </div>
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="first-name"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Vendor Name
                    </label>
                    <input
                      type="text"
                      name="vandorName"
                      id="vandorName"
                      Value={loggedInUser?.displayName}
                      disabled
                      autocomplete="given-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.vandorName
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />

                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.vandorName && errors.vandorName.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="last-name"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      name="email"
                      Value={loggedInUser?.email}
                      disabled
                      id="last-name"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Brand Name
                    </label>
                    <select
                      id="make"
                      name="make"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={ blog?.make}
                      {...registerPost("make", {
                        required: "Brand name is required!",
                      })}
                    >
                      <option value="" disabled selected>
                        Select Brand Name
                      </option>
                      <option value="BMW">BMW</option>
                      <option value="Audi">Audi</option>
                      <option value="Ford">Ford</option>
                      <option value="Kia">Kia</option>
                      <option value="Chevrolet">Chevrolet</option>
                    </select>

                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.make && errors.make.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Condition
                    </label>
                    <select
                      id="Condition"
                      name="Condition"
                      autocomplete="Condition"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={ blog.Condition}
                      {...registerPost("Condition", {
                        required: "Condition is required!",
                      })}
                    >
                      <option value="" disabled selected>
                        Select Condition Name
                      </option>
                      <option value="New">New</option>
                      <option value="Used">Used</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.Condition && errors.Condition.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Name ...
                    </label>
                    <select
                      id="name"
                      name="name"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={ blog?.name}
                      {...registerPost("name", {
                        required: "name is required!",
                      })}
                    >
                      <option value="" disabled selected>
                        Select Name
                      </option>
                      <option value="Convertible">Convertible</option>
                      <option value="Convertible">Convertible</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.name && errors.name.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Models
                    </label>
                    <select
                      id="model"
                      name="model"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={ blog.model}
                      {...registerPost("model", {
                        required: "model is required!",
                      })}
                    >
                      <option value="" disabled selected>
                        Select Models Name
                      </option>
                      <option value="A4">A4</option>
                      <option value="A6">A6</option>
                      <option value="A8">A8</option>
                      <option value="911">911</option>
                      <option>Camry</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.model && errors.model.message}
                    </span>
                  </div>
                  {/* color */}
                  {/* <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Color
                    </label>
                    <select
                      id="color"
                      name="color"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={listing.color}
                      {...registerPost("color", {
                        required: "model is required!",
                      })}
                    >
                      <option value="" disabled selected>
                        Select Color
                      </option>
                      <option value="White">White</option>
                      <option value="Black">Black</option>
                      <option value="Yellow">Yellow</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.color && errors.color.message}
                    </span>
                  </div> */}

                  {/* update color */}
                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="color"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Color
                    </label>
                    <input
                      type="text"
                      name="color"
                      id="color"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="color"
                      {...registerPost("color", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.color
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.color && errors.color.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Review
                    </label>
                    <select
                      id="Review"
                      name="Review"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={Listing.Review}
                      {...registerPost("Review", {
                        required: "Review is required!",
                      })}
                    >
                      <option value="" disabled selected>
                        Select Review Star out of 5
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.Review && errors.Review.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Type
                    </label>
                    <select
                      id="country"
                      name="Type"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={Listing.Type}
                      {...registerPost("Type", {
                        required: "Type is required!",
                      })}
                    >
                      <option Value="" disabled selected>
                        Select Type of car
                      </option>
                      <option value="Sedan">Sedan</option>
                      <option value="Compact">Compact</option>
                      <option value="SUV">SUV</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.Type && errors.Type.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="country"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Transmission
                    </label>
                    <select
                      id="Transmission"
                      name="Transmission"
                      autocomplete="country-name"
                      class="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={blog.Transmission}
                      {...registerPost("Transmission", {
                        required: "Transmission is required!",
                      })}
                    >
                      <option Value="" disabled selected>
                        Select Transmission
                      </option>
                      <option value="Manual">Manual</option>
                      <option value="Manual">Manual</option>
                      <option value="Manual">Manual</option>
                    </select>
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.Transmission && errors.Transmission.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="  Year"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Year
                    </label>
                    <input
                      type="number"
                      name="Yea"
                      id="Yea"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Year"
                      {...registerPost("Yea", {
                        required: " Year is required",
                        minLength: { value: 4, message: "place 4 digit" },
                        maxLength: { value: 4, message: "place 4 digit" },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.Yea
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.Yea && errors.Yea.message}
                    </span>
                  </div>

                  {/* <div class="col-span-6">
                    <label
                      for="street-address"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Year
                    </label>
                    <input
                      type="number"
                      name="Yea"
                      required
                      id="street-address"
                      autocomplete="street-address"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div> */}

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="  Year"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="price"
                      {...registerPost("price", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.price
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.price && errors.price.message}
                    </span>
                  </div>

                  {/* date  */}
                  {/* date  */}
                  {/* date  */}
                  {/* date  */}

                  {/* <p className="text-xl"> Do you want to open an auction?</p> */}

                  <div className="col-span-6 sm:col-span-3">
                    {openTimeLimitForm ? (
                      <div className="flex">
                        <div class="col-span-3 sm:col-span-3 w-full">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Last Date
                          </label>
                          <input
                            type="date"
                            name="date"
                            id="date"
                            autocomplete="given-name"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            {...registerPost("date", {
                              required: "Transmission is required!",
                            })}
                            className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                              errors.date
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            } `}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.date && errors.date.message}
                          </span>
                        </div>

                        <div class="col-span-3 sm:col-span-3 w-full ml-2">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Last Time
                          </label>
                          <input
                            type="time"
                            name="time"
                            id="time"
                            autocomplete="given-name"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            {...registerPost("time", {
                              required: "Transmission is required!",
                            })}
                            className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                              errors.time
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            } `}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.time && errors.time.message}
                          </span>
                        </div>

                        <label
                          onClick={() => setOpenFormInput(false)}
                          // type="submit"
                          class="block text-sm font-bold text- ml-4 mt-8 rounded-md px-6 mb-2 p-2 bg-red-600 text-center text-white"
                          value="Close "
                        >
                          {" "}
                          Close
                        </label>
                      </div>
                    ) : (
                      <button
                        onClick={() => setOpenFormInput(true)}
                        className="flex text-[#61dafb] bg-white mt-4 ml-2 text-2xl font-bold text-center px-2  "
                      >
                        Do you want to open an auction?{" "}
                        <button className="mt-1 ml-2 bg-[#61dafb]  text-white px-3 rounded-sm m-1 hover:bg-[#2f63d4]">
                          {" "}
                          <RiAuctionFill className=" "></RiAuctionFill>
                        </button>
                      </button>
                    )}
                  </div>

                  {/* discount price  */}

                  <div className="col-span-6 sm:col-span-3">
                    {openDiscountOpen ? (
                      <div className="flex">
                        <div class="col-span-3 sm:col-span-3 w-full">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                          >
                            Discount Price
                          </label>
                          <input
                            type="number"
                            name="dprice"
                            id="date"
                            autocomplete="given-name"
                            placeholder="Discount price"
                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            {...registerPost("dprice", {
                              required: "Transmission is required!",
                            })}
                            className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                              errors.dprice
                                ? "focus:border-red-500 focus:ring-red-500"
                                : "focus:border-blue-500 focus:ring-blue-500"
                            } `}
                          />

                          <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                            {errors.dprice && errors.dprice.message}
                          </span>
                        </div>

                        <label
                          onClick={() => setOpenFormInputDiscount(false)}
                          // type="submit"
                          class="block text-sm font-bold text- ml-4 mt-8 rounded-md px-6 mb-2 p-2 bg-red-600 text-center text-white"
                          value="Close "
                        >
                          {" "}
                          Close
                        </label>
                      </div>
                    ) : (
                      <button
                        onClick={() => setOpenFormInputDiscount(true)}
                        className="flex text-[#61dafb] bg-white mt-4 ml-2 text-2xl font-bold text-center "
                      >
                        Do you want to open an Discount price?{" "}
                        <button className="mt-1 ml-2 bg-[#61dafb] px-3 text-white hover:bg-[#2f63d4] rounded-sm">
                          {" "}
                          <TbCurrencyTaka className=""></TbCurrencyTaka>
                        </button>
                      </button>
                    )}
                  </div>

                  {/* VEHICLE INFORMATION */}
                  {/* VEHICLE INFORMATION */}

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="trim"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Trim
                    </label>
                    <input
                      type="text"
                      name="trim"
                      id="trim"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="trim"
                      {...registerPost("trim", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.trim
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.trim && errors.trim.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="trim"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Engine
                    </label>
                    <input
                      type="text"
                      name="engine"
                      id="engine"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="engine"
                      {...registerPost("engine", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.engine
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.engine && errors.engine.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="trim"
                      class="block text-sm font-medium text-gray-700"
                    >
                      VIN
                    </label>
                    <input
                      type="text"
                      name="vin"
                      id="vin"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="vin"
                      {...registerPost("vin", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.vin
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.vin && errors.vin.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="door"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Doors
                    </label>
                    <input
                      type="number"
                      name="door"
                      id="door"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="door"
                      {...registerPost("door", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.door
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.door && errors.door.message}
                    </span>
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label
                      for="gas"
                      class="block text-sm font-medium text-gray-700"
                    >
                      Gas Mileage
                    </label>
                    <input
                      type="text"
                      name="gas"
                      id="gas"
                      autocomplete="family-name"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="gas"
                      {...registerPost("gas", {
                        required: " price is required",
                        minLength: {
                          value: 1,
                          message: "place 1digit minimum",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.gas
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.gas && errors.gas.message}
                    </span>
                  </div>
                </div>

                <div>
                  <label
                    for="about"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <div class="mt-1">
                    <textarea
                      id="description"
                      type="text"
                      name="description"
                      rows="3"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="description ..."
                      {...registerPost("description", {
                        required: " description is required",
                        minLength: {
                          value: 20,
                          message: "place minimum 20 word description",
                        },
                        maxLength: {
                          value: 200,
                          message: "place minimum 20 word description",
                        },
                      })}
                      className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                        errors.description
                          ? "focus:border-red-500 focus:ring-red-500"
                          : "focus:border-blue-500 focus:ring-blue-500"
                      } `}
                    />

                    <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                      {errors.description && errors.description.message}
                    </span>
                  </div>
                </div>

                {/*  */}
                {/* Multiple Photo */}
                <div>
                  <span className="text-gray-900 text-lg font-medium">
                    Pictures of the space
                  </span>
                  <div className="mt-5">
                    <label
                      className={`mt-1 flex justify-center px-6 py-28 border-2 ${
                        dragEnter ? "border-blue-500" : "border-gray-300"
                      } border-dashed rounded-md`}
                      htmlFor="images-upload"
                      onDragEnter={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <div className="flex text-sm text-gray-700">
                          <label
                            htmlFor="images-upload"
                            className="relative cursor-pointer rounded-md font-semibold text-blue-600 hover:text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                          >
                            <span>Upload multiple photos</span>
                            <input
                              onChange={handleFiles}
                              type="file"
                              multiple
                              name="images-upload"
                              id="images-upload"
                              className="sr-only"
                            />
                          </label>
                          <p className="pl-1 font-semibold">
                            or drag and drop
                          </p>
                        </div>
                        <p className="text-xs text-gray-600">
                          Each pictures size minimum 20KB
                        </p>
                        <p className="text-xs text-gray-600">
                          PNG, JPG, GIF up to 500MB
                        </p>
                      </div>
                    </label>
                  </div>
                </div>

                {/* Preview multiple images */}
                {postImages.length > 0 && (
                  <div className="mx-auto">
                    <div className="space-y-2 sm:space-y-0 grid gap-4 sm:gap-5 grid-cols-2 sm:grid-cols-3">
                      {postImages?.map((image, index) => (
                        <div key={index}>
                          <div className="relative rounded overflow-hidden">
                            <img
                              alt=""
                              className="object-cover object-center w-full h-full shadow-3xl hover:opacity-95"
                              src={image.thumbnail}
                            />

                            {/* Remove photo */}
                            <span
                              className="absolute top-0 cursor-pointer right-0 bg-white bg-opacity-50 text-red-400 transition-colors duration-300 p-1 rounded hover:bg-gray-300 m-1"
                              onClick={() => handleRemoveImage(image, index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div class=" px-4 py-3 text-right sm:px-6">
                <div className="pt-8 flex justify-end">
                  <button
                    type="submit"
                    className=" bg-blue-400 ml-3 inline-flex justify-center py-2.5 px-6 border border-transparent shadow-sm text-sm sm:text-base font-semibold rounded-md text-white transition-colors duration-300 bg-brand-accent-700 hover:bg-yellow-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
                  >
                    Post{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1 h-6 w-6 text-sm sm:text-base font-medium text-gray-50"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true"></div>
  </div>
  );
};

export default Dblog;
