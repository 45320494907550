import React, { useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { FaGasPump, FaTelegramPlane } from "react-icons/fa";
import { FaShare } from "react-icons/fa";
import { BsTelephoneForwardFill } from "react-icons/bs";
import { AiOutlineFieldTime } from "react-icons/ai";
import { GrShieldSecurity } from "react-icons/gr";
import { MdDirectionsCarFilled } from "react-icons/md";

import Slider from "react-slick";

import ProductDeTwo from "./ProductDeTwo";

import PriceHistory from "./PriceHistory";
import GoogleMap from "./GoogleMap";
import sideimg from "../../../src/imgs/login2.png";
import Footer from "../home/Footer";
import ProductChakOutTop from "./ProductChakOutTop";
import Header from "../shared/Header";
import CarModal from "./CarModal";
import VehicleInformation from "./VehicleInformation";
import { BookingContext } from "../../context/AuthProvider/BookingContext";
import CommentProducts from "./CommentProducts";
import Timer from "./Timer";
import Bids from "./Bids";
import { useRef } from "react";
import { TbEngine, TbManualGearbox } from "react-icons/tb";
import { GoDashboard } from "react-icons/go";
import { useAuth } from "../../Auth/AuthenticationContext";
import { ChakoutData, ChokOutFormContext } from "../../context/ChokOutFormContext";
import { useForm } from "react-hook-form";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../Fribase/firebase.init";
import { toast } from 'react-hot-toast';

const ProductDetials = () => {
  const [product, setProduct] = useState([]);
  const {  allCar } = useContext(BookingContext);
  const { loggedInUser, currentUser } = useAuth();
  const { chakout, setChakout } = useContext(ChokOutFormContext);
  const [imgId, setImgId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [postImages] = useState([]);
  // console.log("iteeeeem", allCar);
  const { id } = useParams();
  // console.log("IDDDDDD", id);



  const navigate = useNavigate();
  const {
    register: registerPost,
    handleSubmit: handleMultipulUpload,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getProducts = () => {
      setProduct(allCar?.find((obj) => obj.id === id));
    };
    if (allCar?.length > 0) {
      getProducts();
    }
  }, [allCar, id]);
  console.log("Product", product);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  const date1 = new Date(`${product.date} ${product.time}`);
  const date2 = new Date()
  console.log('date1',date1)
  console.log('date2',date2)
  const difarence = Math.abs(date1 - date2);
  // console.log('difarence date',difarence)



  // chakout form





  const onSubmit = async (data) => {
    toast.dismiss();
    const userEmail = loggedInUser.email.split("@");
    const listingId =
      Math.floor(1000 + Math.random() * 9000) + "-" + userEmail[0];

    // console.log("data", data);
    // data.email = loggedInUser.email;
    // data.eventName = data.eventName || loggedInUser.eventName;
    // data.photoURL = fileUrl || loggedInUser.photoURL;
    data.vandorName = loggedInUser.displayName;
    data.postImages = postImages || chakout.postImages;
    data.email = loggedInUser.email;
    data.category = "chakoutcart" || chakout.category;
    data.id = listingId;

    // if (data.postImages.length < 6) {
    //   toast.error("Please select at least six image");
    //   return;
    // }
    // console.log("onsubmit from", data);

    try {
      const loading = toast.loading("Please wait a moment...");

      // update listing context
      const payload = {
        ...data,
        status: "Draft",
        requstUser: currentUser?.photoURL,
         productImg:product?.postImages && product?.postImages[0]?.url,
        //  productImg:product?.postImages,
        // src={product?.postImages && product?.postImages[0]?.url}
        createdAt: serverTimestamp(),
      };

      // upload listing to firebase database
      const spacesRef = doc(db, data.category, data.id);

      // store user info on database
      await setDoc(spacesRef, payload, { merge: true });

      toast.dismiss(loading);
      setChakout(ChakoutData);
      toast.success("Your listing save as a draft");
      navigate("/listing");

      // <Navigate to="/listing"/>
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <>
      <Header></Header>
      <div>
        <br />
        <br />
        <br />
        <br />
        {/* {product?.name} */}

        <div className="grid md:grid-cols-12 sm:grid-cols-1 ml-auto mr-auto container p-6  mt-1  ">
          <div className="overflow-hidden col-span-7">
            {/* onClick={() => setShowModal(true)} */}

            <div className="cursor-pointer">
              <div>
                <Slider {...settings}>
                  {product?.postImages &&
                    product?.postImages?.map((img, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setShowModal(true);
                          setImgId(index);
                        }}
                        className="flex"
                      >
                        <img className=" w-full" src={img?.url} alt="" />
                      </div>
                    ))}
                </Slider>
                {/* <img
                    className="w-full rounded-2xl "
                  
                    src={product?.postImages && product?.postImages[0]?.url}
                    alt=""
                  /> */}
              </div>

              {/* <div>
                  <img
                    className="w-full rounded-2xl"
                    // src={product?.postImages[0]?.url}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="w-full rounded-2xl"
                    // src={product?.postImages[0]?.url}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="w-full rounded-2xl"
                    // src={product?.postImages[0]?.url}
                    alt=""
                  />
                </div> */}
            </div>
            {/* Timer heder */}
            <br/>

            <Timer
              product={product}
              duration={2* 24 * 60 * 60 * 1000}
              // duration={difarence}

              executeScroll={executeScroll}
            ></Timer>

            <br />
            {/*  */}
            <div className="flex justify-between m-2">
              <p className="text-2xl font-bold"> {product.name}</p>
              <p>price : <span className="  text-xl font-bold">৳</span>  {product.price}</p>
            </div>
            {/* <h4 className="text-left  ml-10 ">
            <span className="ml-64"> </span>
          </h4> */}
            <br />
            {/* <div className="flex justify-between">
            <p></p>

          </div> */}
            <h4 className="  ml-auto mr-auto">
              23,372 miles &nbsp; &nbsp; | &nbsp;&nbsp; Dhaka,Bangladesh
            </h4>
            <br />

            <hr className="ml-4 mr-4" />

            {/* phone share option s */}
            {/* phone share option s */}
            {/* phone share option s */}
            <br />
            <div className="flex ml-2">
              <BsTelephoneForwardFill className="text-xl mt-1 text-blue-700"></BsTelephoneForwardFill>
              <p className="mb-2 text-xl">
                &nbsp; &nbsp; (088+) 01601699671 &nbsp; &nbsp;
              </p>
              <FaShare className="text-xl mt-1  text-blue-700"></FaShare>
              <p
                style={{ marginTop: "-30px", marginLeft: "10px" }}
                className=" text-xl mb-10"
              >
                &nbsp; &nbsp;
                <ProductDeTwo></ProductDeTwo>{" "}
              </p>
              {/*   <ProductDeTwo></ProductDeTwo> ata Share  */}
            </div>

            <hr />
            <br />
            <div className=" grid-cols-2  justify-between gap-2 block md:hidden">
              <button class="  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 w-full border border-blue-700 rounded">
                Call
              </button>
              <button class="  bg-[#61dafb] hover:bg-blue-700 text-white font-bold py-2 w-full border border-blue-700 rounded">
                Check Availability
              </button>
            </div>

            {/* box */}
            {/* box */}
            {/* box */}
            {/* box */}
            <br />

            {/* <div className=" flex rounded-lg text-left ml-auto mr-auto justify-between border border-slate-300  bg-white hover:shadow-xl">
              <img className="w-28" src={img11} alt="" />
              <div className="mt-10 ml-5 hidden sm:block ">
                <p>Lorem ipsum dolor</p>
                <p>Lorem ipsum dolor sit amet consectetur</p>
              </div>
              <div>
                <button class="mt-12 ml-16 m-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                  Button
                </button>
              </div>
            </div> */}

            {/* AUTOLIST PRICE ANALYSIS */}
            <br />
            <div className="flex p-2 ml-auto mr-auto justify-between">
              <h4 className="text-xl font-bold hidden sm:block">
                AUTOLIST PRICE ANALYSIS
              </h4>
              <p className="font-bold ">Get Free Quotation</p>
              <h4 className="text-white bg-black font-bold p-1 text-right ml-10">
                {" "}
                D | A | M | I | G | A | R | I {" "}
              </h4>
            </div>
            {/*  */}

            <div className="bg-white shadow w-40 border-slate-300 ml-auto mr-auto rounded-md p-2 mb-4 justify-between">
              <p className="text-xl">THIS VEHICLE</p>
              <p className="text-2xl">৳ {product?.price}</p>
            </div>

            {/*  */}

            <p className=" rounded-xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 h-3">
              {" "}
              <span className="bg-white h-2 rounded-full"> </span>
            </p>
            {/*  */}
            <div className="m-2 mt-8 grid grid-cols-3">
              <div className="text-left">
                <h4 className="font-bold">Below Market</h4>
                <h4>Priced below similar</h4>
                <p>vehicles</p>
              </div>
              {/*  */}
              <div className="ml-auto mr-auto">
                <h4 className="font-bold">Market Price</h4>
                <h4>Priced around the same</h4>
                <p>as similar vehicles</p>
              </div>
              {/*  */}
              <div className="text-right">
                <h4 className="font-bold">Above Market</h4>
                <h4>Priced above similar</h4>
                <p>vehicles</p>
              </div>
            </div>
            <br />
            <hr />
            {/*  */}
            {/*  */}
            {/*  */}
            {/* PRICE HISTORY */}
            <br />
            <div className=" grid grid-cols-2">
              <div className="text-left">
                <p className="text-xl font-bold">PRICE HISTORY</p>
                <p className="p-2 text-gray-500">Call For Details: -$1100</p>
              </div>

              <div className="flex text-right ">
                <AiOutlineFieldTime className="text-2xl font-bold mt-1 ml-28 text-gray-400"></AiOutlineFieldTime>
                <p className="ml-2">
                  <span className="text-xl font-bold text-right"> 160</span>{" "}
                  days on market.
                </p>
              </div>
            </div>
            {/* PRICE HISTORY */}
            <br />
            <br />

            <PriceHistory></PriceHistory>

            {/* PRICE HISTORY */}

            <br />
            <hr />
            {/* VEHICLE INFORMATION */}
            {/* VEHICLE INFORMATION */}
            {/* VEHICLE INFORMATION */}
            <br />
            <br />
            <h1 className="text-xl font-bold text-left">VEHICLE INFORMATION</h1>
            <br />

            <VehicleInformation  product={product} ></VehicleInformation>

            <br />
            <br />
            

            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            <br />
            <p className="text-left font-bold text-xl">KEY FEATURES</p>
            <br />
            <div className="grid grid-cols-2 gap-10">
              {/* 11111 */}
              <div>
                <div className=" bg-[#f8f8f8] shadow-md border-t-2 border-r-2 border-[#61dafb] rounded-md">
                  <p className=" border-black p-4">
                    {/* <MdAirlineSeatReclineExtra ></MdAirlineSeatReclineExtra> */}
                    <TbEngine className="text-4xl ml-auto mr-auto"></TbEngine>
                  </p>
                  <p className="text-black font-bold">Engine Gas</p>
                  <p className="">{product?.gas}</p>
                  <br />
                  <br />
                </div>
                <div className=" bg-[#f8f8f8] shadow-md mt-4 border-t-2 border-r-2 border-[#61dafb] rounded-md">
                  <p className=" border-black p-4">
                    {/* <MdAirlineSeatReclineExtra ></MdAirlineSeatReclineExtra> */}
                    <GoDashboard className="text-4xl ml-auto mr-auto"></GoDashboard>
                  </p>
                  <p className="text-black font-bold">Horsepower</p>
                  <p className="">280 - 505 hp</p>
                  <br />
                  <br />

                 
                </div>



                 <div className=" bg-[#f8f8f8] shadow-md mt-4 border-t-2 border-r-2 border-[#61dafb] rounded-md">
                  <p className=" border-black p-4">
                    {/* <MdAirlineSeatReclineExtra ></MdAirlineSeatReclineExtra> */}
                    <GrShieldSecurity className="text-4xl ml-auto mr-auto"></GrShieldSecurity>
                  </p>
                  <p className="text-black font-bold">Basic Warranty</p>
                  <p className="">4 years / 50k miles</p>
                  <br />
                  <br />
                </div>
              </div>
              {/* 2222222222222 */}
              <div className="">
                <div className=" bg-[#f8f8f8] shadow-md  border-b-2 border-l-2 border-[#61dafb] rounded-md">
                  <p className=" border-black p-4">
                    {/* <MdAirlineSeatReclineExtra ></MdAirlineSeatReclineExtra> */}
                    <FaGasPump className="text-4xl ml-auto mr-auto"></FaGasPump>
                  </p>
                  <p className="text-black font-bold">MPG</p>
                  <p className="">19 - 25 combined</p>
                  <br />
                  <br />
                </div>

                <div className=" bg-[#f8f8f8] shadow-md mt-4  border-b-2 border-l-2 border-[#61dafb] rounded-md">
                  <p className=" border-black p-4">
                    {/* <MdAirlineSeatReclineExtra ></MdAirlineSeatReclineExtra> */}
                    <TbManualGearbox className="text-4xl ml-auto mr-auto"></TbManualGearbox>
                  </p>
                  <p className="text-black font-bold">Engine Gas</p>
                  <p className="">{product?.engine}</p>
                  <br />
                  <br />
                </div>

                <div className=" bg-[#f8f8f8] shadow-md mt-4 border-b-2 border-l-2 border-[#61dafb] rounded-md">
                  <p className=" border-black p-4">
                    {/* <MdAirlineSeatReclineExtra ></MdAirlineSeatReclineExtra> */}
                    <MdDirectionsCarFilled className="text-4xl ml-auto mr-auto"></MdDirectionsCarFilled>
                  </p>
                  <p className="text-black font-bold">Cargo Capacity
</p>
                  <p className="">18.5 ft³</p>
                  <br />
                  <br />
                </div>


                
              </div>
            </div>
            <br />
            <br />
            <hr />
            {/* Gooogel logation */}
            <div className="rounded">
              <GoogleMap></GoogleMap>

              {/* SELLER DESCRIPTION */}
              <div className="mt-10 text-left">
                <h4 className="text-xl font-bold">SELLER DESCRIPTION</h4>
                <p className="mt-5">
                { product?.description}
                 
                
                </p>
              </div>
            </div>

            {/*  */}
            {/*  */}
            {/*  */}
            <Bids myRef={myRef} product={product}></Bids>
            <br />
            <CommentProducts product={product}></CommentProducts>
          </div>
          {/* scol korle top popap C*/}
          {/* scol korle top popap C*/}
          {/* scol korle top popap C*/}
          {/* scol korle top popap C*/}

          <div className="col-span-5 ml-auto mr-auto">
            <div className="flex min-h-full flex-col  sm:px-6 lg:px-8  mt-0 ">
              {/* sm:sticky sm:top-8  */}
              <div className="sm:sticky sm:top-8">
                <div className=" sm:mx-auto sm:w-full sm:max-w-full text-left    ml-auto mr-auto">
                  <div className="bg-white py-8 px-4  mb-1 md:mt-0 mt-6 shadow sm:rounded-lg sm:px-4 mr-0 w-full ">
                    <h1 className="text-xl font-bold uppercase mb-4 flex ">
                      Check Availability{" "}
                      <span className="ml-24 text-5xl mt-1 text-sky-400">
                        {" "}
                        <FaTelegramPlane></FaTelegramPlane>
                      </span>
                    </h1>
                    <form  onSubmit={handleMultipulUpload(onSubmit)}>
                    <div class="col-span-6 sm:col-span-3">
                            <label
                              for="first-name"
                              class="block text-sm font-medium text-gray-700"
                            >
                             User Name
                            </label>
                            <input
                              type="text"
                              name="userName"
                              id="userName"
                              Value={loggedInUser?.displayName}
                              disabled
                              autocomplete="userName"
                              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                                errors.userName
                                  ? "focus:border-red-500 focus:ring-red-500"
                                  : "focus:border-blue-500 focus:ring-blue-500"
                              } `}
                            />

                            <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                              {errors.userName && errors.userName.message}
                            </span>
                          </div>

                          <div class="col-span-6 sm:col-span-3">
                            <label
                              for="last-name"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Email
                            </label>
                            <input
                              type="text"
                              name="email"
                              Value={loggedInUser?.email}
                              disabled
                              id="email"
                              autocomplete="email"
                              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>



                     <div class="col-span-6 sm:col-span-3">
                            <label
                              for="poneNumber"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Phone Number
                            </label>
                            <input
                              type="number"
                              name="phoneNumber"
                              id="poneNumber"
                              autocomplete="family-name"
                              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Phone Number"
                              {...registerPost("poneNumber", {
                                required: " Phone Number is required",
                                minLength: {
                                  value: 11,
                                  message: "place 11 digit",
                                },
                                maxLength: {
                                  value: 11,
                                  message: "please 11 digit",
                                },
                              })}
                              className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                                errors.phoneNumber
                                  ? "focus:border-red-500 focus:ring-red-500"
                                  : "focus:border-blue-500 focus:ring-blue-500"
                              } `}
                            />
                            <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                              {errors.poneNumber && errors.poneNumber.message}
                            </span>
                          </div>

                          <div>
                            <label
                              htmlFor="Message"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Message
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="text"
                                name="message"
                                type="text"
                                autoComplete="message"
                                placeholder="Message"

                                required
                                {...registerPost("message", {
                                  required: " Message is required",
                                  minLength: {
                                    value: 12,
                                    message: "place 8 word",
                                  },
                                 
                                })}
                                className={`mt-2 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm ${
                                  errors.message
                                    ? "focus:border-red-500 focus:ring-red-500"
                                    : "focus:border-blue-500 focus:ring-blue-500"
                                } `}
                              />
                              <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                              {errors.message && errors.message.message}
                            </span>
                            </div>
                          </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor="remember-me"
                            className="ml-2 block text-sm text-gray-900"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>

                      <div>
                        <p className="text-xl font-bold ml-auto mr-auto">
                          Call (088+) 01601699671
                        </p>
                        <br />
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md border border-transparent bg-[#61dafb] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Send Request
                        </button>
                      </div>
                    </form>

                    <div className="mt-6">
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="bg-white px-2 text-gray-500">
                            Or continue with
                          </span>
                        </div>
                      </div>

                      <div className="mt-6 grid grid-cols-3 gap-3">
                        <div>
                          <a
                             href="https://web.facebook.com/messages/t/100000486330574"
                             target="_blank"  rel="noreferrer" 
                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                          >
                            <span className="sr-only">
                              Sign in with Facebook
                            </span>
                            <svg
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </div>

                        <div>
                          <Link
                            to="/"
                            className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                          >
                            <span className="sr-only">
                              Sign in with Twitter
                            </span>
                            <svg
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                            </svg>
                          </Link>
                        </div>

                        <div>
                          <a
                            
                             href="https://github.com/Zunaid-jony"
                             target="_blank"  rel="noreferrer" 
                            className="inline-flex w-full justify-center  rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                          >
                            <span className="sr-only">Sign in with GitHub</span>
                            <svg
                              className="h-5 w-5"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* img box */}
                  <div>
                    {/* <img className="mt-10 p-2" src={sideimg} alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProductChakOutTop product={product}></ProductChakOutTop>

        <br />
        <br />
        <Footer></Footer>
      </div>

      {/* Modal */}
      {showModal && (
        <CarModal
          open={showModal}
          products={product?.postImages}
          imgIds={imgId}
          setOpen={setShowModal}
        ></CarModal>
      )}
    </>
    // <CartModal isVisible={showModal} onCloce={()=>setShowModal(false)}></CartModal>
    // </Fragment>
  );
};

export default ProductDetials;
