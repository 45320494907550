// CommentsRepllyContext.js


import React, { createContext, useState } from "react";

export const CommentsRepllyContext = createContext();

export const RepllyData = {
  id: "",
  href: "",
  category: "reply",
  createdAt: "",
  featured: "No",
  status: "Draft",
  postImages: [],
  userName: "",
  email: "",
  phoneNumber: "",
  replly:"",
  carid:'',
  commentsUser:'',
  commentsreplly:''
};

export default function CommentsRepllyContextProvider({ children }) {
  const [replly, setReplly] = useState(RepllyData);

  const RepllyCommet = {replly, setReplly };

  return (
    <CommentsRepllyContext.Provider value={RepllyCommet}>
      {children}
    </CommentsRepllyContext.Provider>
  );
}
