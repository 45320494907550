import React from 'react';
import { useContext } from 'react';
import { BookingContext } from '../../context/AuthProvider/BookingContext';
import Services from '../Contact/Services';
import Header from './../shared/Header';
import BlogSection from './BlogSection';

const BlogHome = () => {
    const { userCar, allUser, allCar,allBlog } = useContext(BookingContext);
    return (
        <div>
            <Header></Header>
            <br />
            <br />
            <br />
        <BlogSection></BlogSection>  

     {/* <div className="grid  md:grid-cols-4 sm:grid-cols-2 gap-6 container ml-auto mr-auto m-2">
     
        {allCar.map((item) => (
          <BlogSection key={item.id} item={item}></BlogSection>
        ))}
        <br />
      
      </div> */}


            <br />
          <Services ></Services>
        </div>
    );
};

export default BlogHome;