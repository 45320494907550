import React from 'react';


import { AiFillLike} from 'react-icons/ai';
import { FaDharmachakra} from 'react-icons/fa';
import { AiOutlineUser} from 'react-icons/ai';


const timeline = [
  {
    id: 1,
    content: 'Signup to Damigari.com',
  
    href: '#',
   
    datetime: '2020-09-20',
    icon: AiOutlineUser,
    iconBackground: 'bg-gray-400',
  },
  {
    id: 2,
    content: '"Place Bid" option to participate.',
   
    href: '#',
    
    datetime: '2020-09-22',
    icon: AiFillLike,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 3,
    content: 'You can check for Direct "Purchase" without Bid option. ',
   
    href: '#',
 
    datetime: '2020-09-28',
    icon: FaDharmachakra,
    iconBackground: 'bg-green-500',
  },
  {
    id: 4,
    content: 'Confirm your purchase & we will send sms to your desired number.',
   
    href: '#',
   
    datetime: '2020-09-30',
    icon: AiFillLike,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 5,
    content: ' You can Ask for "Spot" purchase or we can send the car to your desired adress.T&C applied',
  
    href: '#',
   
    datetime: '2020-10-04',
    icon: FaDharmachakra,
    iconBackground: 'bg-green-500',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PriceHistory = () => {
    return (
        <div className="flow-root">
          <ul role="list" className="-mb-8">
            {timeline.map((event, eventIdx) => (
              <li key={event.id}>
                <div className="relative pb-8">
                  {eventIdx !== timeline.length - 1 ? (
                    <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          event.iconBackground,
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                        )}
                      >
                        <event.icon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        <p className="text-sm text-gray-500">
                          {event.content}{' '}
                          <a href={event.href} className="font-medium text-gray-900">
                            {event.target}
                          </a>
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-gray-500">
                        <time dateTime={event.datetime}>{event.date}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )
};

export default PriceHistory;