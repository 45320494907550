import React from 'react';
import { AiTwotoneEdit, AiOutlineDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const ChakoutCart = ({item}) => {
    return (
        <div>
      <div className="px-4 sm:px-6 lg:px-8 mt-5">
       
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 p-2">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300 w-full">
                  <thead className="bg-gray-50 ml-auto mr-auto">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Car  
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Message
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 ml-auto mr-auto justify-center "
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900  hidden sm:block"
                      >
                        Role
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white text-left ">
                   
                      <tr key={item?.vendorName}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-20 w-20 flex-shrink-0">
                              <img
                                className="h-20 w-20 rounded"
                                src={item?.productImg}
                                alt=""
                              />
                              {console.log('postimgs test ..',item?.productImg)}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {item.phoneNumber}
                              </div>
                              <div className="text-gray-500  hidden sm:block">
                                {item.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900  hidden sm:block">
                            {item.title}
                          </div>
                          <div className="text-gray-500  hidden sm:block">
                            {item.message}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:block">
                          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 mt-6 text-sm text-gray-500  hidden sm:block">
                          {item.role}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 ">
                          <Link
                            to="/"
                            className="text-indigo-600 hover:text-indigo-900 flex gap-4  text-xl text-right"
                          >
                            <span>
                              {" "}
                              <AiTwotoneEdit className="hover:text-green-600"></AiTwotoneEdit>{" "}
                            </span>{" "}
                            <span>
                              <AiOutlineDelete className="hover:text-red-600"></AiOutlineDelete>
                            </span>{" "}
                         
                          </Link>
                        </td>
                      </tr>
                
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

export default ChakoutCart;