import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, Navigate } from "react-router-dom";


import { useAuth } from "./AuthenticationContext";
import { Toaster } from "react-hot-toast";
import { FaFacebookF } from "react-icons/fa";
import { GrGooglePlus } from "react-icons/gr";
import LoginImage from "../../src/imgs/loginPage.png"

const LoginUser = () => {
  const [typePass] = useState(false);
  const { signInWithGoogle, signInWithFacebook, login, setJWTToken } =
    useAuth();

  const {
    register: registerSignIn,
    handleSubmit: handleSignIn,
    formState: { errors },
  } = useForm();

  const handleResponse = (res) => {
    setJWTToken();
    toast.success("Successfully Logged In!");
    Navigate("/");
  };

  // Google Sign In
  const googleSignIn = () => {
    const loading = toast.loading("Please wait a moment...");
    signInWithGoogle()
      .then((res) => {
        toast.dismiss(loading);
        handleResponse(res);
        Navigate("/home");
      })
      .catch((err) => {
        toast.dismiss(loading);
        let message = err.code.split("auth/")[1].replace(/-/g, " ");
        toast.error(message.charAt(0).toUpperCase() + message.slice(1));
      });
  };

  // Facebook Sign In
  const facebookSignIn = () => {
    const loading = toast.loading("Please wait a moment...");
    signInWithFacebook()
      .then((res) => {
        toast.dismiss(loading);
        handleResponse(res);
      })
      .catch((err) => {
        toast.dismiss(loading);
        let message = err.code.split("auth/")[1].replace(/-/g, " ");
        toast.error(message.charAt(0).toUpperCase() + message.slice(1));
      });
  };

  // Sign In with Email and Password
  const onSubmit = (data) => {
    const loading = toast.loading("Please wait a moment...");
    const { email, password } = data;

    // For Login
    if (email && password) {
      login(email.toLowerCase(), password)
        .then((res) => {
          toast.dismiss(loading);
          handleResponse(res);
          // console.log('email chak korbo ',email)
        })
        .catch((err) => {
          toast.dismiss(loading);
          console.log('error',err)
          let message = err.code.split("auth/")[1].replace(/-/g, " ");
          toast.error(message.charAt(0).toUpperCase() + message.slice(1));
        });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="background-container bg-cover bg-center h-screen flex  items-center relative justify-center ml-auto mr-auto" style={{ backgroundImage: `url(${LoginImage})` }}>

        <div className="w-full max-w-[1536px] md:grid md:grid-cols-3 -mt-12">
          <div className="col-span-2"></div>
        <div className="flex mt-24 max-w-sm mx-auto overflow-hidden bg-[#AAAAAA] rounded-lg shadow-2xl dark:bg-gray-800 
        lg:max-w-4xl m-3 md:w-3/4 w-full justify-center ml-auto mr-auto md:-ml-20"  >
          <div className="w-full px-6 py-8 md:px-8 ">
            <div className="flex justify-end -mt-2 sm:-mt-2 -mx-8"></div>
            <div className="">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="max-w-md mx-auto space-y-6">
                  <p className="text-2xl font-bold">Login</p>
                  {/* <div className="flex ml-auto mr-auto cursor-pointer gap-2 justify-center ">
                    <i
                      onClick={facebookSignIn}
                      className="hover:bg-white rounded-full p-2 border bg-[#2b77f2] border-sky-600 hover:text-black  text-white  font-bold"
                    >
                      {" "}
                      <FaFacebookF></FaFacebookF>{" "}
                    </i>
                    <i
                      onClick={googleSignIn}
                      className=" hover:bg-white rounded-full p-2 border border-sky-600 hover:text-black text-white bg-[#2b77f2] font-bold"
                    >
                      <GrGooglePlus></GrGooglePlus>{" "}
                    </i>
                  



                 
                  </div> */}
            
                  <form
                    className="grid grid-cols-1 gap-6 text-left -mt-2"
                    onSubmit={handleSignIn(onSubmit)}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block font-medium text-gray-800 -mt-2"
                      >
                        Email
                      </label>
                      <div className="">
                        <input
                          {...registerSignIn("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="example@example.com"
                          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-2xl shadow-sm placeholder-gray-500 focus:outline-none leading-wide ${
                            errors.email
                              ? "focus:border-red-500 focus:ring-red-500"
                              : "focus:border-blue-500 focus:ring-blue-500"
                          }`}
                        />

                        <span className="flex items-center font-medium tracking-wide text-red-500 text-sm  ml-1">
                          {errors.email && errors.email.message}
                        </span>
                      </div>
                    </div>

                    <div className="space-y-1 -mt-4">
                      <div className="flex justify-between items-center">
                        <label
                          htmlFor="password"
                          className="block font-medium text-gray-800"
                        >
                          Password
                        </label>
                      
                      </div>
                      <div className="relative flex w-full flex-wrap items-stretch pt-2 -mt-2">
                        <input
                          {...registerSignIn("password", {
                            required: "Password is required",
                            pattern: {
                              value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                              // value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                              message:
                                "Minimum eight characters, at least one letter, one number and one special character",
                            },
                          })}
                          id="password"
                          name="password"
                          type={typePass ? "text" : "password"}
                          placeholder="****"
                          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-2xl shadow-sm placeholder-gray-500 focus:outline-none leading-wide ${
                            errors.password
                              ? "focus:border-red-500 focus:ring-red-500"
                              : "focus:border-blue-500 focus:ring-blue-500"
                          }`}
                        />

                        <span className="flex items-center font-medium tracking-wide text-red-500 text-sm mt-1 ml-1">
                          {errors.password && errors.password.message}
                        </span>
                      </div>
                    </div>

                    <button
                      className="bg-[#f53e3e] relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-base sm:text-lg font-semibold px-4 py-2 disabled:bg-opacity-70 bg-brand-accent-700 hover:bg-blue-500 flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 text-white"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </form>

                  <span className="block text-base sm:text-lg text-center text-gray-800 ">
                    New user? {` `}
                    <Link to="/register">
                      <span className="text-base sm:text-lg text-blue-500 font-semibold hover:text-blue-700 cursor-pointer">
                        Create an account
                      </span>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* <div
            className="hidden bg-cover lg:block lg:w-1/2 login-url bg-blue-500 "

           
          >
            <div className="mt-36">
              <h2 className="text-2xl  font-semibold text-center text-gray-700 dark:text-white my-4 text-white">
                Sign in
              </h2>
              <p className=" text-slate-300 my-4">
                sign up here if you don't have an account
              </p>
              <Link to="/register">
                <button className=" px-14 rounded-full py-2 tracking-wide text-white transition-colors duration-300 transform  hover:bg-gray-600 focus:outline-none focus:bg-gray-600 border border-white">
                  Register
                </button>
              </Link>
            </div>
          </div> */}
        </div>

        </div>

        {/* Background Modal Opacity */}
        <Toaster
          toastOptions={{
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default LoginUser;
