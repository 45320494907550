import React from "react";
import { Menu } from "@headlessui/react";


import {  AiOutlineArrowUp } from "react-icons/ai";
import { Transition } from "@headlessui/react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Fragment, useContext } from "react";
import AuthContext from "../../context/AuthProvider/AuthContext";
import { useAuth } from "./../../Auth/AuthenticationContext";
import { current } from "@reduxjs/toolkit";

const HeaderLogOut = () => {
  // const [token, setToken] = useState(null);
  // const [email,setEmail] = useState(null);
  // sokol data contet thake astase profile setprofiele  contet api thake asse
  const { profile, setProfile } = useContext(AuthContext);
  const { currentUser,logout } = useAuth();
  const { token, setToken } = useContext(AuthContext);
  const { email, setEmail } = useContext(AuthContext);
  // const [profile, setProfile] = useState([]);
  // useEffect(() => {
  //   setToken(localStorage.getItem("token"));
  //   // setEmail(localStorage.getItem("user"));

  // }, [token]);

  // useEffect(()=>{
  //   fetch("http://localhost:5000/api/profile?email=jony1@gmail.com")
  //     .then((res) => res.json())
  //     .then((data) => setProfile(data));
  // },[])

  // useEffect(() => {
  //   if(email?.length>0) {
  //     console.log('eimaol',email);
  //     axios
  //     .get(`http://localhost:5000/api/profile?email=${email}`)
  //     .then((res) => {
  //       setProfile(res.data);
  //       console.log('email chak data',res.data)
  //     });

  //   }

  // }, [email, setProfile]);
  console.log('cureen user name',currentUser)

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handaleLogout = () => {
    setToken(localStorage.removeItem("token"));
    setEmail(localStorage.removeItem("user"));
  };

  return (
    <div className=" flex items-center  w-40  ">
      <Menu as="div" className="relative ">
        <div>
          <Menu.Button className="flex  items-center rounded-full  text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
            <img
              className="h-8 w-8 rounded-full"
           
              src={currentUser?.photoURL}
              alt=""
            />
            <span className=" hidden text-sm font-medium text-gray-700 lg:block ml-1 ">
              {/* <span className="sr-only">Open user menu for </span> */}
              {/* {profile?.firstName?.charAt(0)?.toUpperCase()}
              {profile?.firstName?.slice(1)}
              &nbsp; */}
              
              {currentUser?.displayName}

            </span>
            {/* <AiOutlineArrowUp
              className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
              aria-hidden="true"
            /> */}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/dashboard/userProfile"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  Your Profile
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="//dashboard/settings"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  Settings {profile?.email}
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link
                  onClick={logout}
                  // to="/login"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                >
                  Logout
                </Link>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default HeaderLogOut;
