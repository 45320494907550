import React from "react";

import {
  PieChart,
  Pie,
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Bar,
} from "recharts";

const Graphs = () => {
  const data = [
    { name: "July", users: 200000000 },
    { name: "August", users: 150000000 },
    { name: "September", users: 100000000 },
    { name: "October", users: 500000000 },
  ];
  return (
    <div style={{ textAlign: "center" }}>
      <h1 className="mt-10 text-xl font-bold">View User Graph</h1>
      <div className="grid md:grid-cols-2 mt-10 p-4 ml-auto mr-auto overflow-hidden">
        <div>
          <PieChart width={400} height={400}>
            <Pie
              dataKey="users"
              isAnimationActive={false}
              data={data}
              cx={200}
              cy={200}
              outerRadius={80}
              fill="#8884d8"
              label
            />
            <Tooltip />
          </PieChart>
        </div>
        <div className="ml-auto mr-auto  m-2  overflow-hidden container ">
          <BarChart
            width={460}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
             
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="users" fill="#8884d8" background={{ fill: "#eee" }} />
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default Graphs;
