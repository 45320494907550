import React, { createContext, useState } from "react";

export const ChokOutFormContext = createContext();

export const ChakoutData = {
  id: "",
  href: "",
  category: "chakoutcart",
  createdAt: "",
  featured: "No",
  status: "Draft",
  reviews: [],
  listingDate: "",
  postImages: [],
  userName:"",
  email:"",
  phoneNumber:"",
  message:"",
};

export default function ChokOutFormContextProvider({ children }) {
  const [chakout, setChakout] = useState(ChakoutData);

  const listingDataone = { chakout, setChakout};

  return (
    <ChokOutFormContext.Provider value={listingDataone}>
      {children}
    </ChokOutFormContext.Provider>
  );
}
