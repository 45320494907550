import React, { useContext } from "react";
import { BookingContext } from "../../../context/AuthProvider/BookingContext";
import ChakoutCart from "./ChakoutCart";

const Chakout = () => {
  const { userCar, allUser, allCar, allChakout } = useContext(BookingContext);
  console.log("all chakout", allChakout);
  return (
    <div>
     

      {allChakout?.map((item) => (
        <>
          <ChakoutCart key={item.id} item={item}></ChakoutCart>
        </>
      ))}
    </div>
  );
};

export default Chakout;
