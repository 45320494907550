import React from 'react'
import Header from '../shared/Header'

function MainCarShow() {
  return (
    <div>
         <Header></Header>
         <p className='mt-20'>  MainCarShow</p>
        
      
        </div>
  )
}

export default MainCarShow