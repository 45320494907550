import React from 'react';
import Header from '../shared/Header';
import AppSection from './AppSection';
import ChooseUs from './ChooseUs';
import Footer from './Footer';
import HeadCar from './headCar/HeadCar';
import HeaderCarosal from './HeaderCarosal';
import HeaderSearch from './HeaderSearch';
import HomeCard from './HomeCard/HomeCard';
import Testimonials from './HomeCard/Testimonials';
import PopularList from './popularList/PopularList';
import TrendingSearches from './TrendingSearches';


import { useEffect } from "react";
import ViewPage from './ViewPage';
import CarLogo from './CarLogSection/CarLogo';
import DemoPage from './DemoPage';




const Home = ({item}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div>
          {/* <Header></Header> */}
          <DemoPage></DemoPage>
           {/* <HeaderCarosal></HeaderCarosal>
           <HeaderSearch></HeaderSearch>
           <HeadCar></HeadCar>
           <CarLogo></CarLogo>
         
           
           <HomeCard></HomeCard>
           <ChooseUs></ChooseUs>
           <PopularList item={item}></PopularList>
           <AppSection></AppSection> */}
           {/* <TrendingSearches></TrendingSearches> */}
           {/* <Testimonials></Testimonials>
           <ViewPage></ViewPage>

           
           <Footer></Footer> */}
        
        </div>
    );
};

export default Home;