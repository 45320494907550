import React from 'react';
import Typed from 'react-typed';


import { Link } from 'react-router-dom';
import Massager from './Massager';
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import {  AiOutlineArrowUp } from "react-icons/ai";

const stats = [
  { id: 1, name: 'Total Subscribers', stat: '71,897', icon: AiOutlineArrowUp, change: '122', changeType: 'increase' },
  { id: 2, name: 'Avg. Open Rate', stat: '58.16%', icon: AiOutlineArrowUp, change: '5.4%', changeType: 'increase' },
  { id: 3, name: 'Avg. Click Rate', stat: '24.57%', icon: AiOutlineArrowUp, change: '3.2%', changeType: 'decrease' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ViewPage = () => {
    return (
        <div className='mb-28 mt-20 p-1 container ml-auto mr-auto m-2'>
          <h3 className="text-lg font-medium leading-6 text-[#61dafb]"></h3>
          <Typed
                className='md:text-3xl sm:text-4xl text-[#61dafb]  text-xl font-bold pl-2'
                 strings={['Last 30 days', 'DamiGari', 'DamiGari']}
                 typeSpeed={120}
                    backSpeed={140}
                    loop 
                
                ></Typed>
    
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 container ml-auto mr-auto">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6"
              >
                <dt>
                  <div className="absolute rounded-md bg-[#61dafb] p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  <p
                    className={classNames(
                      item.changeType === 'increase' ? 'text-green-600' : 'text-red-600',
                      'ml-2 flex items-baseline text-sm font-semibold'
                    )}
                  >
                    {item.changeType === 'increase' ? (
                      <AiOutlineArrowUp className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                    ) : (
                      <BsFillArrowDownCircleFill className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                    )}
    
                    <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                    {item.change}
                  </p>
                  <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                    <div className="text-sm">
                      <Link to="/" className="font-medium text-[#61dafb] hover:text-indigo-500">
                        {' '}
                        View all<span className="sr-only"> {item.name} stats</span>
                      </Link>
                    </div>
                  </div>
                </dd>
              </div>
            ))}
          </dl>
       
        </div>
      )
};

export default ViewPage;


